import React from 'react';
import CustomTabs from '../../components/CustomTabs/CustomTabs';

import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import ExecutiveListView from './ExecutiveListView';
import AddExecutiveView from './AddExecutiveView';


export default function ExecutiveManagement() {


    return (        
        <CustomTabs
            headerColor="warning"
            tabs={[
            
              {
                tabName: "Executives List",
                tabIcon: FormatListBulletedIcon,
                allowed: true,
                variant:"fullWidth",
                tabContent: (
                  <ExecutiveListView />
                )
              },
              {
                tabName: "Add Executive",
                tabIcon: AddIcon,
                allowed: true,
                tabContent: (
                  <AddExecutiveView />
                )
              },
            ]}
          />
    );
}
