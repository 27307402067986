import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
} from "@material-ui/core";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 15,
		textAlign: "justify",
		fontFamily: "Times New Roman",
	},
	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function FAQScreen() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};
	return (
		<Container>
			<Container maxWidth={false}>
				<GridItem xs={12} sm={12} md={12}>
					<ThemeProvider theme={theme}>
						<Typography className={classes.title}>
							<h2> Terms & Conditions</h2>
							<br />
							<p>
								<Accordion>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'1. DEFINITIONS'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												'NowWagon | NowWagon Smart Movers LLP Updated effective from 1st
												September, 2021'
												<br />
												This Site/ Application/ Services is/are operated /provided by NowWagon
												Smart Movers LLP. These terms and conditions (“User Terms”) apply to
												your visit to and use, of the Site whether through a computer or a
												mobile phone, the Service and the Application, as well as to all
												information, recommendations and or services provided to you on or
												through the Site, the Service and the Application. This document is an
												electronic record in terms of Information Technology Act, 2000 and rules
												thereunder as applicable and the provisions pertaining to electronic
												records in various statutes as amended by the Information Technology
												Act, 2000. This electronic record is generated by a computer system and
												does not require any physical or digital signatures. By clicking on the
												“I ACCEPT” button, you are consenting to be bound by these User Terms.
												PLEASE ENSURE THAT YOU READ AND UNDERSTAND ALL THESE ENTIRE USER TERMS
												BEFORE YOU USE THE SITE. If you do not accept any of the User Terms,
												then please do not use the Site or avail any of the services being
												provided therein. YOUR AGREEMENT TO THESE USER TERMS SHALL OPERATE AS A
												BINDING AGREEMENT BETWEEN YOU AND NOWWAGON IN RESPECT OF THE USE AND
												SERVICES OF THE SITE.Your acceptance of the User Terms shall be deemed
												to include your acceptance of the privacy policy available at
												https://www.nowwagon.com/info/faqs#privacyPolicy
												<br />
												By accepting these User Terms, you also allow NowWagon to send you
												promotional emails and SMS alerts from time to time.IT IS HEREBY
												EXPRESSLY CLARIFIED THAT THESE USER TERMS WILL NOT APPLY TO CORPORATE
												ENTITIES WHO ARE GOVERNED BY THE NOWWAGON CORPORATE TERMS OF USE.
												HOWEVER, ALL AUTHORIZED USERS TAKING ‘NOWWAGON CORPORATE RIDES’ i.e.,
												FOR CUSTOMERS WHO ARE AVAILING RIDES UNDER THE DIRECT ARRANGEMENTS
												BETWEEN CORPORATE ENTITIES AND NOWWAGON SHALL ALSO BE GOVERNED BY THESE
												USER TERMS.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'2. ELIGIBILITY'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<br /> '(i) You have attained at least 18 (eighteen) years of age. '
											<br />
											'(ii) You are competent to enter into a contract under the Applicable Laws.'
											<br />
											'(iii)If You reside in a jurisdiction that restricts the use of the Service
											because of age, or restricts the ability to enter into contracts such as
											this User Terms due to age, You must abide by such age limits.'
											<br />
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'3. REGISTRATION AND ACCOUNT'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'3.1 You understand and acknowledge that You can register on the Site
												only after complying with the requirements of this Clause 3 and by
												entering Your Registration Data.'
												<br />
												'3.2 You shall ensure that the Registration Data provided by You is
												accurate, complete, current, valid and true and is updated from time to
												time. We shall bear no liability for false, incomplete, old or incorrect
												Registration Data provided by You.'
												<br />
												'3.3 You are solely responsible for maintaining the confidentiality of
												Your Registration Data and will be liable for all activities and
												transactions that occur through Your Account, whether initiated by You
												or any third party. Your Account cannot be transferred, assigned or sold
												to a third party. We shall not be liable for any loss that You may incur
												as a result of someone else using Your password or Account, either with
												or without Your knowledge.'
												<br />
												'3.4 We reserve the right to suspend or terminate Your Account with
												immediate effect and for an indefinite period, if We have a reason to
												believe that the Registration Data or any other data provided by You is
												incorrect or false, or that the security of Your Account has been
												compromised in any way, or for any other reason We may find just or
												equitable.'
												<br /> '3.5 Except for the Registration Data or any other data submitted
												by You during the use of any other service offered through Site
												(“Permitted Information”), NowWagon does not want You to, and You should
												not, send any confidential or proprietary information to NowWagon on the
												Site or otherwise, unless otherwise is required by Applicable Laws. In
												accepting these User Terms You agree that any information or materials
												that You or individuals acting on Your behalf provide to NowWagon other
												than the Permitted Information will not be considered confidential or
												proprietary.'
												<br /> '3.6 It is Your responsibility to check to ensure that You
												download the correct application for Your device. We are not liable if
												You do not have a compatible mobile device or if You download the wrong
												version of the Application for Your mobile device. We reserve the right
												to terminate the Service and the use of the Application should You be
												using the Service or Application with an incompatible or unauthorized
												device.'
												<br /> '3.7 We allow You to open only one Account in association with
												the Registration Data provided by You. In case of any unauthorized use
												of Your Account please immediately reach us at support@nowwagon.com.'
												<br /> '3.8 In case, You are unable to access Your Account, please
												inform Us at support@nowwagon.com and make a written request for
												blocking Your Account. We will not be liable for any unauthorised
												transactions made through Your Account prior to the expiry of 72
												(seventy-two) hours after You have made a request in writing for
												blocking Your Account, and shall not have any liability in case of Force
												Majeure Event.'
												<br />
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>
													<b>'4. SERVICES'</b>{" "}
												</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'4.1. The Site permits you to avail the transportation services offered
												by TPSPs. The Service allows You to send a request through NowWagon to a
												Driver on the NowWagon network. The Driver has sole and complete
												discretion to accept or reject each request for Service. If the Driver
												accepts a request, NowWagon notifies You and provides information
												regarding the Driver - including Driver name, Vehicle license number,
												vehicle registration number , telephone contact details of the Driver
												and such other details as NowWagon may determine.'
												<br />
												'4.2. NowWagon shall procure reasonable efforts to bring You into
												contact with a Driver, subject to the availability of Driver in or
												around Your location at the moment of Your request for such services.'
												<br />
												'4.3. By using the Application or the Service, You further agree that:
												(i) You will only use the Service or download the Application for Your
												sole, personal use and will not resell or assign it to a third party;
												(ii) You will not use an account that is subject to any rights of a
												person other than You without appropriate authorization; (iii) You will
												not use the Service or Site for unlawful purposes; (iv) You will not try
												to harm the Service, Site or our network in any way whatsoever; (v) You
												will provide NowWagon with such information and documents which NowWagon
												may reasonably request; (vi) You will only use an authorized network to
												avail the Service; (vii) You are aware that when requesting Services,
												whether by message, via Site or calling the call center of NowWagon,
												standard messaging charges, data charges, voice charges, as applicable,
												of the Your and Our phone network service providers, will apply; (viii)
												You will comply with all Applicable Law from Your country of domicile
												and residence and the country, state and/or city in which You are
												present while using the Site or Service; and (ix) You are aware of and
												shall comply with the Information Technology Act, 2000 and the rules,
												regulations and guidelines notified thereunder.'
												<br />
												'4.4. NowWagon reserves the right to immediately terminate the Service
												and the use of the Application in the event of non-compliance with any
												of the above requirements. Further, NowWagon will store the information
												provided by You or record your calls for contacting You for all Service
												related matters. You shall promptly inform NowWagon on any change in the
												information provided by You.'
												<br />
												'4.5. NowWagon or an authorised representative of NowWagon, shall
												provide information regarding services, discounts and promotions
												provided by NowWagon to You by way of an SMS or email to Your registered
												mobile number/registered email ID. You also have the option to
												discontinue receiving such information at any point of time. To
												discontinue receiving such information, You may at any point of time
												visit the specific link provided in the Site to discontinue the same.'
												<br />
												'4.6. NOWWAGON WILL BE ENTITLED TO PROCESS AND TRANSFER YOUR INFORMATION
												AS AND WHEN IT DEEMS FIT AND IT MAY STORE OR TRANSFER YOUR INFORMATION
												IN A SERVER OUTSIDE INDIA OR THE COUNTRY WHERE YOU ARE LOCATED IN ORDER
												TO PERFORM NOWWAGON’S OBLIGATIONS UNDER THESE CUSTOMER T&C.'
												<br />
												'4.7. You agree to grant NowWagon a non-exclusive, worldwide, perpetual,
												irrevocable, royalty- free, sub-licensable (through multiple tiers)
												right to exercise the copyright, publicity, database rights or any other
												rights You have in your information, in any media now known or not
												currently known, with respect to Your information. YOU AGREE AND PERMIT
												NOWWAGON TO SHARE YOUR INFORMATION AND/OR PERMITTED INFORMATION, WITH
												THIRD PARTIES.'
												<br />
												'4.8. You agree and permit NowWagon to share any information provided by
												You with third parties in order to facilitate provision of certain
												value-added services offered by such third parties to You and/or to
												provide certain value-added services to You by NowWagon. You hereby
												expressly consent to receive communications from NowWagon/ third parties
												offering value-added services to You through Your registered phone
												number and/or e-mail id and/or the Site. You agree that You will not
												hold NowWagon responsible for any such communications received from
												third parties, nor will any such communication amount to spam,
												unsolicited communication or a violation of Your registration on the
												national do not call registry.'
												<br />
												'4.9. NowWagon will be entitled to enter into any tie-up in terms of
												joint-venture or otherwise with any other institution engaged in the
												business of providing services analogous and/or similar to those herein
												contained. In such case, depending upon the modality and the mechanism
												as may be devised, You will be provided with the services by NowWagon
												jointly and/or severally with the party/ies in joint venture. You hereby
												give Your irrevocable consent and permission to such a tie-up. In the
												event of such a tie-up, the terms and conditions herein contained will,
												mutatis mutandis, become applicable in respect of such tie-up
												arrangement also.'
												<br /> '4.10. In the event NowWagon provides You any device as a part of
												the Services including but not limited to NOWWAGON Play (“NOWWAGON
												Device”), You shall comply with the following. ‘NOWWAGON Play’ means a
												connected car platform using multiple devices in-car and cloud
												technology platform owned and operated by NowWagon, which endeavours to
												provide an interactive experience to You – (i) You shall not tamper,
												pilfer or misuse the NOWWAGON Device(s) or allow, induce or assist a
												third party to indulge in such activity. (ii) You shall not transcribe,
												distribute, download, store, duplicate and reproduce the content forming
												part of the NOWWAGON Device(s) (“Content”), in whole or in part, through
												any medium including, but not limited to, tape, disc, hard disk,
												computer system, or other electronic recording or transmission device.
												(iii) You shall not (i) license, sublicense, sell, resell, transfer,
												assign, distribute or otherwise commercially exploit or make available
												to any third party, in any way, the NOWWAGON Device(s) and/or any
												Content; (ii) modify or make derivative works from the Content; (iii)
												create Internet “links” to the Content or “frame” or “mirror” any
												Content on any other server or wireless or internet-based device; (iv)
												reverse engineer or access the NOWWAGON Device(s) and/or Content in
												order to (a) design or build a competitive product or service, (b)
												design or build a product using similar ideas, features, functions or
												graphics of the NOWWAGON Device(s) and/or Content, or (c) copy any
												ideas, features, functions or graphics of the NOWWAGON Device(s) and/or
												Content, or (v) launch an automated program or script, including, but
												not limited to, web spiders, web crawlers, web robots, web ants, web
												indexers, bots, viruses or worms, or any program which may make multiple
												server requests per second, or unduly burdens or hinders the operation
												and/or performance of the NOWWAGON Device and/or Content (iv) You shall
												not use the NOWWAGON Device(s) for any illegal or unlawful purposes
												including but not limited to playing / watching pornographic content on
												the NOWWAGON Device and shall use the NOWWAGON Device(s) solely for
												purposes determined by NowWagon and strictly in accordance with these
												User Terms. You shall solely be responsible and liable for any
												violations of law committed by You and misuse of the NOWWAGON Device(s),
												if any, with the NOWWAGON Device(s). (v) You shall ensure that the
												NOWWAGON Device(s) is maintained in Your possession in a proper manner.
												NowWagon may, at its sole discretion, charge such amounts from You as
												may be determined by NowWagon for use of the NOWWAGON Device(s) by You.
												(vi) In the event the NOWWAGON Device(s) is not functioning properly or
												if there is any technical or safety issue in relation to the NOWWAGON
												Device(s), You shall immediately inform the NowWagon Support Executive
												about any such issue. (vii) You shall use the NOWWAGON Device(s) with
												due care and caution and not do anything or permit anything to be done
												that may cause damage to the NOWWAGON Device(s). If the NOWWAGON
												Device(s) is damaged and is not repairable due to any act of You, You
												shall pay amounts to NowWagon, as may be determined by NowWagon. If You
												fail or are unable to pay the amounts determined by NowWagon, NowWagon
												shall be entitled to suspend / terminate Your Account with NowWagon
												without prejudice to NowWagon’s rights under these User Terms and under
												Applicable Laws.'
												<br /> '4.11. In the event of breakdown of the Vehicle, which is beyond
												repair, before completion of the Ride, NowWagon on a best effort basis
												and at its sole discretion may arrange for a Substitute Vehicle for
												completion of Your Ride to Your destination. However, the arrangement of
												Substitute Vehicle shall be subject to its availability.'
												<br /> '4.12. You acknowledge and agree that Substitute Vehicle may not
												be necessarily of the same type as the original Vehicle booked by You
												from the Site.'
												<br /> '4.13. NowWagon bears no responsibility and liability for delays
												and losses suffered by You or caused to You as a consequence of the
												breakdown of the Vehicle or the Substitute Vehicle.'
												<br /> <b>'(c) INFORMATION THIRD PARTIES PROVIDE ABOUT YOU'</b>
												<br />' We may, from time to time, supplement the information we collect
												about you through our websiteor Mobile Application or Services with
												outside records from third parties.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'5. CONFIRMATION OF BOOKING'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'5.1. NowWagon shall, upon receiving the booking request from You in the
												manner set out above, proceed to confirm or decline the booking based on
												the availability of Vehicles at the pickup time, which shall be informed
												to You vide an SMS or email. In the event the booking is confirmed, You
												shall check the booking details including but not limited to pick up
												time and pick up place, and if there is incorrect detail, the same needs
												to be informed to us immediately by calling our call centre.'
												<br /> '5.2. You shall bear the consequences and damages for any delay
												that may be caused to You due to Your failure to check the confirmation
												SMS or email or failure to inform NowWagon of the incorrect details
												immediately.'
												<br />
												'5.3. Certain selective customers will be eligible to avail the Service
												under a ‘Guest Booking’ feature, provided by NowWagon. Such customers
												will also be bound by the terms and conditions set out herein.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'6. PAYMENT'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br /> '6.1. NowWagon shall charge Convenience Fee or Access Fee for the
												Service which shall be determined and amended at the sole and absolute
												discretion of NowWagon. The Convenience Fee or Access Fee shall be
												payable by you to NowWagon and shall be informed to you before you begin
												your ride.'
												<br />
												'6.2. TPSP shall charge Fare to You for the Ride offered to You by the
												TPSP.'
												<br />
												'6.3. You shall be required to pay such Additional Fee, which will form
												part of the receipt of the Total Ride Fee.'
												<br />
												'6.4. You shall be required to pay such Cancellation Fee in terms of
												Clause 7, which will form part of the receipt of the Total Ride Fee. '
												<br />
												'6.5. In addition to the Total Ride Fee, You may be required to pay such
												other charges incurred by you during the Ride in cash i.e. Additional
												Fee, which are not included in the receipt of the Total Ride Fee.'
												<br />
												'6.6. The Total Ride Fee will be collected by the Driver or NowWagon
												from You at the end of the Ride, as set out in Clause 6.7 below.'
												<br />
												'6.7. NowWagon shall provide a receipt of the Total Ride Fee payable by
												You at the end of the Ride, however, separate invoices raised by the
												TPSPs for the Fare and Additional Fee, and the Convenience Fee or Access
												Fee raised by NowWagon shall be provided to You on request. You may
												raise a request for a copy of the invoices from NowWagon App / Support
												page.'
												<br />
												'6.8. All applicable taxes in respect of the Fare, Convenience Fee,
												Additional Fee, and Cancellation Fee shall be borne and payable by You
												to the TPSPs or NowWagon, as the case may be.'
												<br />
												'6.9. You shall choose to pay for the Service Fee by either of the
												following four methods: (i) Cash payment: Cash payment towards the Total
												Ride Fee after the completion of Ride can be made to the Driver. (ii)
												E-Wallet payment: NowWagon offers You the facility of making an online
												payment through an E-Wallet powered by a third party payment processor
												(“Payment Processor”). E-Wallet money will not be applicable on bookings
												made through the phone or while the user is not logged into his/her
												Account on the Site. The processing of payments, in connection with Your
												use of the E-Wallet will be subject to the terms, conditions, and
												privacy policies of the Payment Processor that NowWagon engages for the
												purpose. NowWagon will not be responsible for any errors by the Payment
												Processor in any manner. Further, even in cases of E- Wallet payments,
												all Additional Fee (defined below) shall have to be paid by You in cash,
												to the authority or person concerned or if already paid by the Driver,
												to the Driver. When You choose to make an E-Wallet payment, NowWagon
												shall collect the Fare on behalf of the TPSP who will be responsible for
												providing the transportation services. (iii) NowWagon Credit Payment:
												Payment for the Total Ride Fee can be made to NowWagon on credit subject
												to the terms provided at https://s3-ap-southeast-
												1.amazonaws.com/NowWagon-prod-website/TERMS+AND+CONDITIONS_NOWWAGON+CREDIT.pdf
												Further, even in cases of NowWagon Credit payments, all Additional Fee
												shall have to be paid by You in cash, to the authority or person
												concerned or if already paid by the Driver, to the Driver. When You
												choose to make an NowWagon Credit payment, NowWagon shall collect the
												Fare on behalf of the TPSP who will be responsible for providing the
												transportation services. (iv) Credit Card/ Debit Card/ Net Banking
												Payment: Total Ride Fee shall be effected using the services of an
												entity providing payment gateway/processor services (“PG”), authorized
												by NowWagon. Such PG may either be NowWagon or any of its affiliates or
												partners or unrelated third parties. You agree and undertake to share
												relevant payment details including credit/debit card details (Card
												Details) with the PG for the successful completion of payment towards
												Total Ride Fee to NowWagon and authorize the PG to complete such
												transactions. In this respect, it is clarified that all PGs whose
												services are utilized for the purposes of the Site and/or Application
												and/or Services shall be PCI-DSS (Payment Card Industry – Data Security
												Standard) compliant. Your authorization permits the PG to debit or
												credit the bank account associated with Your payment details. Your
												authorization further permits the PG to use Your Card Details for the
												processing of transactions initiated by You at any of NowWagon’s
												affiliates. Your authorization will remain in effect as long as You
												maintain an Account with Us. In the event You delete Your Card Details
												with the PG or if You delete Your Account, the PG will not process any
												further transactions initiated by You at the Site or Application and at
												the sites of any of NowWagon’s affiliates. Your authorization under this
												clause is subject to any other terms and conditions of the PG.'
												<br />
												'6.10. Any payment related issue, except when such issue is due to an
												error or fault in the Site, shall be resolved between You and the
												Payment Processor. NowWagon shall not be responsible for any
												unauthorized use of Your E-Wallet during or after availing the Services
												on the Site.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'7. CANCELLATION POLICY'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<br />
											'7.1. You agree and acknowledge that You may cancel Your request for a
											Vehicle from a Driver at any point of time subject to a Cancellation Fee as
											explained below: In Mini/Maxi/Large/any other’ categories, Cancellation Fee
											will be charged • If you cancel after 5 minutes after the cab is allotted or
											• If a driver cancels after waiting at Your location for more than 10
											minutes • Notwithstanding the foregoing, if the Driver is delayed by more
											than five (5) minutes to pick You, You will not be charged any Cancellation
											Fee. In case, where we shall extend passenger services in future.'
											<br /> '7.2. You shall be notified of the applicable Cancellation Fee in
											advance whenever You attempt to cancel a booking/service request. The
											notification shall be on the Application and/or the Site.'
											<br />
											'7.3. NowWagon shall provide a receipt of the Cancellation Fee, if any,
											payable by You for every cancellation in terms of the table above for such
											cancellations, however, separate invoices raised by the TPSPs for the
											Cancellation Fee, and NowWagon for the Convenience Fee on cancellations
											shall be provided to You on request. You may raise a request for a copy of
											the invoices from the Support page.'
											<br /> '7.4. The Cancellation Fee shall be payable by You at the completion
											of Your subsequent Ride.'
											<br />
											'7.5. The mode of payment of the Cancellation Fee shall be in terms of
											Clause 7 of these User Terms.'
											<br /> '7.6. This Clause 7 shall not apply to corporate rides availed
											pursuant to the terms and conditions.'
											<br />
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'8. USER VIOLATION OF USER TERMS'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												'8.1. You shall not smoke and drink in the Vehicles or misbehave with
												the Driver or distract the Driver or act in violation of Applicable Law.
												In the event You are found to be involved in the activities set out
												above, You shall be liable to pay a fine to us and we shall also have
												the right to terminate the Ride. In the event You fail to pay fine after
												the completion of the Ride, we may at our discretion, take such steps as
												may be available to us under Applicable Law. You shall also be black
												listed as a result of non-payment of the fine or misbehaving as the case
												may be, and in such event, Your Account may be terminated by NowWagon.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>
													<b>'9. CUSTOMER RELATIONSHIP MANAGEMENT '</b>{" "}
												</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'9.1. All issues, opinions, suggestions, questions and feedback while
												availing our Services shall be communicated to us through email address
												mentioned in Clause 22.2. In case of a Ride booked on our Application,
												You shall be required to rate the Ride after its termination. You agree
												to be fair, accurate and non-disparaging while leaving comment,
												feedbacks, testimonials or reviews on or about the Rides or Services.'
												<br />
												'9.2. Reporting of any issue needs to be within 7 (seven) days of the
												happening of the issue, failing which, such issue will not be
												addressed.'
												<br />
												'9.3. Any issue reported on channels other than the above may be
												addressed by NowWagon only on a best-effort basis. NowWagon takes no
												liability for inability to get back on other channels.'
												<br />
												'9.4. NowWagon shall endeavour to respond to Your issues within 2 (two)
												working days of Your reporting the same and endeavour to resolve it at
												the earliest possible. It is hereby clarified that issues are resolved
												on severity basis, and certain may be resolved earlier than the other.
												However, NowWagon shall not be liable for any damages or losses in the
												event You are not satisfied with any such resolution.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'10. FORCE MAJEURE'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'10.1 We shall not be liable for any failure to perform any obligations
												under this User Terms, if the performance is prevented, hindered or
												delayed by a Force Majeure Event and in such case our obligations under
												this User Terms shall be suspended for so long as the Force Majeure
												Event continues.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'11. INDEMNIFICATION'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'11.1 By accepting these User Terms and using the Service, You agree
												that You shall defend, indemnify and hold NowWagon, its affiliates,
												their licensors, and each of its officers, directors, other users,
												employees, attorneys and agents harmless from and against any and all
												claims, costs, damages, losses, liabilities and expenses (including
												attorneys fees and costs) arising out of or in connection with: (a) Your
												violation or breach of any term of these User Terms or any Applicable
												Law or regulation, whether or not referenced herein; (b) Your violation
												of any rights of any third party, including the TPSPs \or (c) Your use
												or misuse of the Application or Service.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'12. LIABILITY'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<br />
											'12.1. The information, recommendations and/or Services provided to You on
											or through the Site, the Application and NowWagon call center are for
											general information purposes only and does not constitute advice. NowWagon
											will reasonably keep the Site and its contents correct and up to date but
											does not guarantee that (the contents of) the Site is free of errors,
											defects, malware and viruses or that the Site is correct, up to date and
											accurate.'
											<br />
											'12.2. NowWagon shall not be liable for You missing trains/flights/events or
											delays etc. as the Service is dependent on many factors not in NowWagon’s
											control. You must book Your Ride after taking into account the check-in
											time, traffic and weather conditions, political rallies, natural calamities,
											traffic barricades, car breakdowns and other un-expected delays.'
											<br />
											'12.3. In the event, there is a delay by the Vehicle in reaching the pickup
											location beyond 30 (thirty) minutes of the pickup time, NowWagon shall only
											endeavour to get You in touch with the Driver assigned for Your Ride.'
											<br />
											'12.4. NowWagon shall not be liable for any damages resulting from the use
											of or inability to use the Site, including damages caused by wrong usage of
											the Site, error in call centre number, network issues, malware, viruses or
											any incorrectness or incompleteness of the Information or the or
											Application.'
											<br />
											'12.5. NowWagon does not assure a complete sustainability of its Service and
											shall not be held responsible or liable for the same, in any manner.'
											<br />
											'12.6. NowWagon shall not be responsible for any loss of communication /
											information of status update and benefits under the program. All this
											information will be sent on mobile number and/or email ID registered with
											NowWagon. NowWagon will not be responsible for appropriateness of mobile or
											email or any other communication medium. You shall be responsible for
											immediately reporting the errors, if any, occurred in the information sent
											to You regarding booking confirmation.'
											<br />
											'12.7. IN NO EVENT SHALL NOWWAGON BE LIABLE FOR ANY DIRECT, INDIRECT,
											PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR FOR ANY DAMAGES
											WHATSOEVER, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
											OTHERWISE, EVEN IF NOWWAGON HAS BEEN ADVISED OF THE POSSIBILITY THEREOF.'
											<br />
											'12.8. IN ADDITION, AND WITHOUT LIMITING THE FOREGOING, TO THE FULLEST
											EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL NOWWAGON’s AGGREGATE
											LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE USER TERMS OR THE
											SERVICES RENDERED HEREUNDER, WHETHER IN CONTRACT, TORT (INCLUDING
											NEGLIGENCE, PRODUCT LIABILITY, OR OTHER THEORY), WARRANTY, OR OTHERWISE,
											EXCEED THE AMOUNT OF Rs. 1000/- (Rupees One Thousand only).'
											<br />
											'12.9. If Applicable Law does not permit the exclusion of certain warranties
											or the limitation or exclusion of liability, the scope and duration of such
											warranty exclusions and the extent of the liability of NowWagon shall be the
											minimum permitted under Applicable Law.'
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'13. APPLICATION LICENSE'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'13.1. Subject to Your compliance with these User Terms, NowWagon grants
												You a limited, revocable, non-exclusive, non-transferable and
												non-sub-licensable license to download and install a copy of the
												Application on a single mobile device that You own or control and to run
												such copy of the Application solely for Your own personal use and to use
												the Site.'
												<br />
												'13.2. You shall not (i) license, sublicense, sell, resell, transfer,
												assign, distribute or otherwise commercially exploit or make available
												to any third party the Service or Site in any way; (ii) modify or make
												derivative works based upon the Service or Application; (iii) create
												Internet “links” to the Service or “frame” or “mirror” any Site on any
												other server or wireless or Internet-based device; (iv) reverse engineer
												or access the Site in order to (a) design or build a competitive product
												or service, (b) design or build a product using similar ideas, features,
												functions or graphics of the Service or Site, or (c) copy, reproduce,
												record, or make available to the public any ideas, features, functions
												or graphics of the Service or Site, or (v) launch an automated program
												or script, including, but not limited to, web spiders, web crawlers, web
												robots, web ants, web indexers, bots, viruses or worms, or any program
												which may make multiple server requests per second, or unduly burdens or
												hinders the operation and/or performance of the Service or Site.'
												<br />
												'13.3. You shall not: (i) send spam or otherwise duplicative or
												unsolicited messages in violation of applicable laws; (ii) send or store
												infringing, obscene, threatening, libelous, or otherwise unlawful or
												tortious material, including material harmful to children or violative
												of third party privacy rights; (iii) send or store material containing
												software viruses, worms, Trojan horses or other harmful computer code,
												files, scripts, agents or programs; (iv) interfere with or disrupt the
												integrity or performance of the Site, the Application or Service or the
												data contained therein; or (v) attempt to gain unauthorized access to
												the Site, the Application or Service or its related systems or
												networks.'
												<br />
												'13.4. NowWagon will have the right to investigate and prosecute
												violations of any of the above to the fullest extent of the law.
												NowWagon may involve and cooperate with law enforcement authorities in
												prosecuting users who violate these User Terms. You acknowledge that
												NowWagon has no obligation to monitor Your access to or use of the Site,
												Service or Posted Content, but has the right to do so for the purpose of
												operating the Site and Service, to ensure Your compliance with these
												User Terms, or to comply with Applicable Law or the order or requirement
												of a court, administrative agency or other Governmental body. NowWagon
												reserves the right, at any time and without prior notice, to remove or
												disable access to any content that NowWagon, at its sole discretion,
												considers to be in violation of these User Terms or otherwise harmful to
												the Site, the Service or Application.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'14. CONTENT POSTED BY CUSTOMERS'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'14.1. NowWagon may accept posting of any notes, messages, e-mails,
												photos, drawings, profiles, opinions, ideas, images, videos, audio files
												or other materials or information given by you on the Site (“Posted
												Content”) by You. You represent that You have obtained all permissions
												and consents required to post the Posted Content and such Posted Content
												complies with all requirements of the Posted Content. NowWagon shall not
												in any manner be responsible for or endorse the Posted Content.'
												<br />
												'14.2. You agree that when posting Posted Content, You will not: (i)
												Publish, post, upload, distribute or disseminate any inappropriate,
												profane, defamatory, infringing, obscene, indecent or unlawful topic,
												blasphemous, pornographic, libelous, invasive of anothers privacy,
												hateful, or racially, ethnically objectionable, disparaging, relating or
												encouraging money laundering or gambling, or otherwise unlawful material
												or information.'
												<br />
												'(ii) Upload files that contain software or other material protected by
												intellectual property laws (or by rights of privacy or publicity) unless
												You own or control the rights thereto or have received all necessary
												consents.'
												<br />
												'(iii) Upload files that contain viruses, corrupted files, or any other
												similar software or programs that may damage the operation of another’s
												computer.'
												<br />
												'(iv) Advertise or offer to sell or buy any goods or services for any
												business purpose, unless the Site specifically allows such messages.'
												<br />
												'(v) Conduct or forward surveys, contests, pyramid schemes or chain
												letters.'
												<br />
												'(vi) Download any file posted by another user that You know, or
												reasonably should know, cannot be legally distributed in such manner.'
												<br />
												'(vii) Falsify or delete any author attributions, legal or other proper
												notices or proprietary designations or labels of the origin or source of
												software or other material contained in a file that is uploaded.'
												<br />
												'(viii) Deceive or mislead the addressee about the origin of a message
												or communicate any information which is grossly offensive or menacing in
												nature.'
												<br />
												'(ix) Restrict or inhibit any other user from using and enjoying the
												Site.'
												<br />
												'(x) Violate any code of conduct or other guidelines which may be
												applicable for any particular Posted Content.'
												<br />
												'(xi) Harvest or otherwise collect information about others, including
												e-mail addresses, without their consent.'
												<br />
												'(xii) Threaten the unity, integrity, defence, security or sovereignty
												of India, friendly relations with foreign states, or public order or
												causes incitement to the commission of any cognizable offence or
												prevents investigation of any offence or is insulting any other nation.
												'
												<br />
												'(xiii) Violate any Applicable Laws or regulations including the
												Information Technology Act, 2000 and the rules, regulations and
												guidelines notified thereunder.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'15. INTELLECTUAL PROPERTY OWNERSHIP'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'15.1. NowWagon alone (and its licensors, where applicable) shall own
												all right, title and interest, including all related intellectual
												property rights, in and to (i) the Site, Application, product, Service
												and any suggestions, ideas, enhancement requests, feedback,
												recommendations or any other offering; (ii) text, graphics, user
												interfaces, visual interfaces, photographs, trademarks, logos, sounds,
												music, artwork and computer code; or (iii) other information provided by
												You or any other party relating to the Site, Application or the Service.
												Third party trademarks may appear on this Site/ Application and all
												rights therein are reserved to the registered owners of those
												trademarks. For use of any third partys intellectual property, You need
												to get permission directly from the owner of the intellectual property
												for any use.'
												<br />
												'15.2. These User Terms do not constitute a sale and do not convey to
												You any rights of ownership in or related to the Site, the Application
												or the Service, or any intellectual property rights owned by NowWagon.
												You shall be solely responsible for any violations of any laws and for
												any infringements of any intellectual property rights caused by use of
												the Services or the Site/ Application.'
												<br />
												'15.3. You may use information on the Site purposely made available by
												NowWagon for downloading from the Site, provided that You: (i) do not
												remove any proprietary notice language in all copies of such documents
												and make no modifications to the information; (ii) use such information
												only for Your personal, non-commercial informational purpose and do not
												copy or post such information on any networked computer or broadcast it
												in any media; or (iii) do not make any additional representations or
												warranties relating to such information.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'16. LINKS'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												'16.1. If permitted by NowWagon, You may establish a hypertext link to
												the Site, provided that the link does not state or imply any sponsorship
												or endorsement of Your site by Us. You must not use on Your site or in
												any other manner any NowWagon trademarks or service marks or any Content
												belonging to NowWagon and appearing on the Site, including any logos or
												characters, without our express written consent. You must not frame or
												otherwise incorporate into another third party website or present in
												conjunction with or juxtaposed against such a website any of the content
												or other materials on the Site without our prior written consent.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'17. TERM AND TERMINATION OF LICENSE AGREEMENT'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<br />
											'17.1. Unless terminated in accordance with this Clause 17, the agreement
											between NowWagon and You is perpetual in nature upon downloading the
											Application and for each Ride booked through the Site.'
											<br />
											'17.2. You are entitled to terminate the agreement at all times by deletion
											of Your Account, thus disabling the use by You of the Site. You can close
											Your Account at any time by following the instructions on the Site.'
											<br />
											'17.3. NowWagon is entitled to terminate the agreement at all times and with
											immediate effect (by disabling Your use of the Site and the Service) if You:
											(a) violate or breach any term of these User Terms, or (b) in the opinion of
											NowWagon, misuse the Application or the Service. NowWagon is not obliged to
											give notice of the termination of the agreement in advance. After
											termination NowWagon will give notice thereof in accordance with these User
											Terms.'
											<br />
											'17.4. Termination of this agreement will not prejudice accrued rights of
											either NowWagon or You.'
											<br />
											'17.5. Clauses 11 (Indemnification), 12 (Liability), 13 (Application
											License), 14 (Contents posted on Site/ Application), 15 (Intellectual
											Property Ownership), 17 (Term and Termination), 22 (Notice) and 24
											(Applicable Law and Dispute Resolution) and such other provisions which are
											intended to survive the termination, shall survive the expiry/termination of
											these User Terms in accordance with their terms.'
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'18. INVALIDITY OF ONE OR MORE PROVISIONS' </b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'18.1. The invalidity of any term of these User Terms shall not affect
												the validity of the other provisions of these User Terms. If and to the
												extent that any provision of these User Terms is invalid, or is
												unacceptable in the given circumstances, a provision shall apply between
												the parties instead that is acceptable considering all the
												circumstances, taking into account the content and the purpose of these
												User Terms.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'19. CONFLICT' </b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												'In the event of any contradiction or inconsistency between this User
												Terms and any other agreement executed between You and NowWagon, the
												terms of the User Terms shall prevail unless the exception has been
												expressly agreed to in writing by making reference to the relevant
												Clause sought to be modified under this User Terms.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'20. DISCLAIMER'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'20.1. You agree that NowWagon is merely an electronic platform to
												facilitate aggregation of Vehicles and does not in any manner provide
												transportation services. NowWagon does not endorse, advertise, advise or
												recommend You to avail the Services of any Driver. NowWagon also does
												not guarantee or provide assurance in respect of the behaviour, actions
												or data of the users posted on the Site.'
												<br />
												'20.2. We do not authorize anyone to make a warranty on Our behalf and
												You shall not rely on any statement of warranty as a warranty by Us.'
												<br />
												'20.3. NowWagon and their representatives, officers, employees, agents
												and contractors shall not be liable for any loss, damage, claim,
												expense, cost (including legal costs) or liability arising directly or
												indirectly from Your use or non-use of the Service or the Site, or Your
												reliance upon the Service or the information contained upon the Site
												(whether arising from NowWagon or any other persons negligence or
												otherwise).'
												<br />
												'20.4. This Site, Application and all content on the Site and the
												Application are provided on an “as is” basis without warranties of any
												kind, either express or implied, including without limitation warranties
												of title or implied warranties of merchantability or fitness for a
												particular purpose. You acknowledge, by Your access of the Site and/or
												Application, that Your access of the Site and/or Application and
												availing of Services is at Your sole risk, that You assume full
												responsibility for Your access and use of the Site and/or Application,
												and that NowWagon shall not be liable for any damages of any kind
												related to Your access and use of this Site and/or Application.'
												<br />
												'20.5. All images, audio, video and text in the Site and/or Application
												are only for illustrative purposes. None of the models, actors or
												products in the images, if any is endorsing the Services in any manner.
												Any resemblance of any matter to anybody or anything is purely
												unintentional and/or coincidental.'
												<br />
												'20.6. NowWagon is not engaged in the insurance business and does not
												provide any insurance services. NowWagon has facilitated provision of
												insurance services for the benefit of Customers, under group travel
												insurance policies availed from insurance companies, whereby NowWagon is
												acting as the group manager of the policy. NowWagon does not guarantee
												or make any promise in relation to the insurance policy/ insurance
												company, including but not limited to any benefits from use of the
												insurance services provided by the insurance companies, such as
												coverage, claims and settlements. Any claim or servicing of insurance
												policies, raised through the Application, remains a matter between the
												Customer and the insurance company, and NowWagon shall not be
												responsible for the actions of the insurance company or the Customer. It
												is to be noted that the insurance policy number, claim certificate,
												invoice for the insurance premium/ fee collected from the Customer is
												not be issued by NowWagon, but will be issued by the respective
												insurance company. Any issues in receiving tax invoices or issues faced
												in claiming the insurance are not the responsibility of NowWagon.
												NowWagon disclaims all and all liability in relation to the services of
												the insurance company. Please reach out to the insurance company in case
												of any questions/ issues.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'21. MODIFICATION OF THE SERVICE AND USER TERMS'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												'21.1. NowWagon reserves the right, at its sole discretion, to modify or
												replace, in part or full, any of these User Terms, or change, suspend,
												block, discontinue or restrict your use to all or any feature of the
												Service or Application at any time.'
												<br />
												'21.2. NowWagon shall not be required to notify You of any changes made
												to these User Terms. The revised User Terms shall be made available on
												the Site. You are requested to regularly visit the Site to view the most
												current User Terms. You can determine when NowWagon last modified the
												User Terms by referring to the “Last Updated” legend above. It shall be
												Your responsibility to check these User Terms periodically for changes.
												NowWagon may require You to provide Your consent to the updated User
												Terms in a specified manner prior to any further use of the Site and the
												Services. If no such separate consent is sought, Your continued use of
												the Site, following the changes to the User Terms, will constitute Your
												acceptance of those changes. Your use of the Site and the Services is
												subject to the most current version of the User Terms made available on
												the Site at the time of such use.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'22. NOTICE'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<br />
											'22.1. NowWagon may give notice by means of a general notice on the Service
											or Application, or by electronic mail to Your email address or a message on
											Your registered mobile number, or by written communication sent by regular
											mail to Your address on record in NowWagon’s account information.'
											<br />
											'22.2. You may contact NowWagon by electronic mail at Our email address
											legalnotice@nowwagon.com or by written communication sent by regular mail to
											Our address at S. No. 130/3, Shop no. 1, Next to KuberaBahaar,
											Baner-PashanLink road, Pashan, Pune - 411021'
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'23. ASSIGNMENT'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'23.1. You shall not assign Your rights under these User Terms without
												prior written approval of NowWagon. NowWagon can assign its rights under
												the User Terms to any affiliate.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'24. SUB CONTRACTING'</b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'(a) No Subcontractor, agent or servant shall in any circumstances
												whatsoever be under any liability whatsoever to the Merchant for any
												loss, damage or delay of whatsoever kind arising or resulting directly
												or indirectly from any act, neglect or default on the Subcontractor,
												agent or servant’s part while acting in the course of or in connection
												with the Goods or the Carriage of the Goods. '
												<br />
												'(b) (i) The Merchant undertakes that no claim or allegation whether
												arising in contract, bailment, tort or otherwise shall be made against
												any servant, agent, or Subcontractor of NowWagon which imposes or
												attempts to impose upon any of them or any vessel owned or chartered by
												any of them any liability whatsoever in connection with the Goods or the
												Carriage of the Goods whether or not arising out of negligence on the
												part of such Person. The Subcontractor, agent or servant shall also be
												entitled to enforce the foregoing covenant against the Merchant; and
												(ii) if any such claim or allegation should nevertheless be made, to
												indemnify NowWagon against all consequences thereof.'
												<br />
												(c) Without prejudice to the generality of the foregoing provisions of
												this clause, every exemption, limitation, condition and liberty
												contained herein and every right, exemption from liability, defence and
												immunity of whatsoever nature applicable to NowWagon or to which
												NowWagon is entitled hereunder including the right to enforce any
												jurisdiction provision contained herein shall also be available and
												shall extend to every such Subcontractor, agent or servant, who shall be
												entitled to enforce the same against the Merchant. The provisions of
												clause 25 (c) including but not limited to the undertaking of the
												Merchant contained therein, shall extend to all claims or allegations of
												whatsoever nature against other Persons chartering space on the carrying
												vessel.
												<br />
												'No Goods which are or which may become of a dangerous, noxious,
												hazardous, flammable, or damaging nature (including radioactive
												material) or which are or may become liable to damage any Persons or
												property whatsoever, and whether or not so listed in any official or
												unofficial, international or national code, listing or table shall be
												tendered to NowWagon for Carriage without previously giving written
												notice of their nature, character, name, label and classification (if
												applicable) to NowWagon and obtaining his consent in writing and without
												distinctly marking the Goods and the Container or other covering on the
												outside so as to indicate the nature and character of any such Goods and
												so as to comply with any applicable laws, regulations or requirements. '
												<br />
												'If any such Goods are delivered to NowWagon without obtaining his
												consent and/or such marking, or if in the opinion of NowWagon the Goods
												are or are liable to become of a dangerous, noxious, hazardous,
												flammable or damaging nature, they may at any time or place be unloaded,
												destroyed, disposed of, abandoned or rendered harmless without
												compensation to the Merchant and without prejudice to NowWagon’s right
												to Freight.'
												<br />
												'Our services are not suitable for transportation of valuables like
												cash, gold, silver, diamond, precious stones, jewels or jewellery,
												expensive luxury items etc. (“Valuables”). If you handover / load the
												vehicles with Valuables for transportation, any loss / damage / theft /
												misappropriation to / of the consignment shall be at your risk and not
												ours, for the reasons mentioned earlier and without prejudice, we shall
												not only have the right to explicitly and specifically disclaim any
												liability and/or responsibility arising/accruing from the damage / loss
												/ theft / misappropriation to/of the consignment or any contents of the
												consignment, but also the right to claim indemnification from you where
												we have suffered loss of reputation / goodwill due to your actions of
												breaching our terms of service.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<b>'25. APPLICABLE LAW AND DISPUTE RESOLUTION' </b>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<p>
												<br />
												'These User Terms are subject to the laws of India. Any dispute, claim
												or controversy arising out of or relating to these User Terms or the
												breach, termination, enforcement, interpretation or validity thereof or
												the use of the Site, the Service or the Application (collectively,
												“Disputes”) the parties shall attempt to settle the same amicably,
												through negotiation and consultation at such offices of NowWagon as
												NowWagon may designate. In the event the dispute is not resolved
												internally between after at least 30 (thirty) days of negotiation, in
												good faith, the same shall be subject to binding and final arbitration
												in accordance with the Arbitration and Conciliation Act, 1996 as amended
												from time to time or in case the Arbitration and Conciliation Act, 1996
												is no longer in force, as per any law relating to arbitration in force
												at the time of such reference. The reference shall be made to a sole
												arbitrator mutually appointed by NowWagon and You. The place of the
												arbitration shall be Pune, Maharashtra, unless otherwise mutually agreed
												by NowWagon and You in writing. Subject to the above, any Dispute will
												be subject to the exclusive jurisdiction of courts in Pune, India.'
											</p>
										</AccordionItemPanel>
									</AccordionItem>
								</Accordion>
							</p>
						</Typography>
					</ThemeProvider>
				</GridItem>
			</Container>
		</Container>
	);
}
