import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
	MenuItem,
	TextField,
	Button,
	TableContainer,
	TableHead,
	Divider,
	FormControl,
	FormLabel,
	RadioGroup,
	Radio,
	FormControlLabel,
	TextareaAutosize,
	List,
	ListItem,
	IconButton,
	Snackbar,
} from "@material-ui/core";
import { ListItemButton, ListItemIcon, ListItemText, Checkbox, Alert } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

//import { useNavigate } from "react-router";
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import { useEffect } from "react";
import { getAllProvider } from "../../services/users/userOnboarding";
import { sendNotifications, setPullNotificationsStatus } from "../../services/notifications/Notification";
import { getSelectedDrivers } from "../../services/drivers/Drivers";
import { useSnackbar } from "notistack";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function Notification() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [allProvider, setAllProvider] = useState([]);
	const [mainProviderList, setMainProviderList] = useState([]);
	const [selectedDriver, setSelectedDriver] = useState([]);
	const [allNameToRole, setAllNameToRole] = useState([]);
	const [selectedDriverForNotification, setSelectedDriverForNotification] = useState([]);
	const [successToastOpen, setSuccessToastOpen] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};

	const handleClose = (event) => {
		setSuccessToastOpen(false);
	};

	const userProfile = JSON.parse(localStorage.getItem("USER_PROFILE"));

	let user = userProfile.firstName + " " + userProfile.lastName;

	const [inputValues, setInputValues] = useState({
		state: "",
		city: "",
		operator: "",
		message: "",
		userRole: "",
		title: "",
		createdBy: user,
		receivers: [],
	});
	const { state, city, operator, userRole, message, title, receivers } = inputValues;

	const handleToggle = (value) => () => {
		const currentIndex = selectedDriverForNotification.indexOf(value);
		const newChecked = [...selectedDriverForNotification];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		let receivers = [];
		Object.keys(newChecked).forEach((key) => {
			receivers.push(newChecked[key].email);
		});
		setInputValues({ ...inputValues, receivers: receivers });

		setSelectedDriverForNotification(newChecked);
	};

	const handleChange = (name) => (event) => {
		let valueToSet = event.target.value;
		if (name == "operator") {
			setSelectedDriver([]);
		}
		setInputValues({ ...inputValues, [name]: valueToSet });
	};
	const handleOperatorChange = (name) => (event) => {
		let valueToSet = event.target.value;
		let user = allNameToRole.filter((row) => row.name === valueToSet);
		console.log(user);
		setInputValues({ ...inputValues, [name]: valueToSet, userRole: user[0].userRole });
	};
	const validationCheck = () => {
		let errorMessage = "";
		if (state == "") {
			errorMessage = "Please Select State";
		} else if (city == "") {
			errorMessage = "Please Select City";
		} else if (operator == "") {
			errorMessage = "Please Select Operator";
		} else if (title == "") {
			errorMessage = "Please Enter Message Subject";
		} else if (message == "") {
			errorMessage = "Please Enter Message";
		} else if (userRole !== "ALL OPERATORS" && userRole !== "PROVIDER-DRIVER" && inputValues.receivers?.length <= 0) {
			errorMessage = "Please Select Driver ";
		}
		if (errorMessage !== "") {
			showToast(errorMessage, "error");
			return false;
		}
		return true;
	};

	const handleSearch = () => {
		if (state == "") {
			showToast("Please select State", "error");
		} else if (city == "") {
			showToast("Please select City", "error");
		} else if (operator == "") {
			showToast("Please select Operator", "error");
		}

		if (state && city && operator) {
			toggleLoader();
			let selectedOperator = mainProviderList.filter((element) => element.businessInfo?.businessName == operator);
			let operatorEmail = selectedOperator[0].businessInfo.officeEmail;
			getSelectedDrivers(city, operatorEmail).then((driver) => {
				setSelectedDriver(driver);
				closeLoader();
			});
		}
	};

	const onSend = () => {
		let tempInitialValues = {};
		toggleLoader();
		if (!validationCheck()) {
			closeLoader();
			return;
		}
		if (userRole === "ALL OPERATORS" || userRole === "PROVIDER-DRIVER") {
			// inputValues.receivers = [];
			let temp = [];
			if (userRole === "ALL OPERATORS") {
				mainProviderList.forEach((provider) => {
					// inputValues.receivers.push(provider?.businessInfo?.officeEmail);
					temp.push(provider?.businessInfo?.officeEmail);
				});
			}
			if (userRole === "PROVIDER-DRIVER") {
				let provider = null;
				mainProviderList.forEach((pro) => {
					if (operator === pro.businessInfo?.businessName) {
						provider = pro;
					}
				});
				temp.push(provider?.businessInfo?.officeEmail);
			}
			setInputValues({
				...inputValues,
				receivers: temp,
			});
			tempInitialValues = { ...inputValues, receivers: temp, createdOn: new Date(), createOnMil: new Date().getTime() };
		}
		console.log(tempInitialValues);
		sendNotifications(
			userRole === "ALL OPERATORS" || userRole === "PROVIDER-DRIVER" ? tempInitialValues : inputValues
		).then((response) => {
			let sentNotification = [];
			if (tempInitialValues.receivers) {
				tempInitialValues.receivers.map((emailId) => {
					sentNotification.push(setPullNotificationsStatus(emailId));
				});
			}
			if (operator !== "ALL OPERATORS") {
				inputValues.receivers.map((emailId) => {
					sentNotification.push(setPullNotificationsStatus(emailId));
				});
			}
			Promise.all(sentNotification).then(() => {
				closeLoader();
				setInputValues({
					state: "",
					city: "",
					operator: "",
					message: "",
					title: "",
					CreatedOn: new Date(),
					createdBy: user,
					receivers: [],
				});
				showToast("Notification sent successfully", "success");
			});
		});
		resetForm();
	};

	useEffect(() => {
		getAllProvider().then((response) => {
			let providerData = [];
			let allRoleList = [];
			providerData.push("ALL OPERATORS");
			allRoleList.push({ name: "ALL OPERATORS", userRole: "ALL OPERATORS" });
			Object.keys(response).forEach((key) => {
				providerData.push(response[key].businessInfo?.businessName);
				allRoleList.push({ name: response[key].businessInfo?.businessName, userRole: response[key].role });
			});
			setAllProvider(providerData);
			setMainProviderList(response);
			setAllNameToRole(allRoleList);
		});
	}, []);

	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};

	const resetForm = () => {
		setInputValues({
			...inputValues,
			state: "",
			city: "",
			vehicleNumber: "",
			operator: "",
			message: "",
			title: "",
			timestamp: new Date(),
		});
		setSelectedDriverForNotification([]);
		setSelectedDriver([]);
	};
	return (
		<Container>
			<Container maxWidth={false}>
				<GridItem xs={12} sm={12} md={12}>
					<Typography className={classes.title}> Notification </Typography>
					<ThemeProvider theme={theme}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="state"
									label="State"
									type="text"
									id="state"
									select
									value={state}
									onChange={handleChange("state")}
								>
									<MenuItem value="Maharashtra">Maharashtra</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="city"
									label="City"
									type="text"
									id="city"
									select
									value={city}
									onChange={handleChange("city")}
								>
									<MenuItem value="Pune">Pune</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="operator"
									label="Operator"
									type="text"
									id="operator"
									select
									value={operator}
									onChange={handleOperatorChange("operator")}
								>
									{allProvider.map((value, index) => (
										<MenuItem value={value} key={index}>
											{value}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									disabled={userRole === "ALL OPERATORS" || userRole === "PROVIDER-DRIVER" ? true : false}
									onClick={() => handleSearch()}
								>
									Search Driver
								</Button>
							</GridItem>
						</GridContainer>
						<GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>
						<Divider></Divider>
						<GridContainer xs={12} sm={12} md={12}>
							<GridItem xs={12} sm={12} md={6}>
								<List
									sx={{
										width: "100%",
										maxWidth: 360,
										bgcolor: "background.paper",
									}}
									style={{ maxHeight: 200, overflow: "auto", width: "211%" }}
								>
									{selectedDriver.map((value) => {
										const labelId = `checkbox-list-label-${value.firstName}`;
										//console.log("checked", value.firstName);
										return (
											<ListItem
												key={value}
												secondaryAction={
													<IconButton edge="end" aria-label="comments">
														<CommentIcon />
													</IconButton>
												}
												disablePadding
											>
												<ListItemButton role={undefined} onClick={handleToggle(value)} dense>
													<ListItemIcon>
														<Checkbox
															edge="start"
															checked={selectedDriverForNotification.indexOf(value) !== -1}
															tabIndex={-1}
															disableRipple
															inputProps={{ "aria-labelledby": labelId }}
														/>
													</ListItemIcon>
													<ListItemText
														id={labelId}
														primary={`${value.firstName + "  " + value.middleName + "  " + value.lastName}`}
													/>
												</ListItemButton>
											</ListItem>
										);
									})}
								</List>
							</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								<TextField
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="title"
									label="Subject"
									type="text"
									id="title"
									value={title}
									onChange={handleChange("title")}
								/>
							</GridItem>
							<GridItem style={{ paddingTop: 20 }}>&nbsp;</GridItem>
							<GridItem xs={12} sm={12} md={12}>
								<TextareaAutosize
									rowsMin={10}
									type="text"
									style={{ width: "100%", height: "100%" }}
									margin="normal"
									aria-label="maximum height"
									placeholder="&nbsp;Please enter your message here*"
									value={message}
									onChange={handleChange("message")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={9}>
								&nbsp;
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									onClick={onSend}
									fullWidth="true"
								>
									Send
								</Button>
							</GridItem>
						</GridContainer>
					</ThemeProvider>
				</GridItem>
			</Container>
			<Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
			{/* <Snackbar
				open={successToastOpen}
				autoHideDuration={2000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert onClose={handleClose} severity="success">
					Notification Sent Successully.
				</Alert>
			</Snackbar> */}
		</Container>
	);
}
