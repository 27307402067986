import FbApp from "../../config/FbApp";
import { isAdmin, isExecutive, isProvider } from "../../screens/user/AddUserView";
import { createRandomString } from "../../util/utilFunctions";
import { signUp } from "../authentication/authentication";

export default function UserOnBoarding() {}

const userOnBoard = async (payload) => {
	const auth = FbApp.auth();
	const db = FbApp.firestore();
	var userRef = db.collection("ADMIN_USER");
	//create a default password for executive.
	let defaultPassword = createDefaultPasswordForExecutive();
	return auth.createUserWithEmailAndPassword(payload.email, defaultPassword).then((response) => {
		if (response) {
			payload.defaultPassword = defaultPassword;
			// //console.log(paylod);
			userRef.doc(payload.email).set(payload);
		}
		return response;
	});
};



const createDefaultPasswordForExecutive = () => {
	let randomString = createRandomString(6);
	if (!randomString) {
		randomString = "fb1234";
	}
	return randomString;
};

const userOnBoardProvider = async (payload) => {
	const auth = FbApp.auth();
	const db = FbApp.firestore();
	var userRef = db.collection("PROVIDER_USER");
	return auth.createUserWithEmailAndPassword(payload.businessInfo.officeEmail, "fb1234").then((response) => {
		if (response) {
			//console.log('userCreated,',response)
			userRef
				.doc(payload.businessInfo.officeEmail)
				.set(payload, { merge: true })
				.then((data) => {
					//console.log(data);
				})
				.catch((error) => {
					//console.log(error);
				});
		}
		return response;
	});
};

const getAllUser = () => {
	const db = FbApp.firestore();
	return db
		.collection("ADMIN_USER")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => doc.data());
			//console.log("all user", docData);
			return docData;
		});
};

export const getAllExecutive = () => {
	const db = FbApp.firestore();
	return db
		.collection("ADMIN_USER")
		.where("role", "==", "EXECUTIVE")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => doc.data());
			//console.log("all executive", docData);
			return docData;
		});
};

export const getAllAdmin = () => {
	const db = FbApp.firestore();
	return db
		.collection("ADMIN_USER")
		.where("role", "==", "ADMIN")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => doc.data());
			//console.log("all executive", docData);
			return docData;
		});
};

export const getAllProviderAndDriver = () => {
	const db = FbApp.firestore();
	return db
		.collection("PROVIDER_USER")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => doc.data());
			//   //console.log("all provider", docData);
			// docData.forEach(data =>{
			//   data.fullName = data.contactDetails.contactFirstName + " " + data.contactDetails.contactMiddleName + " " + data.contactDetails.contactLastName;
			// })
			return docData;
		});
};

export const getAllProvider = () => {
	const db = FbApp.firestore();
	const providerCollection = db.collection("PROVIDER_USER");
	return providerCollection
		.where("role", "in", ["PROVIDER", "PROVIDER-DRIVER"])
		.get()
		.then((snapshots) => {
			// return providerCollection.get().then(snapshots => {
			var docData = snapshots.docs.map((doc) => doc.data());
			//console.log("all provider", docData);
			// docData.forEach(data =>{
			//   data.fullName = data.contactDetails.contactFirstName + " " + data.contactDetails.contactMiddleName + " " + data.contactDetails.contactLastName;
			// })

			return docData;
		});
};

const getAdminUserByUsername = (username) => {
	const db = FbApp.firestore();
	var docRef = db.collection("ADMIN_USER").doc(username);
	return docRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				//console.log("Current user admin/executive  data:", doc.id);
				// //console.log(doc.data().address,"address")
				return doc.data();
			} else {
				/*  docRef = db.collection("PROVIDER_USER").doc(username);
      return docRef.get().then((doc) => {
        if (doc.exists) {
          //console.log("Current user Provider/driver  data:", doc.data());
          return doc.data();
        } else {
          //console.log("No such user found!",username);
        }

      }).catch((error) => {
        //console.log("Error getting document:", error);
        return error;
      }); */
				return null;
			}
		})
		.catch((error) => {
			//console.log("Error getting document:", error);
			return error;
		});
};

const getProviderByUsername = (username) => {
	const db = FbApp.firestore();
	var docRef = db.collection("PROVIDER_USER").doc(username);
	return docRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				//console.log("Current user admin/executive  data:", doc.id);
				// //console.log(doc.data().address,"address")
				return doc.data();
			} else {
				/* 
      docRef = db.collection("PROVIDER_USER").doc(username);
      return docRef.get().then((doc) => {
        if (doc.exists) {
          //console.log("Current user Provider/driver  data:", doc.data());
          return doc.data();
        } else {
          //console.log("No such user found!",username);
        }

      }).catch((error) => {
        //console.log("Error getting document:", error);
        return error;
      }); */
				return null;
			}
		})
		.catch((error) => {
			//console.log("Error getting document:", error);
			return error;
		});
};

export const uploadDocument = (file) => {
	const storageRef = FbApp.storage().ref();
	const fileRef = storageRef.child(file.name);
	fileRef.put(file).then(() => {
		console("file uploaded", file.name);
	});
};

// export const updateBooking = async(booking) => {
// 	var user = firebase.auth().currentUser;
// 	const db = firebase.firestore();
//    await db.collection("RIDE_REQUESTS").doc(booking.id).update(booking).then(response => {
// 		return response;
// 	})
// }
export const activateDeactiveUser = async (user) => {
	const db = FbApp.firestore();
	if (user?.role === "EXECUTIVE" || user?.role === "ADMIN" ) {
		return db
			.collection("ADMIN_USER")
			.doc(user.email)
			.set(user, { merge: true })
			.then((response) => {
				if (response) {
					return response;
				}
			});
	} else if (user?.businessInfo?.role === "PROVIDER-DRIVER") {
		const providerCollection = db.collection("PROVIDER_USER");
		return providerCollection
			.doc(user.businessInfo.officeEmail)
			.set(user, { merge: true })
			.then((response) => {
				if (response) {
					return response;
				}
			})
			.catch((error) => {
				//console.log("Error saving provider... ", error);
			})
			.finally((data) => {
				//console.log("finally...", data);
			});
	} else if (user?.businessInfo?.role === "PROVIDER") {
		return (
			db
				.collection("PROVIDER_USER")
				.doc(user.businessInfo.officeEmail)
				// .update(user)
				.set(user, { merge: true })
				.then((response) => {
					if (response) {
						return response;
					}
				})
				.catch((error) => {
					//console.log("Error saving provider... ", error);
				})
				.finally((data) => {
					//console.log("finally...", data);
				})
		);
	} else if (user?.role === "DRIVER") {
		// return db.collection("PROVIDER_USER").doc(user.email).update(user).then(response => {
		return db
			.collection("PROVIDER_USER")
			.doc(user.email)
			.set(user, { merge: true })
			.then((response) => {
				if (response) {
					return response;
				}
			});
	} 
};

export const updateUserProfile2 = (user) => {
	const db = FbApp.firestore();
	if (user.role === "PROVIDER-DRIVER") {
		return db
			.collection("PROVIDER-DRIVER_USER")
			.doc(user.email)
			.update(user)
			.then((response) => {
				if (response) {
					return response;
				}
			});
	} else if (user?.role === "PROVIDER") {
		return db
			.collection("PROVIDER_USER")
			.doc(user.contactEmail)
			.update(user)
			.then((response) => {
				if (response) {
					return response;
				}
			});
	}
};

export const onBoardOperator = (user) => {
	const db = FbApp.firestore();
	if (user?.role === "PROVIDER-DRIVER" || user?.role === "PROVIDER") {
		return db
			.collection("ONBOARDED_OPERATORS")
			.doc(user.email)
			.set(user)
			.then((response) => {
				if (response) {
					return response;
				}
			});
	}
};

export const getAddress = async (pinNumber) => {
	const url = "https://api.postalpincode.in/pincode/" + pinNumber;
	const response = await fetch(url);
	const jsonData = await response.json();
	//console.log(jsonData);
	if (response) {
		return jsonData[0];
	}
};

export const getPinAddress = (pinNumber) => {
	const url = "https://api.postalpincode.in/pincode/" + pinNumber;

	return fetch(url)
		.then((response) => {
			const jsonData = response.json();
			//console.log('Response With fetch()', response);
			if (response.status === 200) {
				return jsonData[0];
			} else {
				let errorData = {
					error: true,
					status: response.status,
				};
				return errorData;
			}
		})
		.then((data) => {
			//console.log('Data With fetch()', data);
			if (data.error) {
				//console.log('Incorrect Credentials... ');
				return false;
			} else {
				return data;
			}
		})
		.catch((error) => {
			console.error("Error:", error);
		});
};

export const getAllCustomer = () => {
	const db = FbApp.firestore();
	return db
		.collection("CUSTOMER_USER")
		.get()
		.then((snapshots) => {
			var docData = snapshots.docs.map((doc) => doc.data());
			//   //console.log("all provider", docData);
			// docData.forEach(data =>{
			//   data.fullName = data.contactDetails.contactFirstName + " " + data.contactDetails.contactMiddleName + " " + data.contactDetails.contactLastName;
			// })
			return docData;
		});
};

const getAdminOrExecutiveByUserName = (username) => {
	const db = FbApp.firestore();
	var docRef = db.collection("ADMIN_USER").doc(username);
	return docRef
		.get()
		.then((doc) => {
			if (doc.exists) {
				return doc.data();
			}
		})
		.catch((error) => {
			//console.log("Error fetching user data:", error);
			return error;
		});
};

export { userOnBoard };
export { getAllUser };
export { getAdminUserByUsername };
export { userOnBoardProvider };
export { getAdminOrExecutiveByUserName };
export { getProviderByUsername };
// export {onBoardOperator};
