import React from 'react';
import CustomTabs from '../../components/CustomTabs/CustomTabs';

import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import VehicleTypeList from './VehicleTypeList'
import VehicleType from './VehicleType';



export default function VehicleTypeManagement() {


    return (        
        <CustomTabs
            headerColor="warning"
            tabs={[
            
              {
                tabName: "Vehicle Type List",
                tabIcon: FormatListBulletedIcon,
                allowed: true,
                variant:"fullWidth",
                tabContent: (
                  <VehicleTypeList />
                )
              },
              {
                tabName: "Add Vehicle Type",
                tabIcon: AddIcon,
                allowed: true,
                tabContent: (
                  < VehicleType/>
                )
              },
            ]}
          />
    );
}
