import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	Button,
	TextField,
	ThemeProvider,
	createMuiTheme,
	MenuItem,
} from "@material-ui/core";
//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import GridContainer from "../../components/Grid/GridContainer";
import { orange } from "@material-ui/core/colors";

const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));

export default function VehicleTracking() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};
	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={2}></GridItem>
			<GridItem xs={12} sm={12} md={8}>
				<form className={classes.root} noValidate autoComplete="on">
					<Typography component="h2" variant="h2" style={{ textAlign: "center", padding: 20 }}>
						Vehicle Tracking
					</Typography>
					<GridContainer>&nbsp;</GridContainer>
					<ThemeProvider theme={theme}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={4}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="City"
									label="City"
									type="text"
									id="City"
									select
								>
									<MenuItem value="1">Pune</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={4}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									required
									fullWidth
									name="Vehiclename"
									label="Vehicle"
									type="text"
									id="Vehiclename"
									select
									// value={city}
									// onChange={handleChange("city")}
								>
									<MenuItem value="TATA ACE">TATA ACE</MenuItem>
									<MenuItem value="MAHINDRA CHAMPION">MAHINDRA CHAMPION</MenuItem>
									<MenuItem value="ASHOK LEYLAND DOST">ASHOK LEYLAND DOST</MenuItem>
									<MenuItem value="MARUTI OMNI MPI CARG">MARUTI OMNI MPI CARG</MenuItem>
									<MenuItem Value="TATA LPK">TATA LPK</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={4}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									//required
									fullWidth
									name="vehicle No"
									label="Vehicle Number"
									type="text"
									id="vehicle No"
									select
									// value={salutation}
									// onChange={handleChange('salutation')}
								>
									<MenuItem value="1">MH-12-KE 2564</MenuItem>
									<MenuItem value="2">MH-10-KS 8975</MenuItem>
									<MenuItem value="3">MH-01-AS 5648 </MenuItem>
								</TextField>
							</GridItem>
						</GridContainer>
						<GridContainer>
							{/* <GridItem xs={12} sm={12} md={4}>
                              <TextField
                                  size='small'
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  name="city"
                                  label="City"
                                  type="text"
                                  id="city"
                                  select
                                  // value={city}
                                  // onChange={handleChange("city")}
                              >
                                <MenuItem value='pune'>pune</MenuItem>
                                <MenuItem value='mumbai'>mumbai</MenuItem>
                                <MenuItem value='nashik'>nashik</MenuItem>
                              </TextField>
                          </GridItem> */}
							{/* <GridItem xs={12} sm={12} md={4}>
                              <TextField
                              // InputLabelProps={{shrink:true}}
                                  size='small'
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  type ='text'
                                  name="amount"
                                  label="Amount"
                                  id="amount"
                                  // value={amount}
                                  // onChange={handleChange("amount")}

                              />
                          </GridItem> */}
							{/* <GridItem xs={12} sm={12} md={4}>
                              <TextField
                              // InputLabelProps={{shrink:true}}
                                  size='small'
                                  variant="outlined"
                                  margin="normal"
                                  required
                                  fullWidth
                                  type ='text'
                                  name="otherCharges"
                                  label="Other Charges"
                                  id="otherCharges"
                                  // value={otherCharges}
                                  // onChange={handleChange("otherCharges")}

                              />
                          </GridItem> */}
						</GridContainer>
						<GridContainer>&nbsp;</GridContainer>
						<GridContainer>
							<GridItem xs={12} sm={12} md={8}></GridItem>
							<GridItem xs={12} sm={12} md={4}>
								<Button variant="contained" className={classes.button} fullWidth="true">
									Search
								</Button>
							</GridItem>
						</GridContainer>
					</ThemeProvider>
				</form>
			</GridItem>
			{/* <Snackbar open={successToastOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'center', horizontal: 'center', }}>
              <Alert onClose={handleClose} severity="success">
                  User Added Successully.
                  </Alert>
          </Snackbar>
          <Snackbar open={failureToastOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'center', horizontal: 'center', }}>
              <Alert onClose={handleClose} severity="error">
                  Please Fill All Mandetory Feilds !
                  </Alert>
          </Snackbar> */}
			{/* <Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
              {/* <CircularProgress color="inherit" /> */}
			{/* <Lottie
                  options={loaderOptions}
                  height={200}
                  width={600}
              /> */}
			{/* </Backdrop> */}
		</GridContainer>
	);
}
