import React, { useEffect, useState } from 'react';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { useRef } from 'react';
import {
	TextField,
	MenuItem,
	FormControlLabel,
	Checkbox,
	Button,
	Typography,
	Snackbar,
	Backdrop,
	CircularProgress,
	createMuiTheme,
	ThemeProvider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Person from '@material-ui/icons/Person';
import { DropzoneDialog } from 'material-ui-dropzone';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { activateDeactiveUser, getAddress, updateUserProfile } from '../../services/users/userOnboarding';
import Alert from '@material-ui/lab/Alert';
import { orange } from '@material-ui/core/colors';
import FbApp from '../../config/FbApp';
import Lottie from 'react-lottie';
import animationData from '../../assets/jss/lf30_editor_fvhlirkb.json';
import { useSnackbar } from 'notistack';

const loaderOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	root: {
		margin: theme.spacing(1),
		backgroundColor: '#fff',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		margin: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: '#FF8000',
		color: '#fff',
		'&:hover': {
			backgroundColor: '#c55100',
		},
	},
}));

const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function UpdateExecutiveProfile() {
	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);

	const [user, setUser] = useState({});
	const [areaList, setAreaList] = useState([]);
	const [currentAreaList, setCurrentAreaList] = useState([]);
	const [checked, setChecked] = useState(false);
	const [successToastOpen, setSuccessToastOpen] = useState(false);
	const [failureToastOpen, setFailureToastOpen] = useState(false);
	const [profileEdit, setProfileEdit] = useState(false);
	const [addressCheck, setAddressCheck] = useState(false);
	const [error1, setErrorMessage] = useState(false);
	const [aadharError, setAadharError] = useState(false);
	const [drivingLicenseError, setDrivingLicenseError] = useState(false);
	const [mobileNumberError, setMobileNumberError] = useState(false);
	// Noti snackbar handling...
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : 'info',
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center',
			},
		});
	};

	const [values, setValues] = useState({
		salutation: '',
		firstName: '',
		middleName: '',
		lastName: '',
		role: '',
		email: '',
		mobileNumber: '',
		telephone: '',
		panNumber: '',
		aadharNumber: '',
		drivingLicense: '',
		addressProof: '',
		currentAddress: '',
		currentLocality: '',
		currentPin: '',
		currentLandmark: '',
		currentCity: '',
		currentState: '',
		address: '',
		landmark: '',
		pin: '',
		locality: '',
		city: '',
		state: '',
	});

	const closeLoader = () => {
		setOpenLoader(false);
	};
	const toggleLoader = () => {
		setOpenLoader(!openLoader);
	};

	const handleClose = (event) => {
		setSuccessToastOpen(false);
		setFailureToastOpen(false);
	};

	React.useEffect(() => {
		let userProfile = localStorage.getItem('USER_PROFILE');
		//console.log('profile', userProfile);
		setUser(JSON.parse(userProfile));
	}, []);

	const validationCheck = () => {
		let errorMessage = '';
		////console.log("validation Before", user.role);

		if (user.salutation === '') {
			errorMessage = 'Please Enter Salutation Name';
		} /* else if (user.role == "") {
			errorMessage = "Please Enter Designation Name";
		}  */
		// else if (user.lastName === '') {
		// 	errorMessage = 'Please Enter Valid Last Name';
		// }
		else if (user.firstName === '') {
			errorMessage = 'Please Enter Valid First Name';
		} else if (user.mobileNumber === '') {
			errorMessage = 'Please Enter mobile Number';
		} else if (user.mobileNumber != '' && user.mobileNumber.length != 10) {
			errorMessage = 'Mobile number should be of 10 digits';
		} else if (user.email === '') {
			errorMessage = 'Please Enter Email ';
		} /* else if (user.currentPin === "") {
			errorMessage = "Please Enter Correct Pincode ";
		} else if (user.address === "") {
			errorMessage = "Please Enter Correct Address ";
		} else if (user.currentLocality === "") {
			errorMessage = "Please Enter Correct Current Locality ";
		} else if (user.currentCity !== "Pune") {
			errorMessage = "Please Enter Pune City PinCode ";
		} else if (user.aadharNumber == "") {
			errorMessage = "Please Enter Aadhar Number ";
		} else if (user.panNumber == "") {
			errorMessage = "Please Enter Pan Number ";
		} else if (user.role == "") {
			errorMessage = "Please Select Role ";
		} */
		//console.log('validation errorMessage', errorMessage);
		if (errorMessage !== '') {
			//   alert(errorMessage);
			showToast(errorMessage, 'error');
			return false;
		}
		return true;
	};
	const handleSubmit = () => {
		toggleLoader();
		if (!validationCheck()) {
			closeLoader();
			return;
		}
		activateDeactiveUser(user).then(
			(response) => {
				closeLoader();
				//console.log('Updated', response);
				// //console.log(user, "user Profile Updated")
				localStorage.setItem('USER_PROFILE', JSON.stringify(user));
				// setSuccessToastOpen(true);
				showToast('Profile updated successfully', 'success');
				setProfileEdit(false);
			},
			(error) => {
				//console.log('error', error);
				// setFailureToastOpen(true);
				showToast('Error while updating profile', 'error');
			}
		);
	};

	const handleEdit = () => {
		setProfileEdit(true);
	};

	const handleChange = (name) => (event) => {
		setUser({ ...user, error: false, [name]: event.target.value });
		if (name === 'mobileNumber') {
			let mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

			if (!mobileNumberRegex.test(event.target.value)) {
				setMobileNumberError(!mobileNumberRegex.test(event.target.value));
				return;
			} else if (mobileNumberRegex.test(event.target.value)) {
				setMobileNumberError(null);
			}
		}
		/* if (name === "panNumber") {
      let regex1 = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

      if (!regex1.test(event.target.value)) {
        setErrorMessage(!regex1.test(event.target.value));
        return;
      } else if (regex1.test(event.target.value)) {
        setErrorMessage(null);
      }
    }

    if (name === "aadharNumber") {
      let aadharRegex = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;

      if (!aadharRegex.test(event.target.value)) {
        setAadharError(!aadharRegex.test(event.target.value));
        return;
      } else if (aadharRegex.test(event.target.value)) {
        setAadharError(null);
      }
    }

    if (name === "drivingLicense") {
      let drivingLicenseRegex =
        /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;

      if (!drivingLicenseRegex.test(event.target.value)) {
        setDrivingLicenseError(!drivingLicenseRegex.test(event.target.value));
        return;
      } else if (drivingLicenseRegex.test(event.target.value)) {
        setDrivingLicenseError(null);
      }
    }
    
    if (name === "currentPin") {
      if (event.target.value.length === 6) {
        getAddress(event.target.value).then((response) => {
          if (response.PostOffice === null) {
            alert("pincode not match");
            return;
          } else if (response) {
            user.currentCity = response.PostOffice[0].District;
            user.currentState = response.PostOffice[0].State;
            user.currentLocality = "";
            setCurrentAreaList(response.PostOffice);
            setUser({ ...user, error: false, [name]: event.target.value });
          }
        });
      }
    }
    if (name === "pin") {
      if (event.target.value.length === 6) {
        getAddress(event.target.value).then((response) => {
          if (response.PostOffice === null) {
            alert("pincode not match");
            return;
          } else if (response) {
            user.city = response.PostOffice[0].District;
            user.state = response.PostOffice[0].State;
            user.locality = "";
            setAreaList(response.PostOffice);
            setUser({ ...user, error: false, [name]: event.target.value });
          }
        });
      }
    } */
	};

	/* const onCheckboxClick = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      user.address = user.currentAddress;
      user.pin = user.currentPin;
      user.city = user.currentCity;
      user.state = user.currentState;
      user.locality = user.currentLocality;
      // setUser({ ...user })
      setAddressCheck(true);
    } else {
      user.address = "";
      user.pin = "";
      user.city = "";
      user.state = "";
      user.locality = "";
      setAddressCheck(false);
      // setUser({ ...user })
    }
  }; */

	/* const getPathStorageFromUrl = (url) => {
    const baseUrl =
      `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/`;
    let imagePath = url.replace(baseUrl, "");

    const indexOfEndPath = imagePath.indexOf("?");
    imagePath = imagePath.substring(0, indexOfEndPath);
    imagePath = imagePath.replace("%2F", "/");
    //console.log("imagePath", imagePath);
    return imagePath;
  };

  const deleteFileByUrl = (link) => {
    //console.log("imagePath", link);
    const storageRef = FbApp.storage().ref();
    var deserRef = storageRef.child(getPathStorageFromUrl(link));
    deserRef
      .delete()
      .then(() => {
        //console.log("deleted");
        // user.contactPanDocumentLink = "";
      })
      .catch((error) => {
        //console.log(error);
      });
  }; */

	/* const handleUploadPan = async (e) => {
    if (onChangeUploadFile(e)) {
      if (user.panDocumentLink !== undefined) {
        deleteFileByUrl(user.panDocumentLink);
      }
      const file = e.target.files[0];
      const storageRef = FbApp.storage().ref();
      const fileRef = storageRef.child(
        user.officeEmail + "/" + "/PAN" + file.name
      );
      await fileRef.put(file);
      user.panDocumentLink = await fileRef.getDownloadURL();
      //console.log("testing docment upload", user.panDocumentLink);
    } else {
      e.target.value = null;
      alert("Supported file formats - pdf, jpg, png.");
    }
  };
  const handleUploadAadhar = async (e) => {
    if (onChangeUploadFile(e)) {
      if (user.aadharDocumentLink !== undefined) {
        deleteFileByUrl(user.aadharDocumentLink);
      }
      const file = e.target.files[0];
      const storageRef = FbApp.storage().ref();
      const fileRef = storageRef.child(
        user.officeEmail + "/" + "/AADHAR" + file.name
      );
      await fileRef.put(file);
      user.aadharDocumentLink = await fileRef.getDownloadURL();
      //console.log("testing docment upload", user.aadharDocumentLink);
    } else {
      e.target.value = null;
      alert("Supported file formats - pdf, jpg, png.");
    }
  };

  const handleUploadDrivingLicense = async (e) => {
    if (onChangeUploadFile(e)) {
      if (user.drivingLicenseDocumentLink !== undefined) {
        deleteFileByUrl(user.drivingLicenseDocumentLink);
      }
      const file = e.target.files[0];
      const storageRef = FbApp.storage().ref();
      const fileRef = storageRef.child(
        user.officeEmail + "/" + "/DRIVELICENSE" + file.name
      );
      await fileRef.put(file);
      user.drivingLicenseDocumentLink = await fileRef.getDownloadURL();
      //console.log("testing docment upload", user.drivingLicenseDocumentLink);
    } else {
      e.target.value = null;
      alert("Supported file formats - pdf, jpg, png.");
    }
  };

  const handleUploadAddressProof = async (e) => {
    if (onChangeUploadFile(e)) {
      if (user.addressProofDocumentLink !== undefined) {
        deleteFileByUrl(user.addressProofDocumentLink);
      }
      const file = e.target.files[0];
      const storageRef = FbApp.storage().ref();
      const fileRef = storageRef.child(
        user.officeEmail + "/" + "/ADDRESSPROOF" + file.name
      );
      await fileRef.put(file);
      user.addressProofDocumentLink = await fileRef.getDownloadURL();
      //console.log("testing docment upload", user.addressProofDocumentLink);
    } else {
      e.target.value = null;
      alert("Supported file formats - pdf, jpg, png.");
    }
  }; */

	const onChangeUploadFile = (evt) => {
		const files = evt.target.files[0];
		const ext = files.type.split('.').pop();
		alert(ext);
		if (ext == 'application/pdf' || ext == 'image/png' || ext == 'image/jpeg') {
			return true;
		}
	};

	return (
		<div className={classes.root}>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<form noValidate autoComplete="on">
						<Typography component="h1" variant="h2" style={{ textAlign: 'center', padding: 20 }}>
							My Profile
						</Typography>
						<ThemeProvider theme={theme}>
							<GridContainer>
								<GridItem xs={12} sm={12} md={3}>
									<TextField
										size="small"
										variant="outlined"
										margin="normal"
										fullWidth
										disabled={profileEdit ? false : true}
										name="salutation"
										label="Salutation"
										type="text"
										id="salutation"
										value={user.salutation}
										onChange={handleChange('salutation')}
										required
									></TextField>
								</GridItem>
								<GridItem xs={12} sm={12} md={3}>
									<TextField
										inputProps={{ pattern: '[a-z]', maxLength: 20 }}
										size="small"
										variant="outlined"
										margin="normal"
										fullWidth
										disabled={profileEdit ? false : true}
										name="firstName"
										label="First Name"
										type="text"
										id="firstName"
										value={user.firstName}
										onChange={handleChange('firstName')}
										required
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={3}>
									<TextField
										size="small"
										variant="outlined"
										margin="normal"
										disabled={profileEdit ? false : true}
										inputProps={{ maxLength: 20 }}
										fullWidth
										name="middleName"
										label="Middle Name"
										type="text"
										id="middleName"
										value={user.middleName}
										onChange={handleChange('middleName')}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={3}>
									<TextField
										size="small"
										variant="outlined"
										margin="normal"
										inputProps={{ maxLength: 20 }}
										disabled={profileEdit ? false : true}
										fullWidth
										name="lastName"
										label="Last Name"
										type="text"
										id="lastName"
										value={user.lastName}
										onChange={handleChange('lastName')}
									/>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={3}>
									<TextField
										size="small"
										variant="outlined"
										margin="normal"
										fullWidth
										inputProps={{ maxLength: 20 }}
										name="role"
										label="Role"
										disabled={user.email ? true : false}
										type="text"
										id="role"
										value={user.role}
										onChange={handleChange('role')}
										required
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={3}>
									<TextField
										size="small"
										variant="outlined"
										margin="normal"
										required
										fullWidth
										disabled={user.email ? true : false}
										inputProps={{ maxLength: 25 }}
										name="email"
										label="Email Id"
										type="text"
										id="email"
										value={user.email}
										onChange={handleChange('email')}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={3}>
									<TextField
										// inputProps={{ maxLength: 10 }}
										inputProps={{
											maxLength: 5,
										}}
										size="small"
										variant="outlined"
										margin="normal"
										fullWidth
										disabled={profileEdit ? false : true}
										name="mobileNumber*"
										helperText={mobileNumberError ? 'Invalid Mobile Number' : ''}
										error={mobileNumberError}
										label="Mobile Number*"
										type="number"
										id="mobileNumber"
										value={user.mobileNumber}
										onChange={handleChange('mobileNumber')}
									/>
								</GridItem>
								{/* <GridItem xs={12} sm={12} md={3}>
									<TextField
										inputProps={{ maxLength: 10 }}
										size="small"
										variant="outlined"
										margin="normal"
										fullWidth
										disabled={profileEdit ? false : true}
										name="telephone"
										label="Telephone"
										type="tel"
										id="telephone"
										value={user.telephone}
										onChange={handleChange('telephone')}
									/>
								</GridItem> */}
							</GridContainer>
							<GridContainer>&nbsp;</GridContainer>

							<GridContainer>&nbsp;</GridContainer>

							<GridContainer>
								<GridItem xs={12} sm={12} md={9}></GridItem>
								<GridItem xs={12} sm={12} md={3}>
									{profileEdit == true ? (
										<Button variant="contained" className={classes.button} fullWidth="true" onClick={handleSubmit}>
											Update Profile
										</Button>
									) : (
										<Button variant="contained" className={classes.button} fullWidth="true" onClick={handleEdit}>
											Edit Profile
										</Button>
									)}
								</GridItem>
							</GridContainer>
						</ThemeProvider>
					</form>
				</GridItem>
				{/*  <Snackbar
          open={successToastOpen}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="success">
            User Updated Successully.
          </Alert>
        </Snackbar> */}
				{/* <Snackbar
          open={failureToastOpen}
          autoHideDuration={2000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="error">
            Something Went Wrong!
          </Alert>
        </Snackbar> */}
				<Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
					{/* <CircularProgress color="inherit" /> */}
					<Lottie options={loaderOptions} height={200} width={600} />
				</Backdrop>
			</GridContainer>
		</div>
	);
}
