import React, { useEffect, useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress,
  Backdrop,
  Typography,
  ThemeProvider,
  createMuiTheme,
  MenuItem,
  TextField,
  Button,
  TableContainer,
  TableHead,
  Divider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import stateDistrictData from "../../allStates-Districts.json";
import {
  getAllCustomer,
  getAllProvider,
} from "../../services/users/userOnboarding";
import { getAllVehicles } from "../../services/vehicle/Vehicles";
import dayjs from "dayjs";
import { getAllRides } from "../../services/ride/Ride";
import { getDescendingOrderedList } from "../Helper/Utils";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as XLSX from "xlsx";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    fontSize: 25,
    textAlign: "center",
  },
  customTableContainer: {
    overflowX: "initial",
  },

  tableCell: {
    Width: 100,
  },
  head: {
    backgroundColor: "#FFA93F",
    position: "sticky",
    top: 0,
    color: "#fff",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

export default function CustomerReport() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [allProvider, setAllProvider] = useState([]);
  const [allVehicle, setAllVehicle] = useState([]);
  const [allVehicleList, setAllVehicleList] = useState([]);
  const [customer, setcustomer] = useState([]);
  const [AllCustomerData, setAllCustomerData] = useState([]);
  const [allRidesData, setAllRidesData] = useState([]);
  const [customerFilteredList, setCustomerFilteredList] = useState([]);

  const [searchApplied, setSearchApplied] = useState(false);
  const [inputValues, setInputValues] = useState({
    state: "",
    city: "",
    operator: "",
    vehicleNumber: "",
    fromDate: "",
    toDate: "",
  });
  const getInitialValues = () => {
    return {
      state: "",
      city: "",
      operator: "",
      vehicleNumber: "",
      fromDate: "",
      toDate: "",
    };
  };
  const { state, city, operator, vehicleNumber, fromDate, toDate } =
    inputValues;

  const handleChange = (name) => (event) => {
    let valueToSet = event.target.value;
    setInputValues({ ...inputValues, [name]: valueToSet });
    if (name === "state") {
      Object.keys(stateDistrictData).forEach((key) => {
        if (stateDistrictData[key].state == valueToSet) {
          setDistricts(stateDistrictData[key].districts);
        }
      });
    }
  };

  useEffect(() => {
    let allData = [];
    Object.keys(stateDistrictData).forEach((key) => {
      allData.push(stateDistrictData[key].state);
    });
    setStateData(allData);

    getAllProvider().then((response) => {
      let providerData = [];
      Object.keys(response).forEach((key) => {
        providerData.push(response[key].businessInfo?.businessName);
      });
      setAllProvider(getDescendingOrderedList(providerData));
    });

    getAllVehicles().then((response) => {
      setAllVehicleList(response);
      let vehicleData = [];
      Object.keys(response).forEach((key) => {
        vehicleData.push(response[key].vehicleNumber);
      });
      setAllVehicle(vehicleData);
    });
    getAllCustomer().then((response) => {
      setcustomer(getDescendingOrderedList(response));
      setCustomerFilteredList(getDescendingOrderedList(response));
      setAllCustomerData(getDescendingOrderedList(response));
    });
    getAllRides().then((response) => {
      setAllRidesData(getDescendingOrderedList(response));
    });
  }, []);
  const getTotalRideForCustomer = (email) => {
    let count = 0;
    let totalRides = allRidesData.filter(
      (ride) => ride.customerEmail === email
    );
    if (fromDate !== "") {
      totalRides = totalRides.filter(
        (ride) => new Date(fromDate).getTime() <= ride.tripDateMil
      );
    }
    let lastHourToDate = new Date(toDate);
    lastHourToDate.setHours(23, 59, 0);
    let lastHourToDateMil = lastHourToDate.getTime();
    if (toDate !== "") {
      totalRides = totalRides.filter(
        (ride) => lastHourToDateMil >= ride.tripDateMil
      );
    }
    if (totalRides && totalRides.length > 0) {
      count = totalRides.length;
    }
    return count;
  };
  const getTotalRideAmountChargedCustomer = (email) => {
    let amount = 0;
    let totalRides = allRidesData.filter(
      (ride) => ride.customerEmail === email
    );
    if (fromDate !== "") {
      totalRides = totalRides.filter(
        (ride) => new Date(fromDate).getTime() <= ride.tripDateMil
      );
    }
    let lastHourToDate = new Date(toDate);
    lastHourToDate.setHours(23, 59, 0);
    let lastHourToDateMil = lastHourToDate.getTime();
    if (toDate !== "") {
      totalRides = totalRides.filter(
        (ride) => lastHourToDateMil >= ride.tripDateMil
      );
    }
    if (totalRides && totalRides.length > 0) {
      totalRides.forEach((ride) => {
        amount = parseInt(ride?.amount) + amount;
      });
    }
    return amount;
  };
  const { enqueueSnackbar } = useSnackbar();
  const showToast = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant ? variant : "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };
  const handleSearchClick = () => {
    if (fromDate !== "" && toDate !== "") {
      let fromDateMil = new Date(fromDate).getTime();
      let toDateMil = new Date(toDate).getTime();
      if (fromDateMil > toDateMil) {
        showToast("Invalid Date Selected", "error");
        return;
      }

      const filterdValue = customerFilteredList?.filter(
        (ele) => getTotalRideAmountChargedCustomer(ele.email) > 0
      );
      setcustomer([...filterdValue]);
    }
    setSearchApplied(true);
  };
  const handleClearClick = () => {
    setInputValues(getInitialValues());
    setcustomer([...customerFilteredList]);
    setSearchApplied(false);
  };
  const closeLoader = () => {
    setOpen(false);
  };
  const toggleLoader = () => {
    setOpen(!open);
  };

  const exportToExcel = () => {
    let excelElements = [];
    customer.map((excel, index) => {
      let excelElement = {
        "Sr.No": index + 1,
        "Customer Name": excel?.name,
        "Customer Email Id": excel?.email,
        "Contact Number": excel?.mobile,
        "Total Number of Rides": getTotalRideForCustomer(excel.email),
        "Total amount Charged": getTotalRideAmountChargedCustomer(excel.email),
      };
      excelElements.push(excelElement);
      return excel;
    });
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(excelElements);
    XLSX.utils.book_append_sheet(wb, ws1, "Customer  Desk Status");
    XLSX.writeFile(wb, `Customer Desk report.xlsx`);
  };

  return (
    <Container>
      <Container maxWidth={false}>
        <GridItem xs={12} sm={12} md={12}>
          <Typography className={classes.title}> Customer Report </Typography>
          <ThemeProvider theme={theme}>
            <GridContainer>
              {/* <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    size='small'
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="state"
                                    label="State"
                                    type="text"
                                    id="state"
                                    select
                                    value={state}
                                    onChange={handleChange('state')}
                                >
                                    {stateData.map((value,index)=>(
                                         <MenuItem value={value} key={index}>{value}</MenuItem>   
                                    ))}
                                </TextField>
                            </GridItem> */}
              {/* <GridItem xs={12} sm={12} md={3}>
                                <TextField
                                    size='small'
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="city"
                                    label="City"
                                    type="text"
                                    id="city"
                                    select
                                    value={city}
                                    onChange={handleChange('city')}
                                >
                                    {districts.map((value,index)=>(
                                         <MenuItem value={value} key={index}>{value}</MenuItem>   
                                    ))}
                                </TextField>
                            </GridItem> */}

              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="fromDate"
                  label="From Date"
                  type="date"
                  id="fromDate"
                  value={fromDate}
                  onChange={handleChange("fromDate")}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="toDate"
                  label="To Date"
                  type="date"
                  id="toDate"
                  value={toDate}
                  onChange={handleChange("toDate")}
                />
              </GridItem>
              {/* <GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="operator"
									label="Operator"
									type="text"
									id="operator"
									select
									value={operator}
									onChange={handleChange("operator")}
								>
									{allProvider.map((value, index) => (
										<MenuItem value={value} key={index}>
											{value}
										</MenuItem>
									))}
								</TextField>
							</GridItem> */}
              {/* <GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="vehicleNumber"
									label="Vehicle Number"
									type="text"
									id="vehicleNumber"
									select
									value={vehicleNumber}
									onChange={handleChange("vehicleNumber")}
								>
									{allVehicle.map((value, index) => (
										<MenuItem value={value} key={index}>
											{value}
										</MenuItem>
									))}
								</TextField>
							</GridItem> */}
              <GridItem xs={12} sm={12} md={2}>
                <Button
                  style={{ marginTop: 20 }}
                  variant="contained"
                  className={classes.button}
                  fullWidth="true"
                  onClick={() => handleSearchClick()}
                >
                  Search
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <Button
                  style={{ marginTop: 20 }}
                  variant="contained"
                  className={classes.button}
                  fullWidth="true"
                  onClick={handleClearClick}
                >
                  Clear
                </Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <Button
                  style={{ marginTop: 20 }}
                  variant="contained"
                  className={classes.button}
                  fullWidth="true"
                  onClick={(e) => {
                    exportToExcel("Excel Export");
                  }}
                  startIcon={<FileDownloadOutlinedIcon fontSize="small" />}
                >
                  Export
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>
            <Divider></Divider>
            <GridContainer>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleColor}
                      >
                        Sr No.
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleColor}
                      >
                        Customer Name
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleColor}
                      >
                        Customer Email ID
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleColor}
                      >
                        Contact Number
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleColor}
                      >
                        Total Number of Rides
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.titleColor}
                      >
                        Total amount Charged
                      </Typography>
                    </TableCell>
                    {/* <TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Customer Category
											</Typography>
										</TableCell> */}
                  </TableRow>

                  {customer.map((customer, index) => (
                    <TableRow className={classes.tableRow} key={index}>
                      <TableCell>
                        <Typography variant="body1" gutterBottom>
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" gutterBottom>
                          {customer.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" gutterBottom>
                          {customer.email}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" gutterBottom>
                          {customer.mobile}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" gutterBottom align="center">
                          {getTotalRideForCustomer(customer.email)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1" gutterBottom>
                          {getTotalRideAmountChargedCustomer(customer.email)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* </TableContainer> */}
            </GridContainer>
          </ThemeProvider>
        </GridItem>
      </Container>
      <Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
