import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {
  Button,
  Snackbar,
  InputAdornment,
  Backdrop,
  CircularProgress,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {
  Login,
  resetPassword,
  resetPasswordExternal,
  signup,
} from "../../services/authentication/authentication";
import { browserHistory } from "react-router";
//Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  getAdminOrExecutiveByUserName,
  getAdminUserByUsername,
} from "../../services/users/userOnboarding";
import { orange } from "@material-ui/core/colors";
import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import logo from "../../assets/images/Logo1.png";
import { Navigation } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { fontSize } from "@mui/system";
import {
  getAllVehicles,
  getVehicleCategoryMetadataNew,
} from "../../services/vehicle/Vehicles";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
require("firebase/auth");
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {/* {'Copyright V 1.2 © '} */}
      {/* <Link color="inherit"> */}
      {/* <p>Copyright V 1.2 © NowWagon  <a href='#' onClick={()=>Navigation='/auth/Termsconditions()'}>Terms {'&'} Conditions</a> {new Date().getFullYear()}.</p> */}
      <p>
        Copyright V1.5 © NowWagon{" "}
        {/* <a href="#" onClick={() => (Navigation = "/auth/Termsconditions()")}>
          Terms {"&"} Conditions
        </a>{" "} */}
        {new Date().getFullYear()}.
      </p>
      {/* </Link>{' '} */}
    </Typography>
  );
}
const Termsconditions = () => {
  // 	//alert("Hi");
  // 	// window.open('/auth/Termsconditions');
  // 	// navigate('/auth/Termsconditions');
  browserHistory.push("/auth/Termsconditions");
};

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "-25px",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "#FF8000",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
  img: {
    width: "50%",
    height: "50%",
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function LoginView() {
  const classes = useStyles();
  // let history = useHistory();
  let navigate = useNavigate();
  const [user, setUser] = useState();
  const [emailForReset, setEmailForReset] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [open, setOpen] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [failureToastOpen, setFailureToastOpen] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);

  // Noti snackbar handling...
  const { enqueueSnackbar } = useSnackbar();
  const showToast = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant ? variant : "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const closeLoader = () => {
    setOpenLoader(false);
  };
  const toggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const handleClose = (event) => {
    setSuccessToastOpen(false);
    setFailureToastOpen(false);
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleClickDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
    refreshPage();
  };

  // temp impl for login.
  useEffect(async () => {
    let user = JSON.parse(await localStorage.getItem("USER_PROFILE"));
    if (
      user &&
      user != null &&
      (user.role == "ADMIN" ||
        user.role == "EXECUTIVE" ||
        user.role == "SUPER_ADMIN")
    ) {
      let dbUser = await getAdminOrExecutiveByUserName(user.email)
        .then((user) => {
          if (!user.isActive) {
            showToast(
              "User may be deactivated, Please check with admin",
              "warning"
            );
          }
        })
        .catch((error) => {
          //console.log(error);
        });
      //console.log('role', user.role);
    } else if (user && user != null) {
      showToast(
        "You can not access web portal, please use app login",
        "warning"
      );
    }
    getVehicleCategoryMetadataNew().then((vehicle) => {
      console.log(vehicle);
      // setVehicle(vehicle);
      // setVehicleAllList(vehicle);
      // getVehicle(vehicle);
    });
  }, []);

  const doLogin = async (e) => {
    if (email == undefined || email.trim() == "") {
      showToast("Please enter email", "error");
      return;
    } else if (password == undefined || password.trim() == "") {
      showToast("Please enter password", "error");
      return;
    }
    toggleLoader();
    Login(email, password)
      .then(
        (login) => {
          if (login.user) {
            localStorage.setItem("USERNAME", email);
            getAdminUserByUsername(email).then((user) => {
              if (user && user != null && user != undefined) {
                localStorage.setItem("USER_PROFILE", JSON.stringify(user));
                if (
                  (user.role == "ADMIN" && user.isActive) ||
                  (user.role == "EXECUTIVE" && user.isActive) ||
                  user.role == "SUPER_ADMIN" && user.isActive
                ) {
                  setSuccessToastOpen(true);
                  // showToast("Logged in Successfully!", "success");
                  showToast(
                    `Welcome to NowWagon ${user.firstName} ${user.lastName}!`,
                    "success"
                  );
                  navigate("/app/dashboard");
                } else if (
                  (user.role == "ADMIN" && !user.isActive) ||
                  (user.role == "EXECUTIVE" && !user.isActive) ||
                  user.role == "SUPER_ADMIN" && !user.isActive 
                ) {
                  showToast(
                    "User may be deactivated, Please check with admin",
                    "warning"
                  );
                } else {
                  // setFailureToastOpen(true);
                  showToast(
                    "You can not access web portal, please use app login",
                    "warning"
                  );
                }
                localStorage.setItem("USER_PASSWORD", password);
              } else {
                showToast(
                  "Error logging in, please contact system admin",
                  "error"
                );
              }
            });
          } else if (login.code) {
            // alert(login.message);
            // setFailureToastOpen(true);
            showToast(
              "Email id or Password is incorrect! Please check again",
              "error"
            );
            //console.log(login.message);
          }
        },
        (error) => {
          // alert(error.message);
          // //console.log(error.message)
          // setFailureToastOpen(true);
          showToast(
            "Email id or Password is incorrect! Please check again",
            "error"
          );
        }
      )
      .catch((error) => {
        showToast("Error while logging in!", "error");
      })
      .finally(() => {
        closeLoader();
      });
  };
  const doSignup = () => {
    signup(email, password);
  };

  const handleDialogSubmit = (e) => {
    e.preventDefault();
    if (emailForReset == undefined || emailForReset.trim() == "") {
      showToast("Please enter an email address", "warning");
      return false;
    }
    setOpenLoader(true);
    setOpen(false);
    resetPasswordExternal(emailForReset)
      .then((resp) => {
        // //console.log("Succcess Email Sent");
        showToast(
          `Password reset email sent successfully at ${emailForReset}`,
          "success"
        );
        // alert("Email Send for Reset Password");
        setOpen(false);
        setEmailForReset("");
      })
      .catch((error) => {
        //console.log('error', error);
        if (error.code == "auth/invalid-email") {
          showToast(
            `${emailForReset} - This email address is not correct!`,
            "error"
          );
        } else if (error.code == "auth/user-not-found") {
          showToast(
            `${emailForReset} - This user is not in the system!`,
            "error"
          );
        } else {
          showToast("Error sending password reset email", "error");
        }
      })
      .finally(() => {
        closeLoader();
        setEmailForReset("");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <img src={logo} alt="logo" className={classes.img} />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <ThemeProvider theme={theme}>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => {
                setEmail(e.target.value.trim());
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              value={password}
              type={showPassword ? "text " : "Password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e?.key === "Enter") {
                  doLogin();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              //  type="submit"
              fullWidth
              variant="contained"
              // color="primary"
              className={classes.submit}
              onClick={doLogin}
            >
              Sign In
            </Button>

            <Grid container>
              <Grid item xs>
                <Button onClick={handleClickDialogOpen}>
                  Forgot Password?
                </Button>
              </Grid>
              <Dialog
                // maxWidth='lg'
                fullWidth="sm"
                open={open}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Forgot Password"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please enter registered email address
                  </DialogContentText>
                  <TextField
                    size="small"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="emailForReset"
                    label="Email Address"
                    name="emailForReset"
                    autoComplete="emailForReset"
                    autoFocus
                    value={emailForReset}
                    onChange={(e) => {
                      setEmailForReset(e.target.value);
                    }}
                  />
                  <DialogContentText
                    id="alert-dialog-description"
                    style={{ fontSize: 13, marginTop: 5 }}
                  >
                    *If you do not see Reset Password Email in Inbox, please
                    check Spam folder as well.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleDialogClose}
                    color="primary"
                    className={classes.submit}
                  >
                    Close
                  </Button>
                  <Button
                    onClick={handleDialogSubmit}
                    color="primary"
                    className={classes.submit}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </form>
        </ThemeProvider>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>

      {/* <Snackbar
        open={successToastOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity="success">
          Login Success.
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureToastOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error">
          Email id or Password is incorrect !
        </Alert>
      </Snackbar> */}
      <Backdrop
        className={classes.backdrop}
        open={openLoader}
        onClick={() => closeLoader}
      >
        {/* <CircularProgress color="inherit" /> */}
        <Lottie options={loaderOptions} height={100} width={300} />
      </Backdrop>
    </Container>
  );
}
