import React, { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  Typography,
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import NotificationsIcon from "@material-ui/icons/Notifications";

import NavItem from "./NavItem";
import {
  isAdmin,
  isProvider,
  superAdmin,
} from "../../../screens/user/AddUserView";
import { orange } from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import {
  Accessible,
  Assignment,
  AttachMoney,
  Commute,
  ConfirmationNumber,
  Person,
  EmojiTransportation,
  GroupAdd,
  Help,
  LocalShipping,
  Money,
  MoneyOff,
  MoneyTwoTone,
  DriveEta,
  Info,
  Build,
} from "@material-ui/icons";
import { ManageAccounts } from "@mui/icons-material";
// import AlarmIcon from '@mui/icons-material/Alarm';
const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 286,
  },
  desktopDrawer: {
    width: 286,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  name: {
    margin: 10,
  },
  nested: {
    paddingLeft: theme.spacing(1),
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openVehicle, setOpenVehicle] = React.useState(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [openOperator, setOpenOperator] = React.useState(false);
  const [openHelpDesk, setOpenHelpDesk] = React.useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={3}>
        {isAdmin() ? (
          <List>
            <NavItem
              href="/app/dashboard"
              title="Dashboard"
              icon={DashboardIcon}
            />
            <NavItem
              href="/app/executiveManagement"
              title="Executive Management"
              icon={GroupAdd}
            />
            <ListItem button onClick={() => setOpenReport(!openReport)}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText secondary="Reports" />
              {openReport ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openReport} timeout="auto" unmountOnExit>
              <List component="div">
                <NavItem
                  className={classes.nested}
                  href="/app/vehicleStatusReport"
                  title="Vehicle Status"
                  icon={LocalShipping}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/driverPerformanceReport"
                  title="Driver Performance"
                  icon={Person}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/operatorReport"
                  title="Operator Report"
                  icon={Person}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/customerReport"
                  title="Customer Report"
                  icon={Person}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/dailyTotalEarningReport"
                  title="Daily Total Earning"
                  icon={AttachMoney}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/tripHistoryReport"
                  title="Ride History Report"
                  icon={EmojiTransportation}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/helpDeskReport"
                  title="Help Desk Report"
                  icon={Help}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/insuranceReport"
                  title="Insurance Report"
                  icon={Assignment}
                />
              </List>
            </Collapse>
          </List>
        ) : superAdmin() ? (
          <List>
            <NavItem
              href="/app/dashboard"
              title="Dashboard"
              icon={DashboardIcon}
            />
            <NavItem
              href="/app/adminManagement"
              title="Admin Management"
              icon={Person}
            />
            <NavItem
              href="/app/fareManagement"
              title="Fare Management"
              icon={Money}
            />
            {/* <NavItem href="/app/executiveManagement" title="Executive Management" icon={GroupAdd} /> */}
            <ListItem button onClick={() => setOpenReport(!openReport)}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText secondary="Reports" />
              {openReport ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openReport} timeout="auto" unmountOnExit>
              <List component="div">
                <NavItem
                  className={classes.nested}
                  href="/app/vehicleStatusReport"
                  title="Vehicle Status"
                  icon={LocalShipping}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/driverPerformanceReport"
                  title="Driver Performance"
                  icon={Person}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/operatorReport"
                  title="Operator Report"
                  icon={Person}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/customerReport"
                  title="Customer Report"
                  icon={Person}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/dailyTotalEarningReport"
                  title="Daily Total Earning"
                  icon={AttachMoney}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/tripHistoryReport"
                  title="Ride History Report"
                  icon={EmojiTransportation}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/helpDeskReport"
                  title="Help Desk Report"
                  icon={Help}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/insuranceReport"
                  title="Insurance Report"
                  icon={Assignment}
                />
              </List>
            </Collapse>
          </List>
        ) : (
          <List>
            <NavItem
              style={{ paddingVertical: 20 }}
              href="/app/dashboard"
              title="DashBoard"
              icon={DashboardIcon}
            />

            <ListItem
              button
              onClick={() => setOpenOperator(!openOperator)}
              icon={DashboardIcon}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText secondary="Operator" />
              {openOperator ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openOperator} timeout="auto" unmountOnExit>
              <List component="div">
                <NavItem
                  href="/app/userManagement/newUserMode"
                  title="Operator Management"
                  icon={GroupAdd}
                />
                <NavItem
                  href="/app/driversList"
                  title="Driver Management"
                  icon={ManageAccounts}
                />
              </List>
            </Collapse>
            <ListItem button onClick={() => setOpenVehicle(!openVehicle)}>
              <ListItemIcon>
                <LocalShipping />
              </ListItemIcon>
              <ListItemText secondary="Vehicle" />
              {openVehicle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openVehicle} timeout="auto" unmountOnExit>
              <List component="div">
                <NavItem
                  href="/app/vehicleList"
                  title="Vehicle Management"
                  icon={Commute}
                />
              </List>
            </Collapse>

            <NavItem
              href="/app/ticketingSystem"
              title="Ticketing System"
              icon={Build}
            />
            <ListItem button onClick={() => setOpenReport(!openReport)}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText secondary="Reports" />
              {openReport ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openReport} timeout="auto" unmountOnExit>
              <List component="div">
                <NavItem
                  className={classes.nested}
                  href="/app/vehicleStatusReport"
                  title="Vehicle Status"
                  icon={LocalShipping}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/driverPerformanceReport"
                  title="Driver Performance"
                  icon={Person}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/operatorReport"
                  title="Operator Report"
                  icon={Person}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/customerReport"
                  title="Customer Report"
                  icon={Person}
                />
                {/* <NavItem
									className={classes.nested}
									href='/app/dailyTotalEarningReport'
									title='Daily Total Earning'
									icon={AttachMoney}
								/> */}
                <NavItem
                  className={classes.nested}
                  href="/app/tripHistoryReport"
                  title="Ride History Report"
                  icon={EmojiTransportation}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/helpDeskReport"
                  title="Help Desk Report"
                  icon={Help}
                />
                <NavItem
                  className={classes.nested}
                  href="/app/insuranceReport"
                  title="Insurance Report"
                  icon={Assignment}
                />
              </List>
            </Collapse>

            <ListItem button onClick={() => setOpenHelpDesk(!openHelpDesk)}>
              <ListItemIcon>
                <Assignment />
              </ListItemIcon>
              <ListItemText secondary="About NowWagon" />
              {openHelpDesk ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openHelpDesk} timeout="auto" unmountOnExit>
              <List component="div">
                <NavItem
                  href="/app/FAQ"
                  title="FAQ"
                  icon={ConfirmationNumber}
                />
                <NavItem href="/app/aboutUs" title="About Us" icon={Info} />
                <NavItem
                  href="/app/privacyPolicy"
                  title="Privacy Policy"
                  icon={ConfirmationNumber}
                />
                <NavItem
                  href="/app/TermsAndConditions"
                  title="Terms And Conditions"
                  icon={ConfirmationNumber}
                />
              </List>
            </Collapse>

            <NavItem
              href="/app/notification"
              title="Notifications"
              icon={NotificationsIcon}
            />
          </List>
        )}
      </Box>
      <Box flexGrow={2} />
    </Box>
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <Hidden lgUp>
          <Drawer
            anchor="left"
            classes={{ paper: classes.mobileDrawer }}
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            {content}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </Hidden>
      </ThemeProvider>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
