import FbApp from '../../config/FbApp'

export default function Metadata() {

}
export const  postData = [];
export const getAllMetadata = async() => {
    const db = FbApp.firestore();
    
    postData.length = 0
   await  db.collection('MD_VEHICAL_CATEGORY').onSnapshot((snapshots) => {
        snapshots.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
        postData.forEach(data => {
            data.subCategory = [];
            db.collection('MD_VEHICAL_CATEGORY').doc(data.id).collection("MD_VEHICAL_TYPE")
            .onSnapshot((snapshot) =>{
                console.log("snapshot",snapshot);
                snapshot.forEach((doc) => data.subCategory.push({ ...doc.data(), id: doc.id }));
            })
        })
    })
    return postData
}

export const getAllVehiclesMasterMetadata = async () => {
	const db = FbApp.firestore();
	const vehicleRef = db.collection("VEHICLE_MASTER_DATA");
	const snapshot = await vehicleRef.get();
	let vehicleMasterData = {};
	snapshot.forEach((doc) => {
		vehicleMasterData.data = doc.data();
        vehicleMasterData.id = doc.id;
		console.log("vehicleMasterData",vehicleMasterData.id);
	});
    console.log("vehicleMasterData",vehicleMasterData);
	return vehicleMasterData;
};

export const addAndUpdateAllVehiclesMasterData = (allVehiclesMasterData) => {
	console.log("allVehiclesMasterData",allVehiclesMasterData);
	const db = FbApp.firestore();
	return db
		.collection("VEHICLE_MASTER_DATA")
		.doc(allVehiclesMasterData.id)
		.update(allVehiclesMasterData.data)
		.then((response) => {
			if (response) {
				return response;
			}
		})
		.catch((error) => {
			throw error;
		});
};
