import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

import {
	Backdrop,
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TableContainer,
	TableHead,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { activateDeactiveUser } from "../../services/users/userOnboarding";
import Switch from "@material-ui/core/Switch";
import { getAllDrivers } from "../../services/drivers/Drivers";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import SearchField from "react-search-field";
import { useSnackbar } from "notistack";
import { getDescendingOrderedList } from "../Helper/Utils";
import { updateVehicleStatus } from "../../services/vehicle/Vehicles";
const useStyles = makeStyles((styles) => ({
	backdrop: {
		zIndex: styles.zIndex.drawer + 1,
		color: "#fff",
	},

	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
}));

const PurpleSwitch = withStyles({
	switchBase: {
		"&$checked": {
			color: "#FF8000",
		},
		"&$checked + $track": {
			backgroundColor: "#FF8000",
		},
	},
	checked: {},
	track: {},
})(Switch);

const loaderOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};
export default function DriverListView() {
	const classes = useStyles();
	const [driversList, setDriversList] = useState([]);
	const [selectedDriver, setSelectedDriver] = useState();
	const [filteredDriverList, setfilterdDriversList] = useState([]);
	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const closeLoader = () => {
		setOpenLoader(false);
	};
	const toggleLoader = () => {
		setOpenLoader(!openLoader);
	};

	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};

	/* const onDeactivate = (driver) => {
    setSelectedDriver(driver);
    //console.log("selectedDriver", driver);
    driver.isActive = false;
    activateDeactiveUser(driver).then((response) => {
      //console.log(response, "driver deactivated");
      setSelectedDriver("");
    });
  };

  const onActivate = (driver) => {
    setSelectedDriver(driver);
    //console.log("selectedDriver", driver);
    driver.isActive = true;
    activateDeactiveUser(driver).then((response) => {
      //console.log(response, "driver Activated");
      setSelectedDriver("");
    });
  }; */

	const onDeactivate = (driver) => {
		setSelectedDriver(driver);
		//console.log('selectedDriver', driver);
		driver.isActive = false;
		activateDeactiveUser(driver).then((response) => {
			// setOpenDeactive(false);
			if (driver.role && driver.vehicle && driver.role === "PROVIDER-DRIVER") {
				updateVehicleStatus(driver.vehicle, false);
				console.log("Vehicle No", driver.vehicle, "Role", driver.role);
			}
			setSelectedDriver("");
			handleCloseConfirmationDialog();
			//	setCurrentUserStatus(false);
			showToast(`User is Deactivated`, "error");
		});
	};

	const onActivate = (user) => {
		setSelectedDriver(user);
		//console.log('selectedDriver', user);
		user.isActive = true;
		activateDeactiveUser(user).then((response) => {
			// setOpenActive(false);
			if (user.role && user.vehicle && user.role === "PROVIDER-DRIVER") {
				updateVehicleStatus(user.vehicle, true);
				console.log("Vehicle No", user.vehicle, "Role", user.role);
			}
			setSelectedDriver("");
			handleCloseConfirmationDialog();
			//setCurrentUserStatus(true);
			showToast(`User is Activated`, "success");
		});
	};

	const handleOpenConfirmationDialog = () => {
		setOpenConfirmationDialog(true);
	};

	const handleCloseConfirmationDialog = () => {
		setOpenConfirmationDialog(false);
	};
	const handleToggleDriver = (user) => {
		handleOpenConfirmationDialog();
		setSelectedDriver(user);
	};

	useEffect(() => {
		toggleLoader();
		getAllDrivers().then((drivers) => {
			closeLoader();
			setDriversList(drivers);
			// //console.log(drivers,"driver")
			//setUserList(drivers);
			setfilterdDriversList(getDescendingOrderedList(drivers));
		});
	}, []);

	const onChangeSerchText = (e) => {
		if (e && e.trim() !== " ") {
			let filterDriver = driversList.filter((driver) => {
				return (
					driver?.firstName?.toLowerCase().indexOf(e.toLowerCase()) > -1 ||
					driver?.lastName?.toLowerCase().indexOf(e.toLowerCase()) > -1
				);
			});
			//console.log("Text", filterDriver);
			setfilterdDriversList(getDescendingOrderedList(filterDriver));
		} else {
			setfilterdDriversList(getDescendingOrderedList(driversList));
		}
	};
	return (
		<>
			<div>&nbsp;</div>

			<div>
				<SearchField
					placeholder="Search Driver Name..."
					onChange={onChangeSerchText}
					searchText=""
					classNames="test-class"
				/>
			</div>
			<div>&nbsp;</div>
			<TableContainer component={Paper} className={classes.customTableContainer}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Sr.No
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									{" "}
									Driver Name
								</Typography>
							</TableCell>

							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Vehicle No
								</Typography>
							</TableCell>

							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Mobile No
								</Typography>
							</TableCell>
							{/* <TableCell  className={classes.head}>
              <Typography variant="h4" gutterBottom className={classes.titleColor}>E-Mail Id</Typography>
            </TableCell> */}
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Status
								</Typography>
							</TableCell>
							<TableCell className={classes.head}>
								<Typography variant="h4" gutterBottom className={classes.titleColor}>
									Actions
								</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredDriverList.map((driver, index) => (
							<TableRow key={index} className={classes.tableRow}>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{index + 1}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{driver.firstName} {} {driver.lastName}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{driver.vehicle}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography variant="body1" gutterBottom>
										{driver.mobileNumber}
									</Typography>
								</TableCell>
								<TableCell>
									{driver.isActive == true ? (
										<Chip
											label="&nbsp;Active&nbsp;"
											color="green"
											variant="outlined"
											style={{ borderColor: "green", color: "black", fontWeight: "bold" }}
										/>
									) : (
										<Chip
											label="Inactive"
											variant="outlined"
											style={{ backgroundColor: "red", color: "white", fontWeight: "bold" }}
										/>
									)}
								</TableCell>
								{/* <TableCell >
                <Typography variant="body1" gutterBottom>{driver.email}</Typography>
              </TableCell> */}
								{/* <TableCell>
                  <Typography variant="body1" gutterBottom>
                    {driver.isActive === true ? "Active " : "Deactive"}
                  </Typography>
                </TableCell> */}

								{driver.isActive ? (
									<TableCell>
										<Tooltip arrow title="Deactivate">
											<PurpleSwitch
												checked={true}
												onClick={() => {
													// onDeactivate(driver);
													handleToggleDriver(driver);
												}}
											/>
										</Tooltip>
									</TableCell>
								) : (
									<TableCell>
										<Tooltip arrow title="Activate">
											<PurpleSwitch
												onClick={() => {
													// onActivate(driver);
													handleToggleDriver(driver);
												}}
												checked={false}
											/>
										</Tooltip>
									</TableCell>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Dialog
				//component={Paper}
				//fullWidth="true"
				maxWidth="xs"
				open={openConfirmationDialog}
				//TransitionComponent={Transition}
				onClose={handleCloseConfirmationDialog}
			>
				<DialogTitle id="alert-dialog-title">{"NowWagon"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{`Do you want to ${
						selectedDriver !== "" && selectedDriver?.isActive ? "Deactivate" : "Activate"
					} Driver ${selectedDriver?.firstName}?`}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						className={classes.submit}
						onClick={
							selectedDriver !== ""
								? selectedDriver?.isActive
									? () => onDeactivate(selectedDriver)
									: () => onActivate(selectedDriver)
								: null
						}
					>
						YES
					</Button>
					<Button color="primary" className={classes.submit} onClick={() => handleCloseConfirmationDialog()} autoFocus>
						NO
					</Button>
				</DialogActions>
			</Dialog>
			<Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
				{/* <Lottie 
	        options={loaderOptions}
            height={200}
            width={600}
        /> */}
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
