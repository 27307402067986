import React, { useState, useEffect } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
	Container,
	Grid,
	makeStyles,
	CircularProgress,
	Backdrop,
	Typography,
	ThemeProvider,
	createMuiTheme,
	MenuItem,
	TextField,
	Button,
	TableContainer,
	TableHead,
	Divider,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";
import GridContainer from "../../components/Grid/GridContainer";
import stateDistrictData from "../../allStates-Districts.json";
import { getAllProvider } from "../../services/users/userOnboarding";
import { getAllVehicles } from "../../services/vehicle/Vehicles";
import { getAllDrivers } from "../../services/drivers/Drivers";
import { getAllRides } from "../../services/ride/Ride";
import { getDescendingOrderedList } from "../Helper/Utils";
import { useSnackbar } from "notistack";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.dark,
		minHeight: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
	title: {
		fontSize: 25,
		textAlign: "center",
	},
	customTableContainer: {
		overflowX: "initial",
	},

	tableCell: {
		Width: 100,
	},
	head: {
		backgroundColor: "#FFA93F",
		position: "sticky",
		top: 0,
		color: "#fff",
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
}));
const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

export default function DriverPerformanceReport() {
	const classes = useStyles();
	let navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [inputValues, setInputValues] = useState({
		state: "Maharashtra",
		city: "Pune",
		operator: "",
		Driver: "",
		fromDate: "",
		toDate: "",
	});
	const { state, city, operator, Driver, fromDate, toDate } = inputValues;
	const [provider, setProvider] = useState([]);
	const [allProvider, setAllProvider] = useState([]);
	const [allDriversList, setAllDriversList] = useState([]);
	const [mainDriversList, setMainDriversList] = useState([]);
	const [allDrivers, setAllDrivers] = useState([]);
	const [allRidesData, setAllRidesData] = useState([]);
	const getInitalValues = () => {
		return {
			state: "Maharashtra",
			city: "Pune",
			operator: "",
			Driver: "",
			fromDate: "",
			toDate: "",
		};
	};

	const handleChange = (name) => (event) => {
		let valueToSet = event.target.value;
		setInputValues({ ...inputValues, error: false, [name]: valueToSet });

		if (name === "operator") {
			let operatorEmail = provider.filter((element) => element.businessInfo.businessName == valueToSet);
			let driver = mainDriversList.filter((element) => element.ownerId == operatorEmail[0].businessInfo.officeEmail);
			let driverData = [];
			Object.keys(driver).forEach((key) => {
				driverData.push(driver[key].firstName);
			});
			setAllDrivers(driverData);
		}
	};
	const { enqueueSnackbar } = useSnackbar();
	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};
	const handleSearch = () => {
		if (fromDate !== "" && toDate !== "") {
			let lastHourToDate = new Date(toDate);
			lastHourToDate.setHours(23, 59, 0);
			let lastHourToDateMil = lastHourToDate.getTime();
			let fromDateMil = new Date(fromDate).getTime();
			let toDateMil = new Date(toDate).getTime();
			if (fromDateMil > lastHourToDateMil) {
				showToast("Invalid Date Selected", "error");
				return;
			}
		}
		let filteredList = mainDriversList;
		if (state !== "") {
			filteredList = filteredList.filter((element) => element.state == state);
		}
		if (city !== "") {
			filteredList = filteredList.filter((element) => element.city == city);
		}
		if (operator !== "") {
			let filterProvider = provider.filter((element) => element.businessInfo.businessName == operator);

			filteredList = filteredList.filter((element) => element.ownerId == filterProvider[0].businessInfo.officeEmail);
		}
		if (Driver !== "") {
			filteredList = filteredList.filter((element) => element.firstName == Driver);
		}
		setAllDriversList(filteredList);
		// if (state !== "") {
		// 	let stateWiseDriver = mainDriversList.filter((element) => element.state == state);
		// 	setAllDriversList(stateWiseDriver);

		// 	if (city !== "") {
		// 		let cityWiseDriver = stateWiseDriver.filter((element) => element.city == city);
		// 		setAllDriversList(cityWiseDriver);

		// 		if (operator !== "") {
		// 			let filterProvider = provider.filter((element) => element.businessInfo.businessName == operator);

		// 			let providerWiseDriver = cityWiseDriver.filter(
		// 				(element) => element.ownerId == filterProvider[0].businessInfo.officeEmail
		// 			);
		// 			setAllDriversList(providerWiseDriver);
		// 			// //console.log("mainVehicleList", providerWiseDriver)
		// 			if (Driver !== "") {
		// 				let vehicleNumberWiseDriver = providerWiseDriver.filter((element) => element.firstName == Driver);
		// 				setAllDriversList(vehicleNumberWiseDriver);
		// 				// //console.log("mainVehicleList", vehicleNumberWiseDriver)
		// 			}
		// 		}
		// 	}
		// }
	};

	useEffect(() => {
		let driver = [];
		getAllProvider().then((response) => {
			setProvider(response);
			let providerData = [];
			Object.keys(response).forEach((key) => {
				providerData.push(response[key].businessInfo?.businessName);
			});
			setAllProvider(getDescendingOrderedList(providerData));
		});

		getAllDrivers()
			.then((response) => {
				setAllDriversList(getDescendingOrderedList(response));
				setMainDriversList(getDescendingOrderedList(response));
			})
			.catch((error) => {
				console.log(error);
			});

		getAllRides().then((response) => {
			setAllRidesData(getDescendingOrderedList(response));
		});
		filterDriverRideData(driver);
	}, []);

	const filterDriverRideData = (driver) => {
		const DriverReport = [];
		Object.keys(driver).forEach((key) => {
			if (driver[key].firstName == "Swapnil") {
				//console.log("Done");
			}
			// driverData.push(driver[key].firstName)
		});
	};
	const getTotalRideCount = (email) => {
		let count = 0;
		let totalRides = allRidesData.filter((ride) => ride.driverEmail === email);
		if (fromDate !== "") {
			totalRides = totalRides.filter((ride) => new Date(fromDate).getTime() <= ride.createdOnMil);
		}
		let lastHourToDate = new Date(toDate);
		lastHourToDate.setHours(23, 59, 0);
		let lastHourToDateMil = lastHourToDate.getTime();
		if (toDate !== "") {
			totalRides = totalRides.filter((ride) => lastHourToDateMil >= ride.createdOnMil);
		}
		if (totalRides && totalRides.length > 0) {
			count = totalRides.length;
		}
		return count;
	};
	const getTotalRatings = (email) => {
		let count = 0;
		let totalRides = allRidesData.filter((ride) => ride.driverEmail === email);
		totalRides = totalRides.filter((ride) => ride.customerRating !== undefined);
		if (fromDate !== "") {
			totalRides = totalRides.filter((ride) => new Date(fromDate).getTime() <= ride.createdOnMil);
		}
		let lastHourToDate = new Date(toDate);
		lastHourToDate.setHours(23, 59, 0);
		let lastHourToDateMil = lastHourToDate.getTime();
		if (toDate !== "") {
			totalRides = totalRides.filter((ride) => lastHourToDateMil >= ride.createdOnMil);
		}
		if (totalRides && totalRides.length > 0) {
			let totalRatings = 0;
			totalRides.forEach((ride) => {
				totalRatings = parseInt(ride.customerRating) + totalRatings;
			});
			count = totalRatings / totalRides.length;
		}
		if (count !== 0) {
			return count;
		} else {
			return "N/A";
		}
	};
	const closeLoader = () => {
		setOpen(false);
	};
	const toggleLoader = () => {
		setOpen(!open);
	};

	const exportToExcel = () => {
		let excelElements = [];
		allDriversList.map((excel,index)=>{
			let excelElement = {
				"Sr.No": index + 1,
				"Driver Name":`${excel?.firstName + '' + excel?.lastName} `,
				"Driver Email-Id":excel?.email,
				"Contact Number":excel?.mobileNumber,
				"Operator Email-Id": excel?.ownerId,
				"Ride Completed": getTotalRideCount(excel.email),
				"Customer Rating": getTotalRatings(excel.email)
			  };
			  excelElements.push(excelElement);
			  return excel;
		});
		 const wb = XLSX.utils.book_new();
		 const ws1 = XLSX.utils.json_to_sheet(excelElements);
		 XLSX.utils.book_append_sheet(wb, ws1, 'Driver Performance Status');
		 XLSX.writeFile(wb, `Driver Performance report.xlsx`);
	};

	return (
		<Container>
			<Container maxWidth={false}>
				<GridItem xs={12} sm={12} md={12}>
					<Typography className={classes.title}> Driver Performance Report </Typography>
					<ThemeProvider theme={theme}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="state"
									label="State"
									type="text"
									id="state"
									select
									value={state}
									onChange={handleChange("state")}
								>
									<MenuItem value="Maharashtra">Maharashtra</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="city"
									label="City"
									type="text"
									id="city"
									select
									value={city}
									onChange={handleChange("city")}
								>
									<MenuItem value="Pune">Pune</MenuItem>
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="operator"
									label="Operator"
									type="text"
									id="operator"
									select
									value={operator}
									onChange={handleChange("operator")}
								>
									{allProvider.map((value, index) => (
										<MenuItem value={value} key={index}>
											{value}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="Driver"
									label="Driver"
									type="text"
									id="Driver"
									select
									value={Driver}
									onChange={handleChange("Driver")}
								>
									{allDrivers.map((value, index) => (
										<MenuItem value={value} key={index}>
											{value}
										</MenuItem>
									))}
								</TextField>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="fromDate"
									label="From Date"
									type="date"
									id="fromDate"
									value={fromDate}
									onChange={handleChange("fromDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3}>
								<TextField
									InputLabelProps={{
										shrink: true,
									}}
									size="small"
									variant="outlined"
									margin="normal"
									fullWidth
									name="toDate"
									label="To Date"
									type="date"
									id="toDate"
									value={toDate}
									onChange={handleChange("toDate")}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => handleSearch()}
								>
									Search
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={() => {
										setInputValues(getInitalValues());
										setAllDriversList(mainDriversList);
									}}
								>
									Clear
								</Button>
							</GridItem>
							<GridItem xs={12} sm={12} md={2}>
								<Button
									style={{ marginTop: 20 }}
									variant="contained"
									className={classes.button}
									fullWidth="true"
									onClick={(e) => {
										exportToExcel("Export")
									}}
									startIcon={<FileDownloadOutlinedIcon fontSize="small" />}

								>
									Export
								</Button>
							</GridItem>
						</GridContainer>
						<GridContainer style={{ paddingTop: 20 }}>&nbsp;</GridContainer>
						<Divider></Divider>
						<GridContainer>
							<Table className={classes.table}>
								<TableBody>
									<TableRow>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Sr No.
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Driver Name
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Driver Email ID
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Contact Number
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Operator Email ID
											</Typography>
										</TableCell>
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Ride Completed
											</Typography>
										</TableCell>
										{/* <TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Trip Cancelled
											</Typography>
										</TableCell> */}
										<TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												Customer Rating
											</Typography>
										</TableCell>
										{/* <TableCell>
											<Typography variant="h6" gutterBottom className={classes.titleColor}>
												NowWagon Rating
											</Typography>
										</TableCell> */}
									</TableRow>

									{allDriversList.map((driver, index) => (
										<TableRow className={classes.tableRow} key={index}>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{index + 1}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{driver.firstName + " " + driver.lastName}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{driver.email}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{driver.mobileNumber}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{driver.ownerId}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{getTotalRideCount(driver.email)}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom>
													{getTotalRatings(driver.email)}
												</Typography>
											</TableCell>
											{/* <TableCell>
												<Typography variant="body1" gutterBottom></Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body1" gutterBottom></Typography>
											</TableCell> */}
										</TableRow>
									))}
								</TableBody>
							</Table>
							{/* </TableContainer> */}
						</GridContainer>
					</ThemeProvider>
				</GridItem>
			</Container>
			<Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Container>
	);
}
