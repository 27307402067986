import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

import {
  Backdrop,
  Chip,
  CircularProgress,
  Snackbar,
  TableContainer,
  TableHead,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  activateDeactiveUser,
  getAllAdmin,
} from "../../services/users/userOnboarding";
import { isAdmin } from "./AddUserView";
import Switch from "@material-ui/core/Switch";
import Paper from "@material-ui/core/Paper";
import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import MuiAlert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import _ from "lodash";

const loaderOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((styles) => ({
  backdrop: {
    zIndex: styles.zIndex.drawer + 1,
    color: "#fff",
  },

  customTableContainer: {
    overflowX: "initial",
  },

  tableCell: {
    Width: 100,
  },
  head: {
    backgroundColor: "#FFA93F",
    position: "sticky",
    top: 0,
    color: "#fff",
  },
  submit: {
    margin: styles.spacing(3, 0, 2),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
}));

const PurpleSwitch = withStyles({
  switchBase: {
    "&$checked": {
      color: "#FF8000",
    },
    "&$checked + $track": {
      backgroundColor: "#FF8000",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function AdminListView() {
  const classes = useStyles();
  const [adminList, setAdminList] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [currentUserStatus, setCurrentUserStatus] = useState(false);
  const [userStatusToastOpen, setuserStatusToastOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const showToast = (message, variant) => {
    enqueueSnackbar(message, {
      variant: variant ? variant : "info",
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };
  const closeLoader = () => {
    setOpenLoader(false);
  };

  const toggleLoader = () => {
    setOpenLoader(!openLoader);
  };

  const onDeactivate = (user) => {
    setSelectedUser(user);

    user.isActive = false;
    activateDeactiveUser(user).then((response) => {
      //console.log(response, "user Deactivated");
      setSelectedUser("");
      handleCloseConfirmationDialog();
      setCurrentUserStatus(false);
      showToast(`User is Deactivated`, "success");
      setuserStatusToastOpen(true);
    });
  };

  const onActivate = (user) => {
    setSelectedUser(user);
    user.isActive = true;
    activateDeactiveUser(user).then((response) => {
      //console.log(response, "user Activated");
      setSelectedUser("");
      handleCloseConfirmationDialog();
      setCurrentUserStatus(true);
      showToast(`Admin is Activated`, "success");
      setuserStatusToastOpen(true);
    });
  };

  const handleClose = () => {
    setuserStatusToastOpen(false);
  };

  const handleToggleUser = (user) => {
    handleOpenConfirmationDialog();
    setSelectedUser(user);
  };	

  useEffect(() => {
    toggleLoader();
    getAllAdmin().then((users) => {
      if (users) {
        users = _.orderBy(users, 'createdOnMil', "desc");
      }
      closeLoader();
      setAdminList(users);
      //console.log(users, "user");
    });
  }, []);
  //console.log(`userStatusToastOpen: ${userStatusToastOpen}`);
  return (
    <>
      <TableContainer
        component={Paper}
        className={classes.customTableContainer}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Sr.No
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Name
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Mobile No
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  E-Mail Id
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell className={classes.head}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.titleColor}
                >
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adminList.map((user, index) => (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell>
                  <Typography variant="body1" gutterBottom>
                    {index + 1}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" gutterBottom>
                    {user.firstName} {user.lastName}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" gutterBottom>
                    {user.mobileNumber}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1" gutterBottom>
                    {user.email}
                  </Typography>
                </TableCell>
                <TableCell>
                  {/* <Typography variant="body1" gutterBottom> */}
                  {user.isActive === true ? (
                    <Chip
                      label="&nbsp;ACTIVE&nbsp;"
                      color="green"
                      variant="outlined"
                      style={{ borderColor: 'green', color: 'black', fontWeight: 'bold' }}
                    />
                  ) : (
                    <Chip label="INACTIVE" variant="outlined" style={{backgroundColor:'red', color:'white', fontWeight:'bold'}}/>
                  )}
                  {/* </Typography> */}
                </TableCell>

                <TableCell>
                  <PurpleSwitch
                    checked={user.isActive ? true : false}
                    onClick={() => {
                      //user.isActive ? onDeactivate(user) : onActivate(user);
                      handleToggleUser(user);
                    }}
                  />
                </TableCell>
                {/* {user.isActive ? (
									<TableCell>
										<PurpleSwitch
											checked={true}
											onClick={() => {
												onDeactivate(user);
											}}
										/>
									</TableCell>
								) : (
									<TableCell>
										<PurpleSwitch
											onClick={() => {
												onActivate(user);
											}}
											checked={false}
										/>
									</TableCell>
								)} */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop
        className={classes.backdrop}
        open={openLoader}
        onClick={closeLoader}
      >
        {/* <CircularProgress color="inherit" /> */}
        <Lottie options={loaderOptions} height={200} width={600} />
      </Backdrop>
      <Dialog
        //component={Paper}
        //fullWidth="true"
        maxWidth="xs"
        open={openConfirmationDialog}
        //TransitionComponent={Transition}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle id="alert-dialog-title">{"NowWagon"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{`Do you want to ${
            selectedUser !== "" && selectedUser?.isActive
              ? "Deactivate"
              : "Activate"
          } user?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            className={classes.submit}
            onClick={
              selectedUser !== ""
                ? selectedUser.isActive
                  ? () => onDeactivate(selectedUser)
                  : () => onActivate(selectedUser)
                : null
            }
          >
            YES
          </Button>
          <Button
            color="primary"
            className={classes.submit}
            onClick={() => handleCloseConfirmationDialog()}
            autoFocus
          >
            NO
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
