import React from 'react';
import CustomTabs from '../../components/CustomTabs/CustomTabs';

import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import Admin from './Admin';
import AdminList from './AdminList';


export default function AdminManagement() {


    return (        
        <CustomTabs
            headerColor="warning"
            tabs={[
            
              {
                tabName: "Admin List",
                tabIcon: FormatListBulletedIcon,
                allowed: true,
                variant:"fullWidth",
                tabContent: (
                  <AdminList />
                )
              },
              {
                tabName: "Admin",
                tabIcon: AddIcon,
                allowed: true,
                tabContent: (
                  <Admin />
                )
              },
            ]}
          />
    );
}
