import React, { useEffect, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { DropzoneDialog } from "material-ui-dropzone";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import clsx from "clsx";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
	AppBar,
	Tab,
	TextField,
	Button,
	MenuItem,
	CircularProgress,
	Backdrop,
	Checkbox,
	FormControlLabel,
	Snackbar,
	TableCell,
	TableBody,
	Table,
	TableRow,
	FormHelperText,
	createMuiTheme,
	ThemeProvider,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	Select,
} from "@material-ui/core";
import {
	activateDeactiveUser,
	getAddress,
	getAdminOrExecutiveByUserName,
	getProviderByUsername,
	onBoardOperator,
	userOnBoardProvider,
} from "../../services/users/userOnboarding";
import { getAllMetadata, postData } from "../../services/metadata/metadata";
import MuiAlert from "@material-ui/lab/Alert";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DeleteOutline } from "@material-ui/icons";

import BusinessIcon from "@material-ui/icons/Business";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ComputerIcon from "@material-ui/icons/Computer";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import StepConnector from "@material-ui/core/StepConnector";
import { orange } from "@material-ui/core/colors";
import FbApp from "../../config/FbApp";
import Lottie from "react-lottie";
import animationData from "../../assets/jss/lf30_editor_fvhlirkb.json";
import { useSnackbar } from "notistack";

const loaderOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
	rendererSettings: {
		preserveAspectRatio: "xMidYMid slice",
	},
};

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	root: {
		margin: theme.spacing(1),
		backgroundColor: "#fff",
	},
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	titleColor: {
		color: "#C55100",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		margin: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	button: {
		margin: theme.spacing(1),
		backgroundColor: "#FF8000",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#c55100",
		},
	},
	instructions: {
		textAlign: "center",
	},
}));

const theme = createMuiTheme({
	palette: {
		primary: orange,
	},
});

// for icons on stepper

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: <BusinessIcon />,
		2: <GroupAddIcon />,
		3: <ComputerIcon />,
		4: <EmojiTransportationIcon />,
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: "#ccc",
		zIndex: 1,
		color: "#fff",
		width: 50,
		height: 50,
		display: "flex",
		borderRadius: "50%",
		justifyContent: "center",
		alignItems: "center",
	},
	active: {
		backgroundImage: "linear-gradient( 136deg, rgb(255, 128, 0) 50%, rgb(255, 177, 67) 100%)",
		boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
	},
	completed: {
		backgroundImage: "linear-gradient( 136deg, rgb(255, 128, 0) 50%, rgb(255, 177, 67) 100%)",
	},
});

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		"& $line": {
			backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(255, 177, 67) 100%)",
		},
	},
	completed: {
		"& $line": {
			backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(255, 177, 67) 100%)",
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: "#eaeaf0",
		borderRadius: 1,
	},
})(StepConnector);

const roles = [
	{
		value: "PROVIDER",
		label: "Operator",
	},
	{
		value: "PROVIDER-DRIVER",
		label: "Operator-Driver",
	},
];

export const isExecutive = () => {
	let user = JSON.parse(localStorage.getItem("USER_PROFILE"));
	return user.role === "EXECUTIVE";
};

export const superAdmin = () => {
	let user = JSON.parse(localStorage.getItem("USER_PROFILE"));
	return user.role === "SUPER_ADMIN";
};

export const isAdmin = () => {
	let user = JSON.parse(localStorage.getItem("USER_PROFILE"));
	return user.role === "ADMIN";
};
export const isProvider = () => {
	let user = JSON.parse(localStorage.getItem("USER_PROFILE"));
	return user.role === "PROVIDER";
};

function getSteps() {
	return ["Business Info", "Contact Detail", "KYC"];
}
function getStepContent(step) {
	switch (step) {
		case 0:
			return "Business Info...";
		case 1:
			return "Personal Detail";
		// case 2:
		// 	return 'Vehicle Categorization';
		default:
			return "Unknown step";
	}
}

// let arrayOfVehicleCategory = [];
var grouped = {};
export default function AddUser() {
	const classes = useStyles();
	const [arrayOfVehicleCategory, setArrayOfVehicleCategory] = useState([]);
	const [successToastOpen, setSuccessToastOpen] = useState(false);
	const [failureToastOpen, setFailureToastOpen] = useState(false);
	const [uploadFile, setUploadFile] = useState();
	const [userRole, setUserRole] = useState(roles);
	const [open, setOpen] = useState(false);
	const [metadata, setMetadata] = useState([]);
	const [vehicleType, setVehicleType] = useState([]);
	const [vehicleCategory, setVehicleCategory] = useState([]);
	const [vehicleCategoryActual, setVehicleCategoryActual] = useState([]);
	const [vehicleTypeName, setVehicleTypeName] = useState();
	const [activeStep, setActiveStep] = useState(0);
	const [isBackPressed, setIsBackPressed] = useState(false);
	const [skipped, setSkipped] = useState(new Set());
	const [tabValue, setTabValue] = useState("TRANSPORT");
	const [openLoader, setOpenLoader] = useState(false);
	const [businessAreaList, setBusinessAreaList] = useState([]);
	const [officeAreaList, setOfficeAreaList] = useState([]);
	const [contactAreaList, setContactAreaList] = useState([]);
	const [contactPermanentAreaList, setContactPermanentAreaList] = useState([]);
	const [checked, setChecked] = React.useState(false);
	const [contactChecked, setContactchecked] = React.useState(false);
	const [user, setUser] = React.useState({});
	const [selectedUserId, setSelectedUserId] = React.useState({});
	const [profileEdit] = useState(true);
	const [estdYearError, setEstdYearError] = useState(false);
	const [mobileNumberError, setMobileNumberError] = useState(false);
	const [businessEmailError, setBusinessEmailError] = useState(false);
	const [cntMobileNumberError, setCntMobileNumberError] = useState(false);
	const [contactMobileNumberError, setContactMobileNumberError] = useState(false);
	const [contactMobileNumberError2, setContactMobileNumberError2] = useState(false);
	const [alternateCntNoError, setAlternateCntNoError] = useState(false);
	const [contactEmailError, setContactEmailError] = useState(false);
	const [contactAadharNumberError, setContactAadharNumberError] = useState(false);
	const [contactPanNumberError, setContactPanNumberError] = useState(false);
	const [ifscNumberError, setifscNumberError] = useState(false);
	const [bankACNumberError, setBankACNumberError] = useState(false);
	const [udyogAadharNumberError, setUdyogAadharNumberError] = useState(false);
	const [panTanNoError, setpanTanNoError] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [onboarded, setOnboarded] = useState(false);
	const [openOnboardConfirmatopnDialog, setOpenOnboardConfirmatopnDialog] = useState(false);
	const [panDocumentLink, setPanDocumentLink] = useState(null);
	const [aadharDocumentLink, setAadharDocumentLink] = useState(null);
	const [drivingLicenseDocumentLink, setDrivingLicenseDocumentLink] = useState(null);
	const [panTanDocumentLink, setPanTanDocumentLink] = useState(null);
	const [accountNumberLink, setAccountNumberLink] = useState(null);
	const { enqueueSnackbar } = useSnackbar();

	const params = useParams();
	useEffect(() => {
		if (params.mode !== undefined && params.mode === "editUserMode") {
			setIsEditMode(true);
		}
		if (params.mode !== undefined && params.mode === "newUserMode") {
			setIsEditMode(false);
		}
	}, []);

	const state = useLocation("state");
	let navigate = useNavigate();

	function refreshPage() {
		window.location.reload(false);
	}

	const steps = getSteps();
	const closeLoader = () => {
		setOpenLoader(false);
	};
	const toggleLoader = () => {
		setOpenLoader(!openLoader);
	};

	const handleClose = () => {
		setSuccessToastOpen(false);
		setFailureToastOpen(false);
	};

	const showToast = (message, variant) => {
		enqueueSnackbar(message, {
			variant: variant ? variant : "info",
			anchorOrigin: {
				vertical: "top",
				horizontal: "center",
			},
		});
	};

	const businessInfoCheck = () => {
		let errorMessage = "";
		if (businessInfo.role === "") {
			errorMessage = "Plese select Role";
		} else if (businessInfo.businessName === "") {
			errorMessage = "Please Enter Business Name";
		} else if (businessInfo.businessAddress === "") {
			errorMessage = "Please Enter Registered Business Address";
		} else if (businessInfo.businessPin === "") {
			errorMessage = "Please Enter Pincode";
		} else if (businessInfo.businessLocality === "") {
			errorMessage = "Please Select Locality ";
		} else if (businessInfo.businessCity !== "Pune") {
			errorMessage = "Please Enter Pune City PinCode ";
		} else if (businessInfo.officeEmail === "") {
			errorMessage = "Please Enter Email ";
		} else if (mobileNumberError) {
			errorMessage = "Please Enter Valid Mobile No ";
		} else if (estdYearError) {
			errorMessage = "Please Enter Valid Year";
		}
		if (errorMessage !== "") {
			showToast(errorMessage, "error");
			return false;
		}
		return true;
	};
	const vehicleCheck = () => {
		let errorMessage = "";
		if (vehicleCategorization.subCategory) {
			errorMessage = "Please Select Vehicle ";
		} else if (vehicleCategorization.countOfVehicle) {
			errorMessage = "Please Select Vehicle ";
		} else if (vehicleCategorization.sizeOfVehicle) {
			errorMessage = "Please Select Vehicle ";
		}
		if (errorMessage !== "") {
			showToast(errorMessage, "error");
			return false;
		}
		return true;
	};
	const kycCheck = () => {
		let errorMessage = "";
		if (kyc === undefined) {
			errorMessage = "Please Enter KYC Details";
		} else if (!kyc?.bankName || kyc?.bankName == "") {
			errorMessage = "Please Enter Bank Name";
		} else if (!kyc?.branchName || kyc?.branchName === "") {
			errorMessage = "Please Enter Branch Name";
		} else if (!kyc?.IFSC || kyc?.IFSC === "") {
			errorMessage = "Please Enter IFSC No";
		} else if (!kyc?.accountNumber || kyc?.accountNumber === "") {
			errorMessage = "Please Enter Account No";
		} else if (!kyc?.pan_tanNumber || kyc?.pan_tanNumber === "") {
			errorMessage = "Please Enter pan_tan No";
		} else if (!kyc?.kycPan_TanDocumentLink && panTanDocumentLink === null) {
			errorMessage = "Please Upload PAN TAN Document";
		} else if (ifscNumberError) {
			errorMessage = "Please Enter Valid IFSC Code";
		} else if (bankACNumberError) {
			errorMessage = "Please Enter Valid Account Number";
		} else if (!kyc?.kycAccountDocumentLink && accountNumberLink === null) {
			errorMessage = "Please Upload Passbook Document";
		} else if (udyogAadharNumberError) {
			errorMessage = "Please Enter Valid Udyog Aadhar Number";
		} else if (panTanNoError) {
			errorMessage = "Please Enter Valid Pan/Tan Number";
		}

		if (errorMessage !== "") {
			showToast(errorMessage, "error");
			return false;
		}
		return true;
	};

	const contactDetailsCheck = () => {
		let errorMessage = "";
		if (contactDetails.contactSalutation === "") {
			errorMessage = "Please Selsect salutation";
		} else if (contactDetails.contactFirstName === "") {
			errorMessage = "Please Enter First Name";
		} else if (contactDetails.contactEmail === "") {
			errorMessage = "Plese Enter Email";
		} else if (contactDetails.contactMobileNumber === "") {
			errorMessage = "Plese Enter Mobile No";
		} else if (contactDetails.contactCurrentAddress === "") {
			errorMessage = "Plese Enter Current Address";
		} else if (contactDetails.contactPin === "") {
			errorMessage = "Plese Enter Pin";
		}
		// else if (contactDetails.contactLocality === '') {
		// 	errorMessage = 'Plese Select Locality';
		// }
		else if (contactDetails.contactPermanentAddress === "") {
			errorMessage = "Plese Enter Office Address";
		} else if (contactDetails.contactPanNumber === "") {
			errorMessage = "Plese Enter PAN No";
		} else if (contactDetails.contactAadharNumber === "") {
			errorMessage = "Plese Enter Aadhar No ";
		} else if (contactDetails.contactDrivingLicense === "") {
			errorMessage = "Plese Enter Driving License No";
		} else if (cntMobileNumberError) {
			errorMessage = "Plese Enter Valid Business Mobile No";
		} else if (alternateCntNoError) {
			errorMessage = "Plese Enter Valid Alternate No";
		} else if (contactMobileNumberError || contactMobileNumberError2) {
			errorMessage = "Plese Enter Valid Contact Person Mobile No";
		} else if (contactPanNumberError) {
			errorMessage = "Plese Enter Valid PAN Number";
		} else if (panDocumentLink === null) {
			errorMessage = "Plese Upload PAN Card Document";
		} else if (contactAadharNumberError) {
			errorMessage = "Plese Enter Valid Aadhar Number";
		} else if (aadharDocumentLink === null) {
			errorMessage = "Plese Upload Aadhar Card Document";
		} else if (drivingLicenseDocumentLink === null) {
			errorMessage = "Plese Driving License Document";
		} else if (contactEmailError) {
			errorMessage = "Plese Enter Valid Email";
		}

		if (errorMessage !== "") {
			showToast(errorMessage, "error");
			return false;
		}
		return true;
	};
	const arrayOfVehicleCategorizationMapping = () => {
		let vehicleCategoryArray = [];
		setTimeout(() => {
			//console.log('metadata for add user', postData);
			setMetadata(postData);
			const category = {};
			category.name = postData[0]?.name;
			category.subCategory = postData[0]?.subCategory;
			handleChangeVehicleCategory(category);
			if (state.state) {
				toggleLoader();
				getProviderByUsername(state.state).then((userProfile) => {
					if (userProfile) {
						//console.log(userProfile, 'profile');
						setOnboarded(userProfile?.isOnboarded);
						setBusinessInfo(userProfile?.businessInfo);
						setContactDetails(userProfile?.contactDetails);
						setKyc(userProfile?.kyc);
						if (userProfile?.vehicleCategorization !== undefined && userProfile?.vehicleCategorization !== "") {
							Object.keys(userProfile?.vehicleCategorization).forEach((key) => {
								userProfile.vehicleCategorization[key].forEach((value) => {
									vehicleCategoryArray.push(value);
								});
							});
							setArrayOfVehicleCategory(vehicleCategoryArray);
						}
						//console.log(userProfile.vehicleCategorization);
						closeLoader();
					}
				});
			}
		}, 1000);
	};

	const handleNext = () => {
		//toggleLoader();
		if (activeStep === 0) {
			const userProfileToBeUpdate = {
				role: businessInfo.role,
				isActive: true,
				isDefaultPassword: true,
				createdOn: new Date(),
				createdOnMil: new Date().getTime(),
				createdBy: "Admin",
				isOnboarded: false,
			};
			if (!businessInfoCheck()) {
				closeLoader();
				return;
			}
			userProfileToBeUpdate.businessInfo = businessInfo;
			userProfileToBeUpdate.contactDetails = contactDetails;
			if (!isEditMode) {
				userOnBoardProvider(userProfileToBeUpdate)
					.then((response) => {
						closeLoader();
						if (response) {
							//console.log('response', response);
						}
					})
					.catch((err) => {
						if (!isBackPressed) {
							showToast(err.message, "error");
							setActiveStep(0);
							return;
						}
						//console.log(err);
					});
			} else {
				if (userProfileToBeUpdate?.contactDetails?.contactPanDocumentLink !== "") {
					setPanDocumentLink(userProfileToBeUpdate.contactDetails.contactPanDocumentLink);
				}
				if (userProfileToBeUpdate?.contactDetails?.contactAadharDocumentLink !== "") {
					setAadharDocumentLink(userProfileToBeUpdate.contactDetails.contactAadharDocumentLink);
				}
				if (userProfileToBeUpdate?.contactDetails?.contactDrivingLicenseDocumentLink !== "") {
					setDrivingLicenseDocumentLink(userProfileToBeUpdate.contactDetails.contactDrivingLicenseDocumentLink);
				}
			}
		} else if (activeStep === 1) {
			const userProfileToBeUpdate = {
				role: businessInfo.role,
			};
			if (!contactDetailsCheck()) {
				closeLoader();
				return false;
			}
			userProfileToBeUpdate.businessInfo = businessInfo;
			userProfileToBeUpdate.contactDetails = contactDetails;
			//console.log('User --------', userProfileToBeUpdate);
			activateDeactiveUser(userProfileToBeUpdate).then((response) => {
				closeLoader();
				if (response) {
					//console.log('response', response);
				}
			});
		} else if (activeStep === 2) {
			const userProfileToBeUpdate = {
				role: businessInfo.role,
			};
			if (!kycCheck()) {
				closeLoader();
				return false;
			}
			userProfileToBeUpdate.businessInfo = businessInfo;
			userProfileToBeUpdate.contactDetails = contactDetails;
			userProfileToBeUpdate.kyc = kyc;
			//console.log('User --------', userProfileToBeUpdate);

			activateDeactiveUser(userProfileToBeUpdate).then((response) => {
				closeLoader();
				if (response) {
					showToast("User updated successfully", "success");
					//console.log('response', response);
					//   navigate("/app/addUser/editUserMode", {
					//     state: userProfileToBeUpdate.businessInfo.officeEmail,
					//   });
				}
				// navigate("/app/dashboard");
			});
		} else if (activeStep === 3) {
			const userProfileToBeUpdate = {
				role: businessInfo.role,
			};
			if (!vehicleCheck()) {
				closeLoader();
				return false;
			}
			userProfileToBeUpdate.businessInfo = businessInfo;
			userProfileToBeUpdate.contactDetails = contactDetails;
			userProfileToBeUpdate.kyc = kyc;
			userProfileToBeUpdate.vehicleCategorization = _.groupBy(arrayOfVehicleCategory, function (o) {
				return o.category;
			});
			metadata.forEach((cat) => {
				let categoryPresent = userProfileToBeUpdate.vehicleCategorization[cat.name];
				if (categoryPresent == undefined) {
					userProfileToBeUpdate.vehicleCategorization[cat.name] = [];
				}
			});
			if (userProfileToBeUpdate?.kyc?.kycPan_TanDocumentLink !== "") {
				setPanTanDocumentLink(userProfileToBeUpdate?.kyc?.kycPan_TanDocumentLink);
			}
			if (userProfileToBeUpdate?.kyc?.kycAccountDocumentLink !== "") {
				setAccountNumberLink(userProfileToBeUpdate?.kyc?.kycAccountDocumentLink);
			}

			activateDeactiveUser(userProfileToBeUpdate).then((response) => {
				closeLoader();
				if (response) {
					showToast("User updated successfully", "success");
					//console.log('response', response);
					navigate("/app/addUser/editUserMode", {
						state: userProfileToBeUpdate.businessInfo.officeEmail,
					});
				}
				navigate("/app/dashboard");
			});
		}

		if (state.state && activeStep === 0) {
			const userProfileToBeUpdate = {
				role: businessInfo.role,
			};
			userProfileToBeUpdate.businessInfo = businessInfo;
			//console.log('User --------', userProfileToBeUpdate);
			activateDeactiveUser(userProfileToBeUpdate).then((response) => {
				closeLoader();
				if (response) {
					//console.log('response', response);
				}
			});
		}

		if (activeStep !== 2) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else if (state.state && activeStep === 2) {
			navigate("/app/userManagement/newUserMode");
			setSuccessToastOpen(true);
			showToast("User updated successfully", "success");
		} else {
			setActiveStep(0);
			ResetForm();
			setSuccessToastOpen(true);
		}
		// setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleOnboardOperator = () => {
		if (kycCheck()) {
			setOpenOnboardConfirmatopnDialog(true);
		}
	};

	const onBoardOperatorHandler = () => {
		const userProfileToBeUpdate = {
			role: businessInfo.role,
		};
		userProfileToBeUpdate.businessInfo = businessInfo;
		userProfileToBeUpdate.contactDetails = contactDetails;
		userProfileToBeUpdate.kyc = kyc;

		activateDeactiveUser(userProfileToBeUpdate).then((response) => {
			closeLoader();
			if (businessInfo && businessInfo.officeEmail && businessInfo.role) {
				let onboardingUser = {
					email: businessInfo.officeEmail,
					name: businessInfo.businessName,
					role: businessInfo.role,
				};
				onBoardOperator(onboardingUser)
					.then((response) => {
						//console.log(response);
						getProviderByUsername(businessInfo.officeEmail)
							.then((user) => {
								user.isOnboarded = true;
								activateDeactiveUser(user)
									.then((response) => {
										setOnboarded(true);
										setOpenOnboardConfirmatopnDialog(false);
										showToast("Operator onboarded successfully", "success");
										navigate("/app/userManagement/newUserMode");
									})
									.catch((error) => {
										//console.log(error);
									});
							})
							.catch((error) => {
								//console.log(error);
							});
					})
					.catch((error) => {
						//console.log(error);
					});
			}
		});
	};

	const handleBack = () => {
		toggleLoader();
		setIsBackPressed(true);
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		closeLoader();
	};

	const [vehicleCategorization, setVehicleCategorization] = useState({
		countOfVehicle: "",
		subCategory: "",
		sizeOfVehicle: "",
	});

	const [businessInfo, setBusinessInfo] = useState({
		role: "",
		businessName: "",
		typeOfOwnership: "",
		yearOfEstablishment: "",
		businessAddress: "",
		businessLandmark: "",
		businessPin: "",
		businessLocality: "",
		businessCity: "",
		businessState: "",
		officeAddress: "",
		officePin: "",
		officeLocality: "",
		officeEmail: "",
		officeCity: "",
		officeState: "",
		officeTelephone: "",
		officeFaxNumber: "",
		officeWebsite: "",
	});
	const [contactDetails, setContactDetails] = useState({
		contactSalutation: "",
		contactFirstName: "",
		contactMiddleName: "",
		contactLastName: "",
		contactMobileNumber: "",
		contactEmail: "",
		contactAlternetMobile: "",
		contactTelephone: "",
		contactCurrentAddress: "",
		contactLandmark: "",
		contactPin: "",
		contactLocality: "",
		contactCity: "",
		contactState: "",
		contactPermanentAddress: "",
		contactPermanentLandmark: "",
		contactPermanentPin: "",
		contactPermanentLocality: "",
		contactPermanentCity: "",
		contactPermanentState: "",
		contactPanNumber: "",
		contactAadharNumber: "",
		contactDrivingLicense: "",
		contactAddressProof: "",

		contactPersonSalutation: "",
		contactPersonFirstname: "",
		contactPersonmiddlename: "",
		contactPersonLastname: "",
		contactPersonEmail: "",
		contactPersonDesignation: "",
		contactPersonMobileNumber: "",
		contactPersonTelephone: "",
	});

	const [kyc, setKyc] = useState({
		bankName: "",
		branchName: "",
		IFSC: "",
		accountNumber: "",
		pan_tanNumber: "",
		GSTNumber: "",
		transportLicense: "",
		licenseExpiry: "",
		udyogAadhar: "",
		shopActNumber: "",
	});

	const [values, setValues] = useState({
		kyc: {},
		businessInfo: {},
		contactDetails: {},
		vehicleCategorization: {},
		password: "fb1234",
		isDefaultPassword: true,
	});

	const ResetForm = () => {
		setBusinessInfo({
			...businessInfo,
			role: "",
			businessName: "",
			typeOfOwnership: "",
			yearOfEstablishment: "",
			businessAddress: "",
			businessLandmark: "",

			businessPin: "",
			businessLocality: "",
			businessCity: "",
			businessState: "",

			officeAddress: "",
			officePin: "",
			officeLocality: "",
			officeCity: "",
			officeState: "",
			officeEmail: "",
			officeTelephone: "",
			officeFaxNumber: "",
			officeWebsite: "",
		});
		setContactDetails({
			...contactDetails,
			contactSalutation: "",
			contactFirstName: "",
			contactMiddleName: "",
			contactLastName: "",
			contactMobileNumber: "",
			contactEmail: "",
			contactAleternetMobile: "",
			contactTelephone: "",
			contactCurrentAddress: "",
			contactLandmark: "",

			contactPin: "",
			contactLocality: "",
			contactCity: "",
			contactState: "",

			contactPermanentAddress: "",
			contactPermanentLandmark: "",
			contactPermanentPin: "",
			contactPermanentLocality: "",
			contactPermanentCity: "",
			contactPermanentState: "",
			contactPanNumber: "",
			contactAadharNumber: "",
			contactDrivingLicense: "",
			contactAddressProof: "",

			contactPersonSalutation: "",
			contactPersonFirstname: "",
			contactPersonmiddlename: "",
			contactPersonLastname: "",
			contactPersonEmail: "",
			contactPersonDesignation: "",
			contactPersonMobileNumber: "",
			contactPersonTelephone: "",
		});
		setKyc({
			...kyc,
			bankName: "",
			branchName: "",
			IFSC: "",
			accountNumber: "",
			pan_tanNumber: "",
			GSTNumber: "",
			transportLicense: "",
			licenseExpiry: "",
			udyogAadhar: "",
			shopActNumber: "",
		});
		setValues({
			...values,
		});
		setVehicleCategorization({
			...vehicleCategorization,
			countOfVehicle: "",
			subCategory: "",
			sizeOfVehicle: "",
		});
		setChecked(!checked);
		setContactchecked(!checked);
	};

	const handleChangeContactDetails = (name) => (event) => {
		let value = event.target.value;
		if (name === "contactPin") {
			if (event.target.value.length === 6) {
				getAddress(event.target.value).then((response) => {
					if (response.PostOffice === null) {
						// alert("pincode not match");
						showToast("pincode do not match", "error");
						return;
					} else if (response) {
						if (response && response.PostOffice && response.PostOffice[0]) {
							contactDetails.contactCity = response.PostOffice[0].District;
							contactDetails.contactState = response.PostOffice[0].State;
							contactDetails.contactLocality = "";
							setContactAreaList([...response.PostOffice]);
							setContactPermanentAreaList([...response.PostOffice]);
						}
						setContactDetails({
							...contactDetails,
							error: false,
							[name]: event.target.value,
						});
					}
				});
			}
		}
		if (name === "contactPermanentPin") {
			if (event.target.value.length === 6) {
				getAddress(event.target.value).then((response) => {
					if (response.PostOffice === null) {
						// alert("pincode not match");
						showToast("Pincode does not match", "error");
						return;
					} else if (response) {
						if (response && response.PostOffice && response.PostOffice[0]) {
							contactDetails.contactPermanentCity = response.PostOffice[0].District;
							contactDetails.contactPermanentState = response.PostOffice[0].State;
							contactDetails.contactPermanentPin = "";
							setContactPermanentAreaList(response.PostOffice);
						}
						setContactDetails({
							...contactDetails,
							error: false,
							[name]: event.target.value,
						});
					}
				});
			}
		}
		if (name === "contactAadharNumber") {
			let aadharNumberRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
			if (value !== "" && !aadharNumberRegex.test(value)) {
				setContactAadharNumberError(!aadharNumberRegex.test(value));
			} else if (aadharNumberRegex.test(value)) {
				setContactAadharNumberError(false);
			} else if (value === "") {
				setContactAadharNumberError(false);
			}
		}
		if (name === "contactPanNumber") {
			let panNumberRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
			if (value !== "" && !panNumberRegex.test(value)) {
				setContactPanNumberError(!panNumberRegex.test(value));
			} else if (panNumberRegex.test(value)) {
				setContactPanNumberError(false);
			} else if (value === "") {
				setContactPanNumberError(false);
			}
		}
		if (name === "contactMobileNumber") {
			let mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
			if (value !== "" && !mobileNumberRegex.test(value)) {
				setCntMobileNumberError(!mobileNumberRegex.test(value));
			} else if (mobileNumberRegex.test(value)) {
				setCntMobileNumberError(false);
			} else if (value === "") {
				setCntMobileNumberError(false);
			}
		}
		if (name === "contactPersonMobileNumber") {
			let mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
			if (value !== "" && !mobileNumberRegex.test(value)) {
				setContactMobileNumberError(!mobileNumberRegex.test(value));
			} else if (mobileNumberRegex.test(value)) {
				setContactMobileNumberError(false);
			} else if (value === "") {
				setContactMobileNumberError(false);
			}
		}
		if (name === "contactPersonTelephone") {
			let mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
			if (value !== "" && !mobileNumberRegex.test(value)) {
				setContactMobileNumberError2(!mobileNumberRegex.test(value));
			} else if (mobileNumberRegex.test(value)) {
				setContactMobileNumberError2(false);
			} else if (value === "") {
				setContactMobileNumberError2(false);
			}
		}
		if (name === "contactAlternetMobile") {
			let mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
			if (value !== "" && !mobileNumberRegex.test(value)) {
				setAlternateCntNoError(!mobileNumberRegex.test(value));
			} else if (mobileNumberRegex.test(value)) {
				setAlternateCntNoError(false);
			} else if (value === "") {
				setAlternateCntNoError(false);
			}
		}
		if (name === "contactEmail") {
			let businessEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (value !== "" && !businessEmailRegex.test(value)) {
				setContactEmailError(!businessEmailRegex.test(value));
			} else if (businessEmailRegex.test(value)) {
				setContactEmailError(false);
			} else if (value === "") {
				setContactEmailError(false);
			}
		}
		let contactCurrentAddress = name === "contactCurrentAddress" ? value : contactDetails.contactCurrentAddress;
		let contactPin = name === "contactPin" ? value : contactDetails.contactPin;
		let contactLocality = name === "contactLocality" ? value : contactDetails.contactLocality;
		let contactCity = name === "contactCity" ? value : contactDetails.contactCity;
		let contactState = name === "contactState" ? value : contactDetails.contactState;

		if (contactChecked) {
			setContactDetails({
				...contactDetails,
				contactPermanentAddress: contactCurrentAddress,
				contactPermanentPin: contactPin,
				contactPermanentLocality: contactLocality,
				contactPermanentCity: contactCity,
				contactPermanentState: contactState,
				error: false,
				[name]: event.target.value,
			});
		} else {
			setContactDetails({
				...contactDetails,
				error: false,
				[name]: event.target.value,
			});
		}
	};
	useEffect(() => {
		if (contactDetails.contactPermanentPin.length === 6 && isEditMode) {
			getAddress(contactDetails.contactPermanentPin).then((response) => {
				if (response.PostOffice === null) {
					// alert("pincode not match");
					showToast("Pincode does not match", "error");
					return;
				} else if (response) {
					if (response && response.PostOffice && response.PostOffice[0]) {
						contactDetails.contactPermanentCity = response.PostOffice[0].District;
						contactDetails.contactPermanentState = response.PostOffice[0].State;
						setContactPermanentAreaList(response.PostOffice);
					}
					setContactDetails({
						...contactDetails,
						error: false,
						//[name]: event.target.value,
					});
				}
			});
		}
	}, [contactDetails.contactPermanentPin]);

	useEffect(() => {
		if (contactDetails && contactDetails.contactPin.length === 6 && isEditMode) {
			getAddress(contactDetails.contactPin).then((response) => {
				if (response.PostOffice === null) {
					// alert("pincode not match");
					showToast("pincode do not match", "error");
					return;
				} else if (response) {
					if (response && response.PostOffice && response.PostOffice[0]) {
						contactDetails.contactCity = response.PostOffice[0].District;
						contactDetails.contactState = response.PostOffice[0].State;
						setContactAreaList(response.PostOffice);
					}
					setContactDetails({
						...contactDetails,
						error: false,
						//[name]: event.target.value,
					});
				}
			});
		}
	}, [contactDetails.contactPin]);

	useEffect(() => {
		if (businessInfo && businessInfo.businessPin.length === 6 && isEditMode) {
			getAddress(businessInfo.businessPin).then((response) => {
				//console.log('BUSSINESS PIN', response);
				if (response.PostOffice === null) {
					// alert("pincode not match");
					showToast("Pincode does not match", "error");
					return;
				} else if (response) {
					if (response && response.PostOffice && response.PostOffice[0]) {
						businessInfo.businessCity = response?.PostOffice[0]?.District;
						businessInfo.businessState = response?.PostOffice[0]?.State;
						// businessInfo.businessLocality = "";
						setBusinessAreaList(response.PostOffice);
					}
					setBusinessInfo({
						...businessInfo,
						error: false,
						//[name]: event.target.value,
					});
				}
			});
		}
	}, [businessInfo.businessPin]);
	useEffect(() => {
		if (businessInfo && businessInfo.officePin.length === 6 && isEditMode && !checked) {
			getAddress(businessInfo.officePin).then((response) => {
				if (response.PostOffice === null) {
					// alert("pincode not match");
					showToast("Pincode does not match", "error");
					return;
				} else if (response) {
					if (response && response.PostOffice && response.PostOffice[0]) {
						businessInfo.officeCity = response.PostOffice[0].District;
						businessInfo.officeState = response.PostOffice[0].State;
						setOfficeAreaList(response.PostOffice);
						//console.log('INSIDE OFFICE PIN');
						setBusinessInfo({
							...businessInfo,
							error: false,
							//[name]: event.target.value,
						});
					}
				}
			});
		}
	}, [businessInfo.officePin]);

	const handleChangeBusinessInfo = (name) => (event) => {
		let value = event.target.value;
		if (name === "businessPin") {
			if (businessInfo && event.target.value.length === 6) {
				getAddress(event.target.value).then((response) => {
					if (response.PostOffice === null) {
						// alert("pincode not match");
						showToast("Pincode does not match", "error");
						return;
					} else if (response) {
						if (response && response.PostOffice && response.PostOffice[0]) {
							businessInfo.businessCity = response?.PostOffice[0]?.District;
							businessInfo.businessState = response?.PostOffice[0]?.State;
							businessInfo.businessLocality = "";
							setBusinessAreaList([...response.PostOffice]);
							if (checked) {
								setOfficeAreaList([...response.PostOffice]);
							}
						}
						setBusinessInfo({
							...businessInfo,
							error: false,
							[name]: event.target.value,
						});
					}
				});
			}
		}
		if (name === "officePin") {
			if (event.target.value.length === 6) {
				getAddress(event.target.value).then((response) => {
					if (response.PostOffice === null) {
						// alert("pincode not match");
						showToast("Pincode does not match", "error");
						return;
					} else if (response) {
						if (response && response.PostOffice && response.PostOffice[0]) {
							businessInfo.officeCity = response.PostOffice[0].District;
							businessInfo.officeState = response.PostOffice[0].State;
							// businessInfo.officePin = '';
							setOfficeAreaList(response.PostOffice);
							//console.log(response.PostOffice);
						}

						// setValues({ ...values, error: false, [name]: event.target.value });
						setBusinessInfo({
							...businessInfo,
							error: false,
							[name]: event.target.value,
						});
					}
				});
			}
		}
		if (name === "yearOfEstablishment") {
			let currentYear = new Date().getFullYear();
			if (value !== "" && value > currentYear) {
				setEstdYearError(true);
			} else {
				setEstdYearError(false);
			}
		}
		if (name === "officeTelephone") {
			let mobileNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
			if (value !== "" && !mobileNumberRegex.test(value)) {
				setMobileNumberError(!mobileNumberRegex.test(value));
			} else if (mobileNumberRegex.test(value)) {
				setMobileNumberError(false);
			} else if (value === "") {
				setMobileNumberError(false);
			}
		}
		if (name === "officeEmail") {
			let businessEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (value !== "" && !businessEmailRegex.test(value)) {
				setBusinessEmailError(!businessEmailRegex.test(value));
			} else if (businessEmailRegex.test(value)) {
				setBusinessEmailError(false);
			} else if (value === "") {
				setBusinessEmailError(false);
			}
		}
		let officeAddress = "";
		let officePin = "";
		let officeCity = "";
		let officeState = "";
		let officeLocality = "";
		if (checked) {
			officeAddress = name === "businessAddress" ? value : businessInfo.businessAddress;
			officePin = name === "businessPin" ? value : businessInfo.businessPin;
			officeCity = name === "businessCity" ? value : businessInfo.businessCity;
			officeState = name === "businessState" ? value : businessInfo.businessState;
			officeLocality = name === "businessLocality" ? value : businessInfo.businessLocality;
		}
		if (checked) {
			setBusinessInfo({
				...businessInfo,
				error: false,
				officeAddress: officeAddress,
				officePin: officePin,
				officeCity: officeCity,
				officeState: officeState,
				officeLocality: officeLocality,
				[name]: event.target.value,
			});
		} else {
			setBusinessInfo({
				...businessInfo,
				error: false,
				[name]: event.target.value,
			});
		}
	};
	useEffect(() => {
		if (checked) {
			setBusinessInfo({
				...businessInfo,
				error: false,
				officeAddress: businessInfo.businessAddress,
				officePin: businessInfo.businessPin,
				officeCity: businessInfo.businessCity,
				officeState: businessInfo.businessState,
				officeLocality: businessInfo.businessLocality,
			});
		}
	}, [businessInfo.businessCity]);
	useEffect(() => {
		if (contactChecked) {
			setContactDetails({
				...contactDetails,
				contactPermanentAddress: contactDetails.contactCurrentAddress,
				contactPermanentPin: contactDetails.contactPin,
				contactPermanentLocality: contactDetails.contactLocality,
				contactPermanentCity: contactDetails.contactCity,
				contactPermanentState: contactDetails.contactState,
				error: false,
			});
		}
	}, [contactDetails.contactCity]);

	const handleChangeKyc = (name) => (event) => {
		// setValues({ ...values, error: false, [name]: event.target.value });
		let value = event.target.value;
		if (name === "IFSC") {
			let IFSCRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
			if (value !== "" && !IFSCRegex.test(value)) {
				setifscNumberError(!IFSCRegex.test(value));
			} else if (IFSCRegex.test(value)) {
				setifscNumberError(false);
			} else if (value === "") {
				setifscNumberError(false);
			}
		}
		if (name === "accountNumber") {
			let bankACNumberRegex = /^[0-9]+$/;
			if (value !== "" && !bankACNumberRegex.test(value)) {
				setBankACNumberError(!bankACNumberRegex.test(value));
			} else if (bankACNumberRegex.test(value)) {
				setBankACNumberError(false);
			} else if (value === "") {
				setBankACNumberError(false);
			}
		}

		if (name === "udyogAadhar") {
			let aadharNumberRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
			if (value !== "" && !aadharNumberRegex.test(value)) {
				setUdyogAadharNumberError(!aadharNumberRegex.test(value));
			} else if (aadharNumberRegex.test(value)) {
				setUdyogAadharNumberError(false);
			} else if (value === "") {
				setUdyogAadharNumberError(false);
			}
		}
		if (name === "pan_tanNumber") {
			let panTanRegex = /^([A-Z0-9]{10})$/;
			if (value !== "" && !panTanRegex.test(value)) {
				setpanTanNoError(!panTanRegex.test(value));
			} else if (panTanRegex.test(value)) {
				setpanTanNoError(false);
			} else if (value === "") {
				setpanTanNoError(false);
			}
		}
		setKyc({ ...kyc, error: false, [name]: event.target.value });
	};
	const getPathStorageFromUrl = (url) => {
		const baseUrl = `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/`;
		let imagePath = url.replace(baseUrl, "");
		const indexOfEndPath = imagePath.indexOf("?");
		imagePath = imagePath.substring(0, indexOfEndPath);
		imagePath = imagePath.replace("%2F", "/");
		//console.log(imagePath);
		return imagePath;
	};

	const deleteFileByUrl = (link) => {
		const storageRef = FbApp.storage().ref();
		var deserRef = storageRef.child(getPathStorageFromUrl(link));
		deserRef
			.delete()
			.then(() => {
				//console.log('deleted');
				contactDetails.contactPanDocumentLink = "";
			})
			.catch((error) => {
				//console.log(error);
			});
	};

	const handleUploadContactPan = async (e) => {
		if (onChangeUploadFile(e)) {
			if (contactDetails.contactPanDocumentLink !== undefined) {
				deleteFileByUrl(contactDetails.contactPanDocumentLink);
			}
			setOpenLoader(true);
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/PAN" + file.name);
			await fileRef.put(file);

			fileRef.getDownloadURL().then((link) => {
				contactDetails.contactPanDocumentLink = link;
				setPanDocumentLink(contactDetails.contactPanDocumentLink);
				setOpenLoader(false);
			});
			//console.log('testing docment upload', contactDetails.contactPanDocumentLink);
		} else {
			e.target.value = null;
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const handleUploadContactAadhar = async (e) => {
		if (onChangeUploadFile(e)) {
			if (contactDetails.contactAadharDocumentLink !== undefined) {
				deleteFileByUrl(contactDetails.contactAadharDocumentLink);
			}
			setOpenLoader(true);
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/AADHAR" + file.name);
			await fileRef.put(file);
			fileRef.getDownloadURL().then((link) => {
				contactDetails.contactAadharDocumentLink = link;
				setAadharDocumentLink(contactDetails.contactAadharDocumentLink);
				setOpenLoader(false);
			});
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const handleUploadContactDrivingLicense = async (e) => {
		if (onChangeUploadFile(e)) {
			if (contactDetails.contactDrivingLicenseDocumentLink !== undefined) {
				deleteFileByUrl(contactDetails.contactDrivingLicenseDocumentLink);
			}
			setOpenLoader(true);
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/DRIVINGLICENSE" + file.name);
			await fileRef.put(file);
			fileRef.getDownloadURL().then((link) => {
				contactDetails.contactDrivingLicenseDocumentLink = link;
				setDrivingLicenseDocumentLink(contactDetails.contactDrivingLicenseDocumentLink);
				setOpenLoader(false);
			});
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const handleUploadChangeContactAddressProof = async (e) => {
		if (onChangeUploadFile(e)) {
			if (contactDetails.contactAddressProofDocumentLink !== undefined) {
				deleteFileByUrl(contactDetails.contactAddressProofDocumentLink);
			}
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/ADDRESSPROOF" + file.name);
			await fileRef.put(file);
			contactDetails.contactAddressProofDocumentLink = await fileRef.getDownloadURL();
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const handleUploadChangeAccountNumber = async (e) => {
		if (onChangeUploadFile(e)) {
			if (kyc?.kycAccountDocumentLink !== undefined) {
				deleteFileByUrl(kyc?.kycAccountDocumentLink);
			}
			setOpenLoader(true);
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/ACCOUNTNUMBER" + file.name);
			await fileRef.put(file);
			await fileRef.getDownloadURL().then((link) => {
				kyc.kycAccountDocumentLink = link;
				setAccountNumberLink(kyc?.kycAccountDocumentLink);
				setOpenLoader(false);
			});
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};
	const handleUploadChangePan_TanNumber = async (e) => {
		if (onChangeUploadFile(e)) {
			if (kyc?.kycPan_TanDocumentLink !== undefined) {
				deleteFileByUrl(kyc?.kycPan_TanDocumentLink);
			}
			setOpenLoader(true);
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/PAN_TAN" + file.name);
			await fileRef.put(file);
			fileRef.getDownloadURL().then((link) => {
				kyc.kycPan_TanDocumentLink = link;
				setPanTanDocumentLink(kyc?.kycPan_TanDocumentLink);
				setOpenLoader(false);
			});
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const handleUploadChangeGSTNumber = async (e) => {
		if (onChangeUploadFile(e)) {
			if (kyc?.kycGSTDocumentLink !== undefined) {
				deleteFileByUrl(kyc?.kycGSTDocumentLink);
			}
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/GSTNUMBER" + file.name);
			await fileRef.put(file);
			kyc.kycGSTDocumentLink = await fileRef.getDownloadURL();
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const handleUploadChangeShopActNumber = async (e) => {
		if (onChangeUploadFile(e)) {
			if (contactDetails.shopActDocumentLink !== undefined) {
				deleteFileByUrl(contactDetails.shopActDocumentLink);
			}
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/SHOPACT" + file.name);
			await fileRef.put(file);
			kyc.shopActDocumentLink = await fileRef.getDownloadURL();
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const handleUploadChangeTransportLicense = async (e) => {
		if (onChangeUploadFile(e)) {
			if (kyc?.kycTransportlicenceDocumentLink !== undefined) {
				deleteFileByUrl(kyc?.kycTransportlicenceDocumentLink);
			}
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/TRANSPORTLISENCE" + file.name);
			await fileRef.put(file);
			kyc.kycTransportlicenceDocumentLink = await fileRef.getDownloadURL();
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const handleUploadChangeUdyogAadhar = async (e) => {
		if (onChangeUploadFile(e)) {
			if (kyc?.kycUdyogAdharDocumentLink !== undefined) {
				deleteFileByUrl(kyc?.kycUdyogAdharDocumentLink);
			}
			const file = e.target.files[0];
			const storageRef = FbApp.storage().ref();
			const fileRef = storageRef.child(businessInfo.officeEmail + "/" + "/UDYOGADHAR" + file.name);
			await fileRef.put(file);
			kyc.kycUdyogAdharDocumentLink = await fileRef.getDownloadURL();
		} else {
			e.target.value = null;
			// alert("Supported file formats - pdf, jpg, png.");
			showToast("Supported file formats - pdf, jpg, png.", "warning");
		}
	};

	const onCheckboxClick = (event) => {
		if (event.target.checked) {
			setOfficeAreaList([...businessAreaList]);
			setBusinessInfo({
				...businessInfo,
				error: false,
				officeAddress: businessInfo.businessAddress,
				officePin: businessInfo.businessPin,
				officeCity: businessInfo.businessCity,
				officeState: businessInfo.businessState,
				officeLocality: businessInfo.businessLocality,
			});
		} else {
			setOfficeAreaList([]);
			setBusinessInfo({
				...businessInfo,
				error: false,
				officeAddress: "",
				officePin: "",
				officeCity: "",
				officeState: "",
				officeLocality: "",
			});
		}
		setChecked(event.target.checked);
	};
	const onContactCheckboxClick = (event) => {
		setContactchecked(event.target.checked);
		if (event.target.checked) {
			setContactDetails({
				...contactDetails,
				contactPermanentAddress: contactDetails.contactCurrentAddress,
				contactPermanentPin: contactDetails.contactPin,
				contactPermanentLocality: contactDetails.contactLocality,
				contactPermanentCity: contactDetails.contactCity,
				contactPermanentState: contactDetails.contactState,
			});
			setContactPermanentAreaList([...contactAreaList]);
		} else {
			setContactDetails({
				...contactDetails,
				contactPermanentAddress: "",
				contactPermanentPin: "",
				contactPermanentLocality: "",
				contactPermanentCity: "",
				contactPermanentState: "",
			});
			setContactPermanentAreaList([]);
		}
	};

	const handleChangeVehicleCategory = (category) => {
		//console.log('category selected', category);
		if (category !== "") {
			setVehicleType(category.subCategory);
			setTabValue(category.name);
		}
	};

	const handleChangeVehicleType = (e) => {
		if (e.numberOfVehicle === undefined) {
		}
		setVehicleTypeName(e);
		metadata.forEach((data) => {
			vehicleCategory.forEach((sub) => {
				if (sub.vehicleCategory === data.name) {
					vehicleCategory.vehicleType.push(e);
				}
			});
		});
		//console.log('final', e);
	};

	const handleUploadChange = (files) => {
		setUploadFile(files);
	};

	const handleChangeCategory = (name) => (event) => {
		let valueToSet = event.target.value;
		/* if (name === 'subCategory') {
			arrayOfVehicleCategory.forEach((data) => {
				if (data.subCategory === event.target.value) {
					event.target.value = undefined;
					// alert("Duplicate Value can not select");
					showToast('Duplicate values can not be selected', 'warning');
					return;
				}
			});
		} */
		setVehicleCategorization({
			...vehicleCategorization,
			error: false,
			[name]: valueToSet,
		});
	};

	const onUpdateVehicleCategory = () => {
		if (vehicleCategorization.subCategory === "" || vehicleCategorization.countOfVehicle === "") {
			setFailureToastOpen(true);
			return;
		}
		vehicleCategorization.category = tabValue;
		let tempArray = [...arrayOfVehicleCategory];
		tempArray = tempArray.filter((cat) => {
			if (
				cat.category == tabValue &&
				cat.subCategory == vehicleCategorization.subCategory &&
				cat.sizeOfVehicle == vehicleCategorization.sizeOfVehicle
			) {
				return true;
			} else {
				return false;
			}
		});
		if (tempArray.length == 0) {
			setArrayOfVehicleCategory([...arrayOfVehicleCategory, vehicleCategorization]);
		} else {
			showToast("Duplicate entries not allowed. Please delete and add if needed", "error");
		}
		setVehicleCategorization({
			sizeOfVehicle: "",
			countOfVehicle: "",
			subCategory: "",
		});
	};

	const onChangeUploadFile = (evt) => {
		const files = evt.target.files[0];
		const ext = files.type.split(".").pop();
		// alert(ext);
		//showToast(`${ext}...`, 'info');
		if (ext == "application/pdf" || ext == "image/png" || ext == "image/jpeg") {
			return true;
		}
	};

	const onDeleteVehicleCategory = (value, index, selectedVehicles) => {
		selectedVehicles.splice(index, 1);
		showToast("Vehicle type removed from list", "success");
		setArrayOfVehicleCategory([...selectedVehicles]);
	};

	useEffect(() => {
		getAllMetadata();
		arrayOfVehicleCategorizationMapping();
	}, []);

	return (
		<>
			<div className={classes.root}>
				<ThemeProvider theme={theme}>
					<Stepper activeStep={activeStep} connector={<ColorlibConnector />}>
						{steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps} StepIconComponent={ColorlibStepIcon}>
										{label}
									</StepLabel>
								</Step>
							);
						})}
					</Stepper>
					{/*  Buisness Info Start  */}
					<div style={{ display: activeStep === 0 ? "block" : "none" }} className={classes.root}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<form noValidate autoComplete="on">
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												label="Role"
												name="role"
												id="role"
												select
												value={businessInfo.role}
												onChange={handleChangeBusinessInfo("role")}
											>
												{userRole.map((option) => (
													<MenuItem key={option.value} value={option.value}>
														{option.label}
													</MenuItem>
												))}
											</TextField>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="businessName"
												label="Business Name"
												type="text"
												id="businessName"
												value={businessInfo.businessName}
												onChange={handleChangeBusinessInfo("businessName")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="typeOfOwnership"
												label="Type of Ownership"
												type="text"
												id="typeOfOwnership"
												select
												value={businessInfo.typeOfOwnership}
												onChange={handleChangeBusinessInfo("typeOfOwnership")}
											>
												<MenuItem value="Properietorship">Properietorship</MenuItem>
												<MenuItem value="Pvt.Ltd.">Pvt.Ltd.</MenuItem>
												<MenuItem value="Public Ltd.">Public Ltd.</MenuItem>
												<MenuItem value="Partnership">Partnership</MenuItem>
											</TextField>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												inputProps={{ maxLength: 4 }}
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="yearOfEstablishment"
												label="Year of Establishment"
												type="text"
												id="yearOfEstablishment"
												value={businessInfo.yearOfEstablishment}
												onChange={handleChangeBusinessInfo("yearOfEstablishment")}
												error={estdYearError}
												helperText={estdYearError ? "Please Enter Valid Year" : ""}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={8}>
											<TextField
												size="small"
												//disabled={profileEdit ? false : true}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="businessAddress"
												label="Registered Address"
												type="businessAddress"
												id="businessAddress"
												value={businessInfo.businessAddress}
												onChange={handleChangeBusinessInfo("businessAddress")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={4}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="businessLandmark"
												label="Landmark"
												type="businessLandmark"
												id="businessLandmark"
												value={businessInfo.businessLandmark}
												onChange={handleChangeBusinessInfo("businessLandmark")}
											/>
										</GridItem>
									</GridContainer>

									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												inputProps={{ maxLength: 6 }}
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="businessPin"
												label="Pin Code"
												type="text"
												id="businessPin"
												value={businessInfo.businessPin}
												onChange={handleChangeBusinessInfo("businessPin")}
											/>
										</GridItem>

										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="businessState"
												label="State"
												type="text"
												id="businessState"
												value={businessInfo.businessState}
												onChange={handleChangeBusinessInfo("businessState")}
											/>
										</GridItem>

										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="businessCity"
												label="City"
												id="businessCity"
												value={businessInfo.businessCity}
												onChange={handleChangeBusinessInfo("businessCity")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="businessLocality"
												label="Locality"
												type="text"
												id="businessLocality"
												select
												value={businessInfo.businessLocality}
												onChange={handleChangeBusinessInfo("businessLocality")}
												required
											>
												{businessAreaList.map((value, index) => (
													<MenuItem value={value.Name} key={index}>
														{value.Name}
													</MenuItem>
												))}
											</TextField>
										</GridItem>
										{/* {!state.state ? (
											
										) : (
											<GridItem xs={12} sm={12} md={3}>
												<TextField
													size="small"
													variant="outlined"
													margin="normal"
													fullWidth
													name="businessLocality"
													label="Locality"
													type="text"
													id="businessLocality"
													value={businessInfo.businessLocality}
													onChange={handleChangeBusinessInfo('businessLocality')}
												></TextField>
											</GridItem>
										)} */}
									</GridContainer>
									<GridContainer>
										<FormControlLabel
											className={classes.root}
											control={<Checkbox onClick={onCheckboxClick} style={{ color: "#FF8000" }} />}
											label="Same as Reg. Address"
										/>
										<GridItem xs={12} sm={12} md={12}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="officeAddress"
												label="Office Address"
												type="text"
												id="officeAddress"
												value={businessInfo.officeAddress}
												onChange={handleChangeBusinessInfo("officeAddress")}
												disabled={checked}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												inputProps={{ maxLength: 6 }}
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="officePin"
												label="PinCode"
												type="officePin"
												id="officePin"
												value={businessInfo.officePin}
												onChange={handleChangeBusinessInfo("officePin")}
												disabled={checked}
											/>
										</GridItem>

										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="officeState"
												label="State"
												type="text"
												id="officeState"
												value={businessInfo.officeState}
												disabled={checked}
												onChange={handleChangeBusinessInfo("officeState")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="officeCity"
												label="City"
												type="text"
												id="officeCity"
												value={businessInfo.officeCity}
												disabled={checked}
												onChange={handleChangeBusinessInfo("officeCity")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="officeLocality"
												label="Locality"
												type="text"
												id="officeLocality1"
												select
												onChange={handleChangeBusinessInfo("officeLocality")}
												disabled={checked}
												value={businessInfo.officeLocality}
											>
												{officeAreaList.map((value, index) => (
													<MenuItem key={index} value={value.Name}>
														{value.Name}
													</MenuItem>
												))}
											</TextField>
										</GridItem>
										{/* {state.state || checked ? (
												
											) : (
												<GridItem xs={12} sm={12} md={3}>
													<TextField
														size="small"
														variant="outlined"
														margin="normal"
														fullWidth
														name="officeLocality"
														label="Locality"
														type="officeLocality"
														id="officeLocality"
														select
														value={businessInfo.officeLocality}
														onChange={handleChangeBusinessInfo('officeLocality')}
													>
														{officeAreaList.map((value, index) => (
															<MenuItem key={index} value={value.Name}>
																{value.Name}
															</MenuItem>
														))}
													</TextField>
												</GridItem>
											)} */}
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="officeEmail"
												label="E-mail Id"
												type="email"
												id="officeEmail"
												value={businessInfo.officeEmail}
												onChange={handleChangeBusinessInfo("officeEmail")}
												InputLabelProps={{ shrink: true }}
												disabled={isEditMode}
												error={businessEmailError}
												helperText={businessEmailError ? "Please Enter Valid Email" : ""}
											/>
											<FormHelperText id="component-helper-text">
												<Typography component="h7" variant="h7">
													This Email Id will use for Provider Login as a userId
												</Typography>
											</FormHelperText>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="officeTelephone"
												label="Mobile Number"
												id="officeTelephone"
												value={businessInfo.officeTelephone}
												onChange={handleChangeBusinessInfo("officeTelephone")}
												type="number"
												error={mobileNumberError}
												helperText={mobileNumberError ? "Please Enter Valid Number" : ""}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="officeFaxNumber"
												label="Fax Number"
												id="officeFaxNumber"
												value={businessInfo.officeFaxNumber}
												onChange={handleChangeBusinessInfo("officeFaxNumber")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="officeWebsite"
												label="Website"
												type="officeWebsite"
												id="officeWebsite"
												value={businessInfo.officeWebsite}
												onChange={handleChangeBusinessInfo("officeWebsite")}
											/>
										</GridItem>
									</GridContainer>
								</form>
							</GridItem>
						</GridContainer>
					</div>
					{/*  Buisness Info End  */}

					{/*  Contact Details  Start  */}
					<div style={{ display: activeStep === 1 ? "block" : "none" }}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<form className={classes.root} noValidate autoComplete="on">
									<Typography variant="h6">Business Owner</Typography>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactSalutation"
												label="Salutation"
												type="text"
												id="contactSalutation"
												select
												value={contactDetails.contactSalutation}
												onChange={handleChangeContactDetails("contactSalutation")}
											>
												<MenuItem value="Mr">Mr</MenuItem>
												<MenuItem value="Mrs">Mrs</MenuItem>
												<MenuItem value="Ms">Ms</MenuItem>
											</TextField>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactFirstName"
												label="First Name"
												type="contactFirstName"
												id="contactFirstName"
												value={contactDetails.contactFirstName}
												onChange={handleChangeContactDetails("contactFirstName")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactMiddleName"
												label="Middle Name"
												type="text"
												id="contactMiddleName"
												value={contactDetails.contactMiddleName}
												onChange={handleChangeContactDetails("contactMiddleName")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactLastName"
												label="Last Name"
												type="text"
												id="contactLastName"
												value={contactDetails.contactLastName}
												onChange={handleChangeContactDetails("contactLastName")}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactEmail"
												label="E-mail"
												type="contactEmail"
												id="contactEmail"
												value={contactDetails.contactEmail}
												onChange={handleChangeContactDetails("contactEmail")}
												error={contactEmailError}
												helperText={contactEmailError ? "Please Enter Valid Email" : ""}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 10 }}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactMobileNumber"
												label="Mobile Number"
												id="contactMobileNumber"
												type="number"
												value={contactDetails.contactMobileNumber}
												onChange={handleChangeContactDetails("contactMobileNumber")}
												error={cntMobileNumberError}
												helperText={cntMobileNumberError ? "Please Enter Valid Number" : ""}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 10 }}
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactAlternetMobile"
												label="Alernate Contact Number"
												id="contactAlternetMobile"
												type="number"
												value={contactDetails.contactAlternetMobile}
												onChange={handleChangeContactDetails("contactAlternetMobile")}
												error={alternateCntNoError}
												helperText={alternateCntNoError ? "Please Enter Valid Number" : ""}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactTelephone"
												label="Telephone"
												id="contactTelephone"
												type="text"
												value={contactDetails.contactTelephone}
												onChange={handleChangeContactDetails("contactTelephone")}
											/>
										</GridItem>
									</GridContainer>

									<GridContainer>
										<GridItem xs={12} sm={12} md={8}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactCurrentAddress"
												label="Current Address"
												type="text"
												id="contactCurrentAddress"
												value={contactDetails.contactCurrentAddress}
												onChange={handleChangeContactDetails("contactCurrentAddress")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={4}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactLandmark"
												label="Landmark"
												type="text"
												id="contactLandmark"
												value={contactDetails.contactLandmark}
												onChange={handleChangeContactDetails("contactLandmark")}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 6 }}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactPin"
												label="Pin Code"
												type="text"
												id="contactPin"
												value={contactDetails.contactPin}
												onChange={handleChangeContactDetails("contactPin")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactState"
												label="State"
												type="text"
												id="contactState"
												value={contactDetails.contactState}
												// onChange={handleChangeContactDetails('contactState')}
											/>
										</GridItem>

										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactCity"
												label="City"
												type="text"
												id="contactCity"
												value={contactDetails.contactCity}
												// onChange={handleChangeContactDetails('contactCity')}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactLocality"
												label="Locality"
												type="text"
												id="contactLocality"
												select
												value={contactDetails.contactLocality}
												onChange={handleChangeContactDetails("contactLocality")}
											>
												{contactAreaList.map((value, index) => (
													<MenuItem key={index} value={value.Name}>
														{value.Name}
													</MenuItem>
												))}
											</TextField>
										</GridItem>
										{/* {!state.state ? (
											
										) : (
											<GridItem xs={12} sm={12} md={3}>
												<TextField
													size="small"
													variant="outlined"
													margin="normal"
													required
													fullWidth
													name="contactLocality"
													label="Locality"
													type="text"
													id="contactLocality"
													value={contactDetails.contactLocality}
													onChange={handleChangeContactDetails('contactLocality')}
												></TextField>
											</GridItem>
										)} */}
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>
										<FormControlLabel
											className={classes.root}
											control={<Checkbox onClick={onContactCheckboxClick} style={{ color: "#FF8000" }} />}
											label="Same as Current Address"
										/>
										<GridItem xs={12} sm={12} md={12}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactPermanentAddress"
												label="Office Address"
												type="text"
												id="contactPermanentAddress"
												value={contactDetails.contactPermanentAddress}
												onChange={handleChangeContactDetails("contactPermanentAddress")}
												disabled={contactChecked}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 6 }}
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPermanentPin"
												label="Pin Code"
												type="text"
												id="contactPermanentPin"
												value={contactDetails.contactPermanentPin}
												onChange={handleChangeContactDetails("contactPermanentPin")}
												disabled={contactChecked}
											/>
										</GridItem>

										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPermanentState"
												label="State"
												type="text"
												id="contactPermanentState"
												// select
												value={contactDetails.contactPermanentState}
												disabled={contactChecked}
												// onChange={handleChangeContactDetails('contactPermanentState')}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPermanentCity"
												label="City"
												type="text"
												id="contactPermanentCity"
												// select
												value={contactDetails.contactPermanentCity}
												disabled={contactChecked}
												// onChange={handleChangeContactDetails('contactPermanentCity')}
											/>
										</GridItem>
										<>
											<GridItem xs={12} sm={12} md={3}>
												<TextField
													size="small"
													variant="outlined"
													margin="normal"
													fullWidth
													name="contactPermanentLocality"
													label="Locality"
													type="text"
													id="contactPermanentLocality"
													select
													value={contactDetails.contactPermanentLocality}
													disabled={contactChecked}
													onChange={handleChangeContactDetails("contactPermanentLocality")}
												>
													{contactPermanentAreaList.map((value, index) => (
														<MenuItem key={index} value={value.Name}>
															{value.Name}
														</MenuItem>
													))}
												</TextField>
											</GridItem>
											{/* {state.state || contactChecked ? (
												<GridItem xs={12} sm={12} md={3}>
													<TextField
														size="small"
														variant="outlined"
														margin="normal"
														fullWidth
														name="contactPermanentLocality"
														label="Locality"
														type="text"
														id="contactPermanentLocality"
														value={contactDetails.contactPermanentLocality}
														disabled={contactChecked}
													/>
												</GridItem>
											) : (
												
											)} */}
										</>
									</GridContainer>
									<GridContainer>
										<GridItem xs={6} sm={6} md={3}>
											<TextField
												inputProps={{ maxLength: 10 }}
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="contactPanNumber"
												label="PAN Number"
												type="contactPanNumber"
												id="contactPanNumber"
												value={contactDetails.contactPanNumber}
												onChange={handleChangeContactDetails("contactPanNumber")}
												error={contactPanNumberError}
												helperText={contactPanNumberError ? "Please Enter Valid Pan Number" : ""}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadContactPan}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a href={contactDetails.contactPanDocumentLink} target="_blank" rel="noreferrer noopener">
												<br /> Pan
											</a>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												inputProps={{ maxLength: 12 }}
												size="small"
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												required
												fullWidth
												name="contactAadharNumber"
												label="Aadhar Number"
												type="contactAadharNumber"
												id="contactAadharNumber"
												value={contactDetails.contactAadharNumber}
												onChange={handleChangeContactDetails("contactAadharNumber")}
												error={contactAadharNumberError}
												helperText={contactAadharNumberError ? "Please Enter Valid Aadhar Number" : ""}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadContactAadhar}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a href={contactDetails.contactAadharDocumentLink} target="_blank" rel="noreferrer noopener">
												<br /> Aadhar
											</a>
										</GridItem>

										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 15 }}
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												required
												fullWidth
												name="contactDrivingLicense"
												label="Driving License Number"
												type="contactDrivingLicense"
												id="contactDrivingLicense"
												value={contactDetails.contactDrivingLicense}
												onChange={handleChangeContactDetails("contactDrivingLicense")}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadContactDrivingLicense}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a
												href={contactDetails.contactDrivingLicenseDocumentLink}
												target="_blank"
												rel="noreferrer noopener"
											>
												<br /> Driving License
											</a>
										</GridItem>

										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												fullWidth
												name="contactAddressProof"
												label="Address Proof"
												type="text"
												id="contactAddressProof"
												value={contactDetails.contactAddressProof}
												onChange={handleChangeContactDetails("contactAddressProof")}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadChangeContactAddressProof}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a
												href={contactDetails.contactAddressProofDocumentLink}
												target="_blank"
												rel="noreferrer noopener"
											>
												<br /> Address Proof
											</a>
										</GridItem>
									</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
									<GridContainer>&nbsp;</GridContainer>
									<Typography variant="h6">Contact Person</Typography>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPersonSalutation"
												label="Salutation"
												type="text"
												id="contactPersonSalutation"
												select
												value={contactDetails.contactPersonSalutation}
												onChange={handleChangeContactDetails("contactPersonSalutation")}
											>
												<MenuItem value="Mr">Mr</MenuItem>
												<MenuItem value="Mrs">Mrs</MenuItem>
												<MenuItem value="Ms">Ms</MenuItem>
											</TextField>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPersonFirstName"
												label="First Name"
												type="contactPersonFirstName"
												id="contactPersonFirstName"
												value={contactDetails.contactPersonFirstName}
												onChange={handleChangeContactDetails("contactPersonFirstName")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPersonMiddleName"
												label="Middle Name"
												type="text"
												id="contactPersonMiddleName"
												value={contactDetails.contactPersonMiddleName}
												onChange={handleChangeContactDetails("contactPersonMiddleName")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPersonLastName"
												label="Last Name"
												type="text"
												id="contactPersonLastName"
												value={contactDetails.contactPersonLastName}
												onChange={handleChangeContactDetails("contactPersonLastName")}
											/>
										</GridItem>
									</GridContainer>

									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPersonDesignation"
												label="Designation"
												type="text"
												id="contactPersonDesignation"
												value={contactDetails.contactPersonDesignation}
												onChange={handleChangeContactDetails("contactPersonDesignation")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPersonEmail"
												label="E-mail"
												type="email"
												id="contactPersonEmail"
												value={contactDetails.contactPersonEmail}
												onChange={handleChangeContactDetails("contactPersonEmail")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 10 }}
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPersonMobileNumber"
												label="Mobile Number"
												id="contactPersonMobileNumber"
												type="number"
												value={contactDetails.contactPersonMobileNumber}
												onChange={handleChangeContactDetails("contactPersonMobileNumber")}
												error={contactMobileNumberError}
												helperText={contactMobileNumberError ? "Please Enter Valid Number" : ""}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 10 }}
												variant="outlined"
												margin="normal"
												fullWidth
												name="contactPersonTelephone"
												label="Mobile Number"
												id="contactPersonTelephone"
												type="number"
												value={contactDetails.contactPersonTelephone}
												onChange={handleChangeContactDetails("contactPersonTelephone")}
												error={contactMobileNumberError2}
												helperText={contactMobileNumberError2 ? "Please Enter Valid Number" : ""}
											/>
										</GridItem>
									</GridContainer>
								</form>
							</GridItem>
						</GridContainer>
					</div>
					{/*  Contact Detail End  */}
					{/*  Start  of KYC form  */}
					<div style={{ display: activeStep === 2 ? "block" : "none" }}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<form className={classes.root} noValidate autoComplete="on">
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="bankName"
												label="Bank Name"
												id="bankName"
												type="text"
												value={kyc?.bankName}
												onChange={handleChangeKyc("bankName")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="branchName"
												label="Branch Name"
												id="branchName"
												type="text"
												value={kyc?.branchName}
												onChange={handleChangeKyc("branchName")}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 11 }}
												variant="outlined"
												margin="normal"
												required
												fullWidth
												name="IFSC"
												label="IFSC"
												id="IFSC"
												type="text"
												value={kyc?.IFSC}
												onChange={handleChangeKyc("IFSC")}
												error={ifscNumberError}
												helperText={ifscNumberError ? "Please Enter Valid IFSC Code" : ""}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												required
												fullWidth
												name="accountNumber"
												label="Account Number"
												id="accountNumber"
												type="text"
												value={kyc?.accountNumber}
												onChange={handleChangeKyc("accountNumber")}
												error={bankACNumberError}
												helperText={bankACNumberError ? "Please Enter Valid Account Number" : ""}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadChangeAccountNumber}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a href={kyc?.kycAccountDocumentLink} target="_blank" rel="noreferrer noopener">
												<br /> Passbook
											</a>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												inputProps={{ maxLength: 10 }}
												size="small"
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												required
												fullWidth
												name="pan_tanNumber"
												label="Pan/Tan Number"
												id="pan_tanNumber"
												type="text"
												value={kyc?.pan_tanNumber}
												onChange={handleChangeKyc("pan_tanNumber")}
												error={panTanNoError}
												helperText={panTanNoError ? "Please Enter Valid Pan Tan Number" : ""}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadChangePan_TanNumber}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a href={kyc?.kycPan_TanDocumentLink} target="_blank" rel="noreferrer noopener">
												<br /> PAN/TAN
											</a>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												inputProps={{ maxLength: 15 }}
												size="small"
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												fullWidth
												name="GSTNumber"
												label="GST Number"
												id="GSTNumber"
												type="text"
												value={kyc?.GSTNumber}
												onChange={handleChangeKyc("GSTNumber")}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadChangeGSTNumber}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a href={kyc?.kycGSTDocumentLink} target="_blank" rel="noreferrer noopener">
												<br /> GST
											</a>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 20 }}
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												fullWidth
												name="transportLicense"
												label="Transport License Number"
												id="transportLicense"
												type="text"
												value={kyc?.transportLicense}
												onChange={handleChangeKyc("transportLicense")}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadChangeTransportLicense}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a
												href={kyc?.kycTransportlicenceDocumentLink}
												target="_blank"
												rel="noreferrer noopener"
											>
												<br /> Transport License
											</a>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												variant="outlined"
												margin="normal"
												fullWidth
												name="licenseExpiry"
												label="License Expiry Date"
												id="licenseExpiry"
												type="date"
												value={kyc?.licenseExpiry}
												onChange={handleChangeKyc("licenseExpiry")}
												InputLabelProps={{
													shrink: true,
												}}
											/>
										</GridItem>
									</GridContainer>
									<GridContainer>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 20 }}
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												fullWidth
												name="udyogAadhar"
												label="Udyog Aadhar Number"
												id="udyogAadhar"
												type="text"
												value={kyc?.udyogAadhar}
												onChange={handleChangeKyc("udyogAadhar")}
												error={udyogAadharNumberError}
												helperText={udyogAadharNumberError ? "Invalid Aadhar Number" : ""}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadChangeUdyogAadhar}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a href={kyc?.kycUdyogAdharDocumentLink} target="_blank" rel="noreferrer noopener">
												<br /> Udyog Aadhar
											</a>
										</GridItem>
										<GridItem xs={12} sm={12} md={3}>
											<TextField
												size="small"
												inputProps={{ maxLength: 20 }}
												variant="outlined"
												margin="normal"
												disabled={profileEdit ? false : true}
												fullWidth
												name="shopActNumber"
												label="Shop Act Number (if Available)"
												id="shopActNumber"
												type="text"
												value={kyc?.shopActNumber}
												onChange={handleChangeKyc("shopActNumber")}
											/>
											<input
												type="file"
												accept="image/*"
												disabled={profileEdit ? false : true}
												onChange={handleUploadChangeShopActNumber}
											></input>
											{profileEdit == true ? (
												<a style={{ fontSize: "0.9em" }} disabled>
													* Supported file formats - pdf, jpg, png.
												</a>
											) : (
												""
											)}
											<a href={contactDetails.shopActDocumentLink} target="_blank" rel="noreferrer noopener">
												<br /> Shop-Act License
											</a>
										</GridItem>
									</GridContainer>
								</form>
							</GridItem>
						</GridContainer>
					</div>
					{/*  End of KYC form  */}

					{/*  Start of Vehicle Categoriesation form  */}
					{/* <div style={{ display: activeStep === 3 ? "block" : "none" }}>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<form className={classes.root} noValidate autoComplete="on">
									<TabContext value={tabValue}>
										<AppBar position="center" color="#fff">
											<TabList>
												{metadata.map((value, index) => (
													<Tab
														label={value.name}
														value={value.name}
														key={value.id}
														onClick={() => {
															handleChangeVehicleCategory(value);
														}}
													/>
												))}
											</TabList>
										</AppBar>
										<TabPanel value={tabValue}>
											<GridContainer>
												<GridItem xs={12} sm={12} md={3}>
													<TextField
														size="small"
														variant="outlined"
														margin="normal"
														required
														fullWidth
														name="subCategory"
														label="Vehicle Sub-Categories"
														type="text"
														id="subCategory"
														select
														value={vehicleCategorization.subCategory}
														onChange={handleChangeCategory("subCategory")}
													>
														{vehicleType.map((option) => (
															<MenuItem key={option.name} value={option.name}>
																{option.name}
															</MenuItem>
														))}
													</TextField>
												</GridItem>
												<GridItem xs={12} sm={12} md={3}>
													<TextField
														size="small"
														variant="outlined"
														margin="normal"
														required
														fullWidth
														name="sizeOfVehicle"
														label="Size of Vehicle"
														type="text"
														id="sizeOfVehicle"
														select
														value={vehicleCategorization.sizeOfVehicle}
														onChange={handleChangeCategory("sizeOfVehicle")}
													>
														<MenuItem value="Mini">Mini</MenuItem>
														<MenuItem value="Large">Large</MenuItem>
													</TextField>
												</GridItem>
												<GridItem xs={12} sm={12} md={2}>
													<TextField
														size="small"
														variant="outlined"
														margin="normal"
														required
														fullWidth
														name="countOfVehicle"
														label="No of Vehicle"
														type="number"
														inputProps={{ inputmode: "numeric", pattern: "[0-9]*", min: 0 }}
														id="countOfVehicle"
														value={vehicleCategorization.countOfVehicle}
														onChange={handleChangeCategory("countOfVehicle")}
													/>
												</GridItem>
												<GridItem
													xs={12}
													sm={12}
													md={2}
													style={{ alignItems: "center", marginTop: 10 }}
												>
													<Button
														variant="contained"
														color="primary"
														className={classes.button}
														onClick={onUpdateVehicleCategory}
													>
														update
													</Button>
												</GridItem>
											</GridContainer>
										</TabPanel>
									</TabContext>
									<GridContainer>
										<GridItem
											xs={12}
											sm={12}
											md={12}
											style={{ alignItems: "center", marginTop: 15 }}
										>
											<Table className={classes.table}>
												<TableBody>
													<TableRow>
														<TableCell>
															<Typography
																variant="h6"
																gutterBottom
																className={classes.titleColor}
															>
																Sr No.
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																variant="h6"
																gutterBottom
																className={classes.titleColor}
															>
																Category
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																variant="h6"
																gutterBottom
																className={classes.titleColor}
															>
																Sub-Category
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																variant="h6"
																gutterBottom
																className={classes.titleColor}
															>
																Size of Vehicle
															</Typography>
														</TableCell>
														<TableCell>
															<Typography
																variant="h6"
																gutterBottom
																className={classes.titleColor}
															>
																Count
															</Typography>
														</TableCell>
													</TableRow>
													{arrayOfVehicleCategory.map((value, index) => (
														<TableRow key={index} value={value}>
															<TableCell>
																<Typography
																	variant="body1"
																	gutterBottom
																	className={classes.hmiInfoColor}
																>
																	{index + 1}
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="body1"
																	gutterBottom
																	className={classes.hmiInfoColor}
																>
																	{value.category}
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="body1"
																	gutterBottom
																	className={classes.hmiInfoColor}
																>
																	{value.subCategory}
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="body1"
																	gutterBottom
																	className={classes.hmiInfoColor}
																>
																	{value.sizeOfVehicle}
																</Typography>
															</TableCell>
															<TableCell>
																<Typography
																	variant="body1"
																	gutterBottom
																	className={classes.hmiInfoColor}
																>
																	{value.countOfVehicle}
																</Typography>
															</TableCell>

															<TableCell>
																<DeleteOutline
																	onClick={() => {
																		onDeleteVehicleCategory(
																			value,
																			index,
																			arrayOfVehicleCategory
																		);
																	}}
																></DeleteOutline>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</GridItem>
									</GridContainer>
								</form>
							</GridItem>
						</GridContainer>
					</div> */}
					<div>
						<Button
							disabled={activeStep === 0}
							onClick={handleBack}
							variant="contained"
							color="primary"
							className={classes.button}
						>
							Back
						</Button>
						<Button variant="contained" color="primary" onClick={handleNext} className={classes.button}>
							{activeStep === 0 ? "Next" : ""}
							{activeStep === 1 ? "Next" : ""}
							{/* {activeStep === 2 ? "Next" : ""} */}
							{activeStep === 2 ? "SAVE USER" : ""}
						</Button>
						{activeStep === 2 && !onboarded && (
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									handleOnboardOperator();
									/* if (!kycCheck()) {
                    setOpenOnboardConfirmatopnDialog(true);
                  } */
								}}
								className={classes.button}
							>
								ONBOARD USER
							</Button>
						)}
					</div>
				</ThemeProvider>
			</div>
			<Snackbar
				open={successToastOpen}
				autoHideDuration={2000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert onClose={handleClose} severity="success">
					User Added Successully.
				</Alert>
			</Snackbar>
			<Snackbar
				open={failureToastOpen}
				autoHideDuration={2000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert onClose={handleClose} severity="error">
					Please fill all mandetory feilds
				</Alert>
			</Snackbar>

			<Backdrop className={classes.backdrop} open={openLoader} onClick={closeLoader}>
				{/* <CircularProgress color="inherit" /> */}
				<Lottie options={loaderOptions} height={200} width={600} />
			</Backdrop>
			<Dialog
				maxWidth="xs"
				open={openOnboardConfirmatopnDialog}
				onClose={() => setOpenOnboardConfirmatopnDialog(false)}
			>
				<DialogTitle id="alert-dialog-title">{"NowWagon"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{`Do you want to Onboard operator - ${businessInfo.businessName}?`}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="primary" className={classes.submit} onClick={onBoardOperatorHandler}>
						YES
					</Button>
					<Button
						color="primary"
						className={classes.submit}
						onClick={() => setOpenOnboardConfirmatopnDialog(false)}
						autoFocus
					>
						NO
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
