import FbApp from '../../config/FbApp'


export default function TransportationCharges(){
}

export const addTransportationCharges = (payload) =>{
    const db = FbApp.firestore();
    var userRef = db.collection("TRANSPORTATION_CHARGES");
    return userRef.doc().set(payload).then(response=>{
        return response
    })
}
  export const getTransportationCharges =()=>{
    const db= FbApp.firestore();
    return db.collection('TRANSPORTATION_CHARGES').get().then(snapshots=>{
      var docData = snapshots.docs.map(doc=>doc.data());
      //console.log('TRANSPORTATION_CHARGES',docData);
      return docData;
    })
  }
