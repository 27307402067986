import React, { useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import {
  Container,
  makeStyles,
  CircularProgress,
  Backdrop,
  Typography,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";

//import { useNavigate } from 'react-router';
import { useNavigate } from "react-router-dom";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  title: {
    fontSize: 25,
    textAlign: "center",
  },
  customTableContainer: {
    overflowX: "initial",
  },

  tableCell: {
    Width: 100,
  },
  head: {
    backgroundColor: "#FFA93F",
    position: "sticky",
    top: 0,
    color: "#fff",
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#FF8000",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#c55100",
    },
  },
}));
const theme = createMuiTheme({
  palette: {
    primary: orange,
  },
});

export default function HelpDeskScreen() {
  const classes = useStyles();
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const closeLoader = () => {
    setOpen(false);
  };
  const toggleLoader = () => {
    setOpen(!open);
  };
  return (
    <Container>
      <Container maxWidth={false}>
        <GridItem xs={12} sm={12} md={12}>
          <ThemeProvider theme={theme}>
            <Typography className={classes.title}> HelpDesk Page </Typography>
          </ThemeProvider>
        </GridItem>
      </Container>
      <Backdrop className={classes.backdrop} open={open} onClick={closeLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
